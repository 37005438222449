import React from 'react';

function Suspends(){
    return(
        <div>
            suspend
        </div>
    )
}

export default Suspends