import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import axios from "axios";
import AWS from "aws-sdk";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { CognitoAccessToken } from "amazon-cognito-identity-js";
import { useState } from "react";
import { useEffect } from "react";
import NavBar from "../Navbar/Navbar";
import ChartDashboard from "./ChartDashboard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export default function Home() {
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const [profilePictureAttribute, setProfilePictureAttribute] = useState(null);
  const [emailAttribute, setEmailAttribute] = useState(null);
  const [nameAttribute, setNameAttribute] = useState(null);
  const [LastnameAttribute, setLastNameAttribute] = useState(null);
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const refreshToken = localStorage.getItem("refreshToken");
  const [jobStats, setJobStats] = useState(null);
  let clientId = localStorage.getItem("client_id");
  const token = localStorage.getItem("idToken");
  // const accessToken = localStorage.getItem("accessToken");
  const handleAccessToken = () => {
    const accessToken = localStorage.getItem("accessToken");

    const navigateAndReturn = (path) => {
      navigate(path);
      return false;
    };

    if (!accessToken || !checkAccessTokenValidity(accessToken)) {
      // console.log("...to redirect on login")
      localStorage.setItem("logedIn", false);
      return navigateAndReturn("/");
    } else {
      // console.log("...to redirect on dashboard")
      // refreshtokenentry()
      checkScreenActivity();
      return navigateAndReturn("/dashboard");
    }
    // console.log("...dashboard return ...");
  };

  const checkAccessTokenValidity = (accessToken) => {
    try {
      const decodedToken = new CognitoAccessToken({ AccessToken: accessToken });

      const expirationTime = decodedToken.getExpiration();

      const currentTime = Math.floor(Date.now() / 1000);

      if (expirationTime > currentTime) {
        // console.log("Expiration time is greater than current time. Triggering useEffect logic.");
        return expirationTime > currentTime;
      }
    } catch (error) {
      console.error("Error decoding token or missing expiration time:", error);
      return false;
    }
  };

  const refreshtokenentry = () => {
    // console.log("'...token checking...")
    const region = process.env.REACT_APP_AWS_REGION;

    const apiUrl = `https://cognito-idp.${region}.amazonaws.com/`;
    const requestBody = {
      AuthFlow: "REFRESH_TOKEN",
      ClientId: process.env.REACT_APP_CLIENT_ID_USER,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    };
    const headers = {
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      "Content-Type": "application/x-amz-json-1.1",
    };
    axios
      .post(apiUrl, requestBody, { headers })
      .then((response) => {})
      .catch((error) => {});
  };

  const checkScreenActivity = () => {
    let isActive = false;

    const handleMove = () => {
      isActive = true;
    };

    window.addEventListener("mousemove", handleMove);

    setTimeout(() => {
      window.removeEventListener("mousemove", handleMove);
      if (isActive) {
        // console.log("....yess...")
        refreshtokenentry();
      } else {
        //  console.log("...no...")
      }
    }, 3000);
  };

  React.useEffect(() => {
    handleAccessToken();
    const timeoutId = setTimeout(() => {
      console.log("...trigger after 2 min");
      handleAccessToken();
    }, 55 * 60 * 1000);

    const foraccessToken = setTimeout(() => {
      console.log("...trigger after 5 min");
      // toast.error("Session expired");
      handleAccessToken();
    }, 60 * 60 * 1000);
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(foraccessToken);
    };
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getUserAttributes = async () => {
      const cognitoIdentityServiceProvider =
        new AWS.CognitoIdentityServiceProvider();

      try {
        // Get the authenticated user
        const user = await cognitoIdentityServiceProvider
          .getUser({ AccessToken: accessToken })
          .promise();
        const attributes = user.UserAttributes;

        // Log user attributes, you can filter and extract the ones you need
        // console.log('User attributes:', attributes);

        // Example: Get email and custom profile picture attribute
        const emailAttribute = attributes.find((attr) => attr.Name === "email");
        const profilePictureAttribute = attributes.find(
          (attr) => attr.Name === "picture"
        );
        const nameAttribute = attributes.find(
          (attr) => attr.Name === "given_name"
        );
        const LastNameAttribute = attributes.find(
          (attr) => attr.Name == "family_name"
        );
        // console.log("..Name..",nameAttribute?.Value)
        // console.log('Email:', emailAttribute?.Value);
        // console.log('Profile Picture:', profilePictureAttribute?.Value);
        setProfilePictureAttribute(profilePictureAttribute?.Value);

        setEmailAttribute(emailAttribute?.Value);
        setNameAttribute(nameAttribute?.Value);
        setLastNameAttribute(LastNameAttribute?.Value);
      } catch (error) {
        if (
          error.code === "NotAuthorizedException" &&
          error.message === "Invalid Access Token"
        ) {
          // If the token is expired, attempt to refresh it
          // Implement your token refresh logic here
          console.error("Access token expired. Implement token refresh logic.");
        } else {
          console.error("Error getting user attributes:", error);
        }
      }
    };
    getUserAttributes();
  }, []);
  const [open, setOpen] = useState(window.innerWidth >= 600); // Initialize based on window width

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setOpen(false); // Close the drawer on smaller screens
    } else {
      setOpen(true); // Open the drawer on larger screens
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Set the initial state based on the current window size
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array so it runs only once when the component mounts

  const handleDrawerOpen = () => {
    setOpen(true); // Opens the drawer
  };

  const handleDrawerClose = () => {
    setOpen(false); // Closes the drawer
  };

  const fetchJobStats = async () => {
    if (!clientId) return;

    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    const apiUrl = `${baseUrl}/dashboard/job_stats`;

    try {
      const response = await axios.post(
        apiUrl,
        { client_id: clientId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setJobStats(response.data);
    } catch (error) {
      console.error("Error fetching job stats:", error);
    }
  };

  useEffect(() => {
    if (clientId) {
      fetchJobStats();
      // Set up interval to fetch job stats every 5 minutes
      const interval = setInterval(fetchJobStats, 5 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [clientId]);

  return (
    <Box
      sx={{
        maxHeight: "calc(100vh - 40px)",
        overflowY: "auto",
        width: "auto",
        height: "100vh",
        paddingLeft: 6,
        backgroundColor: isDarkMode ? "black" : "#F9F9F9",
      }}
    >
      <NavBar
        pageTitle="Hello, "
        showNameWithTitle={true}
        nameAttribute={nameAttribute}
        LastnameAttribute={LastnameAttribute}
        profilePictureAttribute={profilePictureAttribute}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        open={open} // Pass the open state
        setOpen={setOpen} // Pass the setOpen function
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: "80px",
          pl: { xs: "16px", sm: open ? "160px" : "40px" },
          pr: { xs: "16px", sm: "24px" },
          pb: "40px",
          mb: "16px",
          // backgroundColor: isDarkMode ? "#121212" : "#f5f5f5",
          overflow: "hidden",
        }}
      >
        <ChartDashboard jobStats={jobStats} showPeriodSelect={true} />
      </Box>
      <Box
        sx={{
          backgroundColor: isDarkMode ? "#080808" : "#EEF3F7",
          position: "fixed",
          bottom: 0,
          width: "100%",
          padding: 2,

          textAlign: "center",
          height: 50,
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: isDarkMode ? "#FFF" : "#142764" }}
        >
          &copy; copyright {new Date().getFullYear()}: Udyat Technologies
        </Typography>
      </Box>
    </Box>
  );
}
