import React from 'react';

function Pauses(){
    return(
        <div>
            pause
        </div>
    )
}

export default Pauses