import React, { useState, useEffect, useRef, useCallback } from "react";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import {
  Box,
  Paper,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
} from "@mui/material";
import { RiseLoader } from "react-spinners";
import axios from "axios";
import { Description, Phone, TrendingUp } from "@mui/icons-material";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const ChartDashboard = ({ isDarkMode, jobStats }) => {
  const theme = useTheme();
  const [isLoadingJobData, setIsLoadingJobData] = useState(false);
  const [data, setData] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedJob, setSelectedJob] = useState("All Jobs");
  const [callsData, setCallsData] = useState(null);
  const [resumeData, setResumeData] = useState(null);
  const [jobsPeriod, setJobsPeriod] = useState("This Quarter");
  const [showYearlyData, setShowYearlyData] = useState(false);
  const previousJobsRef = useRef([]);
  const clientId = localStorage.getItem("client_id");
  const token = localStorage.getItem("idToken");
  const [totalCalls, setTotalCalls] = useState(0);
  const [jobsDisplayMode, setJobsDisplayMode] = useState("year");
  const [totalResumes, setTotalResumes] = useState(0);
  const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
  const jobDataCache = useRef({});
  const [screeningCallsData, setScreeningCallsData] = useState(null);
  const [technicalCallsData, setTechnicalCallsData] = useState(null);
  const [totalScreeningCalls, setTotalScreeningCalls] = useState(0);
  const [totalTechnicalCalls, setTotalTechnicalCalls] = useState(0);
  const fetchJobs = () => {
    const apiUrl = `${baseUrl}/job/show_jobs`;
    axios
      .post(
        apiUrl,
        { client_id: clientId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const jobsData = response.data.jobs;
        const formattedJobTitles = [
          { title: "All Jobs", id: "all" },
          ...jobsData.map((job) => ({
            title: job.job_title,
            id: job.job_id,
          })),
        ];
        setJobTitles(formattedJobTitles);
        setData(
          jobsData.map((job, index) => ({
            id: index + 1,
            role: job.job_title,
            resume: job.number_of_resumes_uploaded,
            processed: job.number_of_resumes_processed,
            job_id: job.job_id,
            technicalCall: job.technical_call,
            screeningStatus: job.screening_call_status,
            initialCall: job.screening_call,
            codingAssessment: job.coding_assessment_check,
            aptitudeAssessment: job.aptitude_assessment_check,
            pause_status: job.pause_status,
            job_disable: job.job_disable,
          }))
        );
        previousJobsRef.current = jobsData;
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching jobs:", error);
        setIsLoading(false);
      });
  };
  const [overallData, setOverallData] = useState(null);

  const fetchOverallData = useCallback(() => {
    setIsLoading(true);
    axios
      .post(
        `${baseUrl}/dashboard/overall_count`,
        { client_id: clientId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setOverallData(response.data);
        if (selectedJob === "All Jobs") {
          processJobData(response.data, true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching overall data:", error);
        setIsLoading(false);
      });
  }, [clientId, token, selectedJob]);

  //   useEffect(() => {
  //     fetchOverallData();
  //   }, [fetchOverallData]);

  const processJobData = (responseData, isOverallData = false) => {
    const {
      totalfilesuploaded,
      Actualresumesexecuted,
      Screening_calls_count,
      Technical_calls_count,
    } = responseData;

    const hasScreeningCallsData = Object.values(Screening_calls_count).some(
      (value) => value > 0
    );
    const hasTechnicalCallsData = Object.values(Technical_calls_count).some(
      (value) => value > 0
    );
    const hasResumeData = totalfilesuploaded > 0 || Actualresumesexecuted > 0;

    // Calculate total screening calls
    const screeningCallsTotal = Object.values(Screening_calls_count).reduce(
      (acc, curr) => acc + curr,
      0
    );
    setTotalScreeningCalls(screeningCallsTotal);

    // Calculate total technical calls
    const technicalCallsTotal = Object.values(Technical_calls_count).reduce(
      (acc, curr) => acc + curr,
      0
    );
    setTotalTechnicalCalls(technicalCallsTotal);

    // Set total calls (sum of screening and technical calls)
    setTotalCalls(screeningCallsTotal + technicalCallsTotal);

    setTotalResumes(parseInt(totalfilesuploaded));

    if (hasScreeningCallsData) {
      setScreeningCallsData({
        labels: [
          "Scheduled",
          "Finished",
          "Unanswered",
          "Triggered",
          "Pending",
          "Under Observation",
          "Force Stopped",
        ],
        datasets: [
          {
            data: [
              Screening_calls_count.scheduled,
              Screening_calls_count.finished,
              Screening_calls_count.unanswered,
              Screening_calls_count.Triggered,
              Screening_calls_count.pending,
              Screening_calls_count.under_observation,
              Screening_calls_count.force_stopped,
            ],
            backgroundColor: [
              "#2196f3",
              "#00AB66",
              "#f50057",
              "#9966FF",
              "#ff9800",
              "#795548",
              "#607d8b",
            ],
          },
        ],
      });
    } else {
      setScreeningCallsData(null);
    }

    if (hasTechnicalCallsData) {
      setTechnicalCallsData({
        labels: [
          "Scheduled",
          "Finished",
          "Unanswered",
          "Triggered",
          "Pending",
          "Under Observation",
          "Force Stopped",
        ],
        datasets: [
          {
            data: [
              Technical_calls_count.scheduled,
              Technical_calls_count.finished,
              Technical_calls_count.unanswered,
              Technical_calls_count.Triggered,
              Technical_calls_count.pending,
              Technical_calls_count.under_observation,
              Technical_calls_count.force_stopped,
            ],
            backgroundColor: [
              "#2196f3",
              "#00AB66",
              "#f50057",
              "#9966FF",
              "#ff9800",
              "#795548",
              "#607d8b",
            ],
          },
        ],
      });
    } else {
      setTechnicalCallsData(null);
    }

    if (hasResumeData) {
      setResumeData({
        labels: ["Uploaded", "Processed"],
        datasets: [
          {
            data: [totalfilesuploaded, Actualresumesexecuted],
            backgroundColor: ["#FF6384", "#36A2EB"],
          },
        ],
      });
    } else {
      setResumeData(null);
    }
  };

  const renderScreeningCallsChart = () => {
    if (isLoading || isLoadingJobData) {
      return <LoaderDisplay />;
    }

    if (screeningCallsData) {
      return (
        <>
          <Doughnut data={screeningCallsData} options={callsOptions} />
          {/* <Typography variant="h6" align="center" sx={{ mt: 2 }}>
            Total Screening Calls: {totalScreeningCalls}
          </Typography> */}
        </>
      );
    }

    return <NoDataDisplay message="No screening calls data available!" />;
  };

  const renderTechnicalCallsChart = () => {
    if (isLoading || isLoadingJobData) {
      return <LoaderDisplay />;
    }

    if (technicalCallsData) {
      return (
        <>
          <Doughnut data={technicalCallsData} options={callsOptions} />
          {/* <Typography variant="h6" align="center" sx={{ mt: 2 }}>
            Total Technical Calls: {totalTechnicalCalls}
          </Typography> */}
        </>
      );
    }

    return <NoDataDisplay message="No technical calls data available!" />;
  };

  const renderResumeChart = () => {
    if (isLoading) {
      return <LoaderDisplay />;
    }

    if (resumeData) {
      return <Pie data={resumeData} options={resumeOptions} />;
    }

    return <NoDataDisplay message="No resume data available!" />;
  };
  const fetchJobData = useCallback(
    (jobId) => {
      if (jobId === "all") {
        fetchOverallData();
        return;
      }

      setIsLoadingJobData(true);
      const apiUrl = `${baseUrl}/dashboard/resume_call_stats`;
      axios
        .post(
          apiUrl,
          { client_id: clientId, job_id: jobId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          jobDataCache.current[jobId] = response.data;
          processJobData(response.data);
          setIsLoadingJobData(false);
        })
        .catch((error) => {
          console.error("Error fetching job data:", error);
          setScreeningCallsData(null);
          setTechnicalCallsData(null);
          setResumeData(null);
          setTotalCalls(0);
          setTotalResumes(0);
          setIsLoadingJobData(false);
        });
    },
    [baseUrl, clientId, token, fetchOverallData]
  );

  useEffect(() => {
    fetchJobs();
    fetchOverallData();
  }, [fetchOverallData]);

  useEffect(() => {
    fetchJobs();
  }, [token]);

  const handleJobChange = (event) => {
    const newSelectedJob = event.target.value;
    setSelectedJob(newSelectedJob);

    if (newSelectedJob === "All Jobs") {
      setJobsPeriod("This Quarter");
      fetchOverallData();
    } else {
      const jobId = jobTitles.find((job) => job.title === newSelectedJob)?.id;
      if (jobId) {
        fetchJobData(jobId);
      }
    }
  };

  const commonOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: isDarkMode ? "#ffffff" : "#000000",
          font: { size: 12 },
        },
      },
      title: {
        display: true,
        color: isDarkMode ? "#ffffff" : "#000000",
        font: { size: 18, weight: "bold" },
        padding: { top: 10, bottom: 30 },
      },
    },
  };

  const jobsOptions = {
    ...commonOptions,
    plugins: {
      ...commonOptions.plugins,
      title: { ...commonOptions.plugins.title },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 5,
          color: isDarkMode ? "#ffffff" : "#000000",
        },
      },
      x: {
        ticks: { color: isDarkMode ? "#ffffff" : "#000000" },
      },
    },
  };

  const callsOptions = {
    ...commonOptions,
    plugins: {
      ...commonOptions.plugins,
      title: {
        ...commonOptions.plugins.title,
      },
      legend: { ...commonOptions.plugins.legend, position: "top" },
    },
    cutout: "60%",
  };

  const resumeOptions = {
    ...commonOptions,
    plugins: {
      ...commonOptions.plugins,
      title: {
        ...commonOptions.plugins.title,
      },
    },
  };

  const [jobsData, setJobsData] = useState(null);

  const getJobsData = () => {
    if (!jobStats) return null;

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const getDataForPeriod = (startDate, endDate) => {
      return jobStats.monthly_data
        .filter((month) => {
          const monthDate = new Date(`${month.month} 1, ${currentYear}`);
          return monthDate >= startDate && monthDate <= endDate;
        })
        .map((month) => ({
          month: month.month,
          total_jobs: month.weeks.reduce(
            (sum, week) => sum + week.total_jobs,
            0
          ),
        }));
    };

    switch (jobsPeriod) {
      case "This Week":
        return {
          labels: Object.keys(jobStats.current_week_data.daily_jobs),
          datasets: [
            {
              label: "Total Jobs",
              data: Object.values(jobStats.current_week_data.daily_jobs),
              backgroundColor: "#2196f3",
              barThickness: 40,
            },
          ],
        };

      case "Last Week":
        // Assuming jobStats includes data for the previous week
        const lastWeekData = jobStats.previous_week_data || { daily_jobs: {} };
        return {
          labels: Object.keys(lastWeekData.daily_jobs),
          datasets: [
            {
              label: "Total Jobs",
              data: Object.values(lastWeekData.daily_jobs),
              backgroundColor: "#2196f3",
              barThickness: 40,
            },
          ],
        };

      case "This Quarter":
        const quarterStart = new Date(
          currentYear,
          Math.floor(currentMonth / 3) * 3,
          1
        );
        const quarterData = getDataForPeriod(quarterStart, currentDate);
        return {
          labels: quarterData.map((data) => data.month),
          datasets: [
            {
              label: "Total Jobs",
              data: quarterData.map((data) => data.total_jobs),
              backgroundColor: "#2196f3",
              barThickness: 40,
            },
          ],
        };

      case "Last Quarter":
        const lastQuarterStart = new Date(
          currentYear,
          Math.floor(currentMonth / 3) * 3 - 3,
          1
        );
        const lastQuarterEnd = new Date(
          currentYear,
          Math.floor(currentMonth / 3) * 3,
          0
        );
        const lastQuarterData = getDataForPeriod(
          lastQuarterStart,
          lastQuarterEnd
        );
        return {
          labels: lastQuarterData.map((data) => data.month),
          datasets: [
            {
              label: "Total Jobs",
              data: lastQuarterData.map((data) => data.total_jobs),
              backgroundColor: "#2196f3",
              barThickness: 40,
            },
          ],
        };

      case "Last 6 Months":
        const sixMonthsAgo = new Date(currentYear, currentMonth - 5, 1);
        const sixMonthData = getDataForPeriod(sixMonthsAgo, currentDate);
        return {
          labels: sixMonthData.map((data) => data.month),
          datasets: [
            {
              label: "Total Jobs",
              data: sixMonthData.map((data) => data.total_jobs),
              backgroundColor: "#2196f3",
              barThickness: 40,
            },
          ],
        };

      default:
        return null;
    }
  };

  const NoDataDisplay = ({ message }) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <svg width="200" height="200" viewBox="0 0 200 200">
        <circle cx="100" cy="100" r="80" fill="#f0f0f0" />
        <text
          x="100"
          y="100"
          fontFamily="Arial"
          fontSize="100"
          fill="#d0d0d0"
          textAnchor="middle"
          dominantBaseline="central"
        >
          ?
        </text>
      </svg>
      <Typography variant="h6" sx={{ mt: 2, color: "text.secondary" }}>
        {message}
      </Typography>
    </Box>
  );

  useEffect(() => {
    const newJobsData = getJobsData();
    setJobsData(newJobsData);
  }, [jobStats, jobsPeriod]);

  const handlePeriodChange = (event) => {
    setJobsPeriod(event.target.value);
  };

  const ChartCard = ({
    title,
    chart,
    showPeriodSelect,
    selectedPeriod,
    onPeriodChange,
    totalCount,
  }) => (
    <Paper
      elevation={3}
      sx={{
        flex: 1,
        p: "24px",
        borderRadius: "16px",
        minWidth: 0,
        backgroundColor: isDarkMode ? "#1e1e1e" : "#ffffff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 20,
          alignItems: "center",
          mb: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: isDarkMode ? "#ffffff" : "#000000" }}
          >
            {title}
          </Typography>
          {totalCount !== undefined && (
            <Typography
              variant="h5"
              sx={{ color: theme.palette.secondary.main, fontWeight: "bold" }}
            >
              {totalCount}
            </Typography>
          )}
        </Box>

        {showPeriodSelect && (
          <FormControl variant="outlined" size="small">
            <InputLabel id="period-label">Duration</InputLabel>
            <Select
              labelId="period-label"
              value={jobsPeriod}
              onChange={handlePeriodChange}
              label="Duration"
            >
              <MenuItem value="This Week">This Week</MenuItem>
              <MenuItem value="Last Week">Last Week</MenuItem>
              <MenuItem value="This Quarter">This Quarter</MenuItem>
              <MenuItem value="Last Quarter">Last Quarter</MenuItem>
              <MenuItem value="Last 6 Months">Last 6 Months</MenuItem>
            </Select>
          </FormControl>
        )}
      </Box>
      <Box sx={{ height: { xs: "300px", sm: "400px" } }}>{chart}</Box>
    </Paper>
  );

  const LoaderDisplay = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <RiseLoader
        speedMultiplier={1}
        color={isDarkMode ? "#ffffff" : "#2196f3"}
        size={15}
      />
    </Box>
  );

  const renderJobsChart = () => {
    if (!jobStats) {
      return <LoaderDisplay />;
    }

    const jobsData = getJobsData();

    if (!jobsData || jobsData.datasets[0].data.every((value) => value === 0)) {
      return (
        <NoDataDisplay
          message={`No jobs data available for the selected ${jobsPeriod}!`}
        />
      );
    }

    return <Bar data={jobsData} options={jobsOptions} />;
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        maxWidth: "100%",
        p: "15px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{ color: isDarkMode ? "#ffffff" : "#000000", fontWeight: "bold" }}
        >
          Dashboard
        </Typography>
        <FormControl variant="outlined" sx={{ minWidth: 220 }}>
          <InputLabel id="job-select-label">Select Job</InputLabel>
          <Select
            labelId="job-select-label"
            value={selectedJob}
            onChange={handleJobChange}
            label="Select Job"
          >
            {jobTitles.map((job) => (
              <MenuItem key={job.id} value={job.title}>
                {job.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {selectedJob === "All Jobs" ? (
        <>
          <Box sx={{ width: "100%" }}>
            <ChartCard
              title={"Jobs Created"}
              icon={<TrendingUp sx={{ color: "#ffffff" }} />}
              chart={renderJobsChart()}
              selectedPeriod={jobsPeriod}
              showPeriodSelect={true}
              onPeriodChange={handlePeriodChange}
              isLoading={!jobStats}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "32px",
            }}
          >
            <ChartCard
              title="Screening Calls Analysis"
              icon={<Phone sx={{ color: "#ffffff" }} />}
              chart={renderScreeningCallsChart()}
              chartType="calls"
              showPeriodSelect={false}
              isLoading={isLoading}
              totalCount={totalScreeningCalls}
            />
            <ChartCard
              title="Technical Calls Analysis"
              icon={<Phone sx={{ color: "#ffffff" }} />}
              chart={renderTechnicalCallsChart()}
              chartType="calls"
              showPeriodSelect={false}
              isLoading={isLoading}
              totalCount={totalTechnicalCalls}
            />
            <ChartCard
              title="Resume Analysis"
              icon={<Description sx={{ color: "#ffffff" }} />}
              chart={renderResumeChart()}
              chartType="resume"
              showPeriodSelect={false}
              isLoading={isLoading}
              totalCount={totalResumes}
            />
          </Box>
          {/* <Box sx={{ width: "100%" }}></Box> */}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: "32px",
          }}
        >
          <ChartCard
            title="Screening Calls Analysis"
            icon={<Phone sx={{ color: "#ffffff" }} />}
            chart={renderScreeningCallsChart()}
            chartType="calls"
            showPeriodSelect={false}
            isLoading={isLoading}
            totalCount={totalScreeningCalls}
          />
          <ChartCard
            title="Technical Calls Analysis"
            icon={<Phone sx={{ color: "#ffffff" }} />}
            chart={renderTechnicalCallsChart()}
            chartType="calls"
            showPeriodSelect={false}
            isLoading={isLoading}
            totalCount={totalTechnicalCalls}
          />
          <ChartCard
            title="Resume Analysis"
            icon={<Description sx={{ color: "#ffffff" }} />}
            chart={renderResumeChart()}
            chartType="resume"
            showPeriodSelect={false}
            isLoading={isLoading}
            totalCount={totalResumes}
          />
        </Box>
      )}
    </Box>
  );
};

export default ChartDashboard;
