import { Navigate, Outlet } from "react-router-dom";
import React from "react"

 
const ProtectedRoute = ({logedIn}) => {

    const logedIn1 =  localStorage.getItem("logedIn")
    if(logedIn1 == "true"){
        return <Outlet/>
    }else{
      return  <Navigate to = {"/"} />
    }
}
export default ProtectedRoute;
