import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import FormHelperText from "@mui/material/FormHelperText";
import axios from "axios";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const steps = [
  "Job Details",
  "Other Details",
  "Job Description",
  "Features",
  "Initial Screening Questions",
  // "Technical Screening Questions",
  "Complete!",
];

// const options = [
//   "Frontend developer",
//   "Backend developer",
//   "UI/UX Designer",
//   "AI/ML",
//   "Database Engineer",
//   "AWS Engineer",
// ];

export default function Step1({
  setInputValue,
  inputValue,
  handleNext,
  formData,
  setFormData,
}) {
  const [jobTitle, setJobTitle] = useState(formData.jobTitle || "");
  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmCancel = () => {
    // Add your cancel logic here
    window.location.href = "/dashboard";
  };
  const handleCancel = () => {
    setOpenDialog(true);
    // Navigate to the dashboard page
  };
  const handleBack = () => {
    window.location.href = "/dashboard"; // Navigate to the dashboard page
  };
  const [location, setLocation] = React.useState(formData.location || ""); // Initialized with a default value
  const [showFields, setShowFields] = useState(true); // Assuming you want to show fields by default
  // const [showSalaryInputs, setShowSalaryInputs] = useState(false);
  const [showSalaryInputs, setShowSalaryInputs] = useState(
    formData.jobType === "Full Time" && formData.location !== "Remote"
  );
  // const [showHourlyInputs, setShowHourlyInputs] = useState(false);
  const [showHourlyInputs, setShowHourlyInputs] = useState(
    (formData.jobType === "Part-Time" ||
      formData.jobType === "Consulting/Contract") &&
      (formData.maxHourlyPrice || formData.minHourlyPrice)
  );
  const [jobType, setJobType] = useState(
    formData.jobType !== undefined ? formData.jobType : ""
  );
  const [activeStep, setActiveStep] = useState(0);
  const [checked, setChecked] = React.useState(formData.checked || false);
  const [remoteOption, setRemoteOption] = useState("");
  const [country, setCountry] = useState(formData.country || ""); // Define country state
  const [state, setState] = useState(formData.state || ""); // Define state state
  const [city, setCity] = useState("");
  const [states, setStates] = useState([]);
  const [data, setData] = useState([]);
  const [selectedState, setSelectedState] = useState(formData.state || "");
  const [selectedCity, setSelectedCity] = useState(formData.city || "");
  const [options, setOptions] = useState([]);
  const [countryError, setCountryError] = useState("");
  const [streetError, setStreetError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [jobTypeError, setJobTypeError] = useState("");

  const [remoteOptionError, setRemoteOptionError] = useState("");
  const countries = ["India"];
  const [streetAddress, setStreetAddress] = useState(
    formData.streetAddress || ""
  );
  const [minHourlyPrice, setMinHourlyPrice] = useState(
    formData.minHourlyPrice !== undefined ? formData.minHourlyPrice : ""
  );
  const [maxHourlyPrice, setMaxHourlyPrice] = useState(
    formData.maxHourlyPrice !== undefined ? formData.maxHourlyPrice : ""
  );
  const [minHourlyPriceError, setMinHourlyPriceError] = useState("");
  const [maxHourlyPriceError, setMaxHourlyPriceError] = useState("");
  const [minSalary, setMinSalary] = useState(
    formData.minSalary !== undefined ? formData.minSalary : ""
  );
  const [maxSalary, setMaxSalary] = useState(
    formData.maxSalary !== undefined ? formData.maxSalary : ""
  );
  const [minSalaryError, setMinSalaryError] = useState("");
  const [maxSalaryError, setMaxSalaryError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [error, setError] = useState("");
  const [minSalaryError2, setMinSalaryError2] = useState("");
  const [maxSalaryError2, setMaxSalaryError2] = useState("");
  console.log("...minhours...", minHourlyPrice);
  console.log("...maxHours...", maxHourlyPrice);
  console.log("...job Type ...", jobType);
  console.log("...location...", location);
  console.log("...formData.jobType...", formData.jobType);
  const token = localStorage.getItem("idToken");
  // Function to handle minimum salary change
  const handleMinHourlyPriceChange = (event) => {
    setMinHourlyPriceError("");
    const value = event.target.value;
    setMinHourlyPrice(value);

    // Validate minimum hourly price if jobType is Part Time
    if (jobType === "Part Time" && location !== "Remote") {
      if (!value) {
        setMinHourlyPriceError("Minimum Hourly Price is required");
        return;
      }
    }
  }; // Log the minimum hourly price value
  const handleMinSalaryChange = (event) => {
    setMinSalary(event.target.value);
  };
  const handleMaxSalaryChange = (event) => {
    setMaxSalary(event.target.value);
  };
  // Function to handle maximum hourly price change
  const handleMaxHourlyPriceChange = (event) => {
    const value = event.target.value;
    setMaxHourlyPrice(value);

    // Resetting the error message when the user starts typing
    setMaxHourlyPriceError("");

    // Validate maximum hourly price if jobType is Part Time
    if (jobType === "Part-Time" && location !== "Remote") {
      if (!value) {
        setMaxHourlyPriceError("Maximum Hourly Price is required");
        return;
      }
      if (parseInt(value, 10) <= parseInt(minHourlyPrice, 10)) {
        setMaxHourlyPriceError(
          "Maximum Hourly Price should be greater than Minimum Hourly Price"
        );
        return;
      }
    }
  };

  // Function to handle street address change
  const handleStreetAddressChange = (event) => {
    setStreetAddress(event.target.value);
  };

  // Logging the street address state variable
  console.log("Street Address:", streetAddress);
  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };
  console.log("..country...", country);
  console.log("..state...", state);

  console.log("...remoteOpton...", remoteOption);

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setCountry(selectedCountry);
    setSelectedState(""); // Reset selectedState when country changes
    setSelectedCity("");
    // Here you should fetch the states for the selected country and update the state
    // For now, let's assume you have the states available for selectedCountry
    const fetchedStates = getStatesForCountry(selectedCountry);
    setStates(fetchedStates);
    if (!event.target.value) {
      setCountryError("Country is required");
    } else {
      setCountryError("");
    }
  };

  const handleNextStep = () => {
    if (!jobTitle) {
      setError("Job title is required");
      return;
    }
    if (!location) {
      setLocationError("Location is required");
      return;
    }
    if (location === "Office" || location === "InField") {
      if (!jobType) {
        setJobTypeError("Job type is required");
        return;
      }
    }
    if (location === "Office" || location === "InField") {
      if (!country) {
        setCountryError("Country is required");
        return;
      }
      if (!selectedState) {
        setStateError("State is required");
        return;
      }
      if (!selectedCity) {
        setCityError("City is required");
        return;
      }
      if (!streetAddress) {
        setStreetError("Street is required");
        return;
      }
    }
    if (
      (jobType === "Full Time" && location === "Office") ||
      (jobType === "Full Time" && location === "InField")
    ) {
      if (!minSalary) {
        setMinSalaryError("Minimum Salary is required");
        return;
      }
      if (parseInt(minSalary, 10) >= parseInt(maxSalary, 10)) {
        setMinSalaryError("Minimum Salary should be less than Maximum Salary");
        return;
      }
      if (!maxSalary) {
        setMaxSalaryError("Maximum Salary is required");
        return;
      }
    }

    if (jobType === "Part-Time" || jobType === "Consulting/Contract") {
      if (!minHourlyPrice) {
        setMinHourlyPriceError("Minimum Hourly Price is required");
        return;
      }
      if (!maxHourlyPrice) {
        setMaxHourlyPriceError("Maximum Hourly Price is required");
        return;
      }
    }
    if (jobType === "Full Time" && location === "Remote") {
      if (!minSalary) {
        setMinSalaryError("Minimum Salary is required");
        return;
      }
      if (!maxSalary) {
        setMaxSalaryError("Maximum Salary is required");
        return;
      }
      if (parseInt(minSalary, 10) >= parseInt(maxSalary, 10)) {
        setMinSalaryError("Minimum Salary should be less than Maximum Salary");
        return;
      }
    }
    if (jobType === "Part-Time" && location === "Remote") {
      if (!minHourlyPrice) {
        setMinHourlyPriceError("Hourly Minimum Price is required");
        return;
      }
      if (!maxHourlyPrice) {
        setMaxHourlyPriceError("Hourly Maximum Price is required");
        return;
      }
      if (parseInt(minHourlyPrice, 10) >= parseInt(maxHourlyPrice, 10)) {
        setMinHourlyPriceError(
          "Hourly Minimum Price should be less than Hourly Maximum Price"
        );
        return;
      }
    }
    setJobTypeError("");
    setCountryError("");
    setStateError("");
    setCityError("");
    setStreetError("");
    setMinSalaryError("");
    setMaxSalaryError("");
    setMinHourlyPriceError("");
    setMaxHourlyPriceError("");
    setFormData({
      ...formData,
      jobTitle: jobTitle,
      location: location,
      country: country,
      state: selectedState,
      city: selectedCity,
      streetAddress: streetAddress,
      jobType: jobType,
      minHourlyPrice: minHourlyPrice,
      maxHourlyPrice: maxHourlyPrice,
      remoteOption: remoteOption,
      maxSalary: maxSalary,
      minSalary: minSalary,
      checked: checked,
    });
    handleNext(); // Proceed to the next step
  };

  // Define a function to get states for the selected country
  const getStatesForCountry = (country) => {
    // You need to implement this function to fetch states for the selected country
    // For now, let's return dummy data
    // Example:
    if (country === "India") {
      return ["Delhi", "Punjab", "UP"];
    }
    // Add more countries and their states as needed
    return [];
  };

  const handleLocationChange = (event) => {
    const selectedLocation = event.target.value;
    setLocation(selectedLocation);
    if (!event.target.value) {
      setLocationError("Location is required");
    } else {
      setLocationError("");
    }

    setShowFields(
      selectedLocation === "Office" ||
        selectedLocation === "InField" ||
        selectedLocation === "Remote"
    );
  };
  console.log(location);

  const handleSalary = (event) => {
    const selectedJobType = event.target.value;
    if (!event.target.value) {
      setJobTypeError("Job Type is required");
    } else {
      setJobTypeError("");
    }
    setJobType(selectedJobType);
    setShowSalaryInputs(selectedJobType === "Full Time");
    setShowHourlyInputs(
      selectedJobType === "Part-Time" ||
        selectedJobType === "Consulting/Contract"
    );
  };

  console.log(jobTitle);

  // const handleJobTitleChange = (event, newValue) => {
  //   let typedValue = newValue;
  //   if (typeof newValue === "object") {
  //     // When an option is selected from the dropdown
  //     typedValue = newValue;
  //   } else if (typeof newValue === "string") {
  //     // When the user types manually
  //     typedValue = newValue;
  //     if (!options.includes(newValue)) {
  //       setOptions((prevOptions) => [...prevOptions, newValue]);
  //     }
  //   }
  //   // Filter out removed characters from the options list
  //   const updatedOptions = options.filter((option) =>
  //     option.startsWith(newValue)
  //   );
  //   setOptions(updatedOptions);
  //   setJobTitle(typedValue);
  //   if (!newValue) {
  //     setError("Job title is required");
  //   } else {
  //     setError("");
  //   }
  // };

  const handleJobTitleChange = (event, newValue) => {
    let typedValue = newValue;
    if (typeof newValue === "object") {
      // When an option is selected from the dropdown
      typedValue = newValue;
    } else if (typeof newValue === "string") {
      // When the user types manually
      typedValue = newValue;
      // If the typed value is not included in the options, add it to the options list
      if (!options.includes(newValue)) {
        setOptions((prevOptions) => [...prevOptions, newValue]);
      }
      // Call fetchJobTitles when the input changes
      fetchJobTitles(newValue);
    }
    // Filter out removed characters from the options list
    const updatedOptions = options.filter((option) =>
      option.startsWith(newValue)
    );
    setOptions(updatedOptions);
    setJobTitle(typedValue);
    if (!newValue) {
      setError("Job title is required");
    } else {
      setError("");
    }
  };

  const handleRemoteOptionChange = (event) => {
    const selectedOption = event.target.value;
    setRemoteOption(selectedOption);
    if (!event.target.value) {
      setRemoteOptionError("Remote Option is required");
    } else {
      setRemoteOptionError("");
    }
  };
  console.log(remoteOption);

  useEffect(() => {
    // Define the API endpoint
    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    const apiUrl = `${baseUrl}/getlocation`;

    // Make the POST request using Axios
    axios
      .post(
        apiUrl,
        {
          // email_id:location.state.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // Handle the API response data here
        setData(response.data.data.India);
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  }, []);

  const fetchJobTitles = (inputValue) => {
    // Define the API endpoint
    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    const apiUrl = `${baseUrl}/getjobtitle`;

    // Make the POST request using Axios
    axios
      .post(
        apiUrl,
        {
          // Pass the inputValue as a parameter to the API
          input: inputValue,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // Handle the API response data here
        setOptions(response.data.data.Job_Title);
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    fetchJobTitles();
  }, []);
  console.log("..data...", data);

  const handleStateChange = (event) => {
    const state = event.target.value;
    setSelectedState(state);
    setSelectedCity("");
    if (!event.target.value) {
      setStateError("State is required");
    } else {
      setStateError("");
    }
  };

  const handleCityChange = (event) => {
    const city = event.target.value;
    setSelectedCity(city);
    if (!event.target.value) {
      setCityError("City is required");
    } else {
      setCityError("");
    }
  };

  // useEffect(() => {
  //   // Define the API endpoint
  //   const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
  //   const apiUrl = `${baseUrl}/getjobtitle`;

  //   // Make the POST request using Axios
  //   axios
  //     .post(
  //       apiUrl,
  //       {
  //         // email_id:location.state.email,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       // Handle the API response data here
  //       setOptions(response.data.data.Job_Title);
  //     })
  //     .catch((error) => {
  //       // Handle errors here
  //       console.error("Error:", error);
  //     });
  // }, []);

  console.log("...jobtitle...", options);

  return (
    <Box sx={{ width: "100%", marginTop: 2 }}>
      <Stepper activeStep={0} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Typography
        sx={{
          fontSize: "25px",
          color: "#142764",
          marginTop: "15px",
          textAlign: "center",
        }}
      >
        Add your Job Details
      </Typography>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 20,
          marginTop: 20,
        }}
      >
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={options}
          sx={{ width: 400 }}
          onChange={handleJobTitleChange}
          onInputChange={handleJobTitleChange} // Add this line to capture user input
          value={jobTitle}
          renderInput={(params) => (
            <div sx={{ display: "flex", flexDirection: "row" }}>
              <TextField
                sx={{ height: 50 }}
                {...params}
                label="Job Title"
                placeholder="search your job role"
                error={Boolean(error)}
              />
              {error && <FormHelperText error>{error}</FormHelperText>}
            </div>
          )}
        />
        <Tooltip
          title={
            <Typography variant="body2">
              Please enter or select your job title.
            </Typography>
          }
          arrow
          placement="right"
          sx={{ cursor: "pointer", ml: 1 }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#F8F8FF",
                color: "black",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                padding: "8px 12px",
              },
            },
            arrow: {
              sx: {
                color: "darkblue", // Match arrow color with tooltip background
                boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
              },
            },
          }}
        >
          <HelpOutlineIcon
            fontSize="small"
            sx={{
              color: "#142764",
              cursor: "pointer",
              transition: "all 0.3s ease",
              "&:hover": {
                transform: "scale(1.1)",
                color: "#1a75ff",
              },
            }}
          />
        </Tooltip>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: 20,
          marginTop: 20,
          alignItems: "center",
        }}
      >
        <FormControl sx={{ mt: 1, mb: 1, minWidth: 400 }}>
          <InputLabel id="demo-simple-select-label">Location</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={location}
            label="Location"
            onChange={handleLocationChange}
            placeholder="Select a job location"
            error={Boolean(locationError)}
          >
            <MenuItem value={"Office"}>Office</MenuItem>
            <MenuItem value={"Remote"}>Remote</MenuItem>
            <MenuItem value={"InField"}>InField</MenuItem>
          </Select>
          {locationError && (
            <FormHelperText error>{locationError}</FormHelperText>
          )}
        </FormControl>
        <Tooltip
          title={
            <Box>
              {/* <Typography variant="body2" sx={{ fontWeight: "normal", mb:1 }}>
            Select a location option (Office, Remote, or InField):
          </Typography> */}
              <Typography variant="body2">
                <strong>Office:</strong> Work performed at a designated office
                location with required on-site presence.
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Typography variant="body2">
                <strong>Remote:</strong> Allows flexibility to work from any
                location.
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Typography variant="body2">
                <strong>InField:</strong> Involves fieldwork, requiring travel
                to various locations as needed.
              </Typography>
            </Box>
          }
          arrow
          placement="right"
          sx={{ cursor: "pointer", ml: 1 }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#F8F8FF",
                color: "black",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                padding: "8px 12px",
              },
            },
            arrow: {
              sx: {
                color: "darkblue", // Match arrow color with tooltip background
                boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
              },
            },
          }}
        >
          <HelpOutlineIcon
            fontSize="small"
            sx={{
              color: "#142764",
              cursor: "pointer",
              transition: "all 0.3s ease",
              "&:hover": {
                transform: "scale(1.1)",
                color: "#1a75ff",
              },
            }}
          />
        </Tooltip>
      </Box>
      {showFields && (
        <Box>
          {location == "Office" || location == "InField" ? (
            <Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 20,
                  marginTop: 10,
                }}
              >
                <FormControl sx={{ m: 0, minWidth: 120 }}>
                  <InputLabel id="country-select-label">Country</InputLabel>
                  <Select
                    labelId="country-select-label"
                    id="country-select"
                    value={country}
                    onChange={handleCountryChange}
                    label="Country"
                    error={Boolean(countryError)}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </Select>
                  {countryError && (
                    <FormHelperText error>{countryError}</FormHelperText>
                  )}
                </FormControl>
                <FormControl sx={{ m: 0, minWidth: 120 }}>
                  <InputLabel id="state-select-label">State</InputLabel>
                  <Select
                    labelId="state-select-label"
                    id="state-select"
                    value={selectedState}
                    onChange={handleStateChange}
                    label="State"
                    error={Boolean(stateError)}
                  >
                    {Object.keys(data).map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                  {stateError && (
                    <FormHelperText error>{stateError}</FormHelperText>
                  )}
                </FormControl>
                <FormControl sx={{ m: 0, minWidth: 120 }}>
                  <InputLabel id="city-select-label">City</InputLabel>
                  <Select
                    labelId="city-select-label"
                    id="city-select"
                    value={selectedCity}
                    onChange={handleCityChange}
                    label="City"
                    error={Boolean(cityError)}
                  >
                    {selectedState &&
                      data[selectedState] &&
                      data[selectedState].map((city) => (
                        <MenuItem key={city} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                  </Select>
                  {cityError && (
                    <FormHelperText error>{cityError}</FormHelperText>
                  )}
                </FormControl>
                <Tooltip
                  title={
                    <Typography variant="body2">
                      Please select the country, state and city where the job
                      will be located.
                    </Typography>
                  }
                  arrow
                  placement="right"
                  sx={{ cursor: "pointer", ml: 1 }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#F8F8FF",
                        color: "black",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                        padding: "8px 12px",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "darkblue", // Match arrow color with tooltip background
                        boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                      },
                    },
                  }}
                >
                  <HelpOutlineIcon
                    fontSize="small"
                    sx={{
                      color: "#142764",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        transform: "scale(1.1)",
                        color: "#1a75ff",
                      },
                    }}
                  />
                </Tooltip>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                <TextField
                  label="Street Address"
                  variant="outlined"
                  margin="normal"
                  sx={{ textAlign: "center", width: 400 }}
                  value={streetAddress}
                  onChange={handleStreetAddressChange}
                  error={Boolean(streetError)}
                />
                <Tooltip
                  title={
                    <Typography variant="body2">
                      Please enter the street address for the job location.
                    </Typography>
                  }
                  arrow
                  placement="right"
                  sx={{ cursor: "pointer", ml: 1 }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#F8F8FF",
                        color: "black",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                        padding: "8px 12px",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "darkblue", // Match arrow color with tooltip background
                        boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                      },
                    },
                  }}
                >
                  <HelpOutlineIcon
                    fontSize="small"
                    sx={{
                      color: "#142764",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        transform: "scale(1.1)",
                        color: "#1a75ff",
                      },
                    }}
                  />
                </Tooltip>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {streetError && (
                  <FormHelperText error>{streetError}</FormHelperText>
                )}
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                <FormControl sx={{ mt: 1, minWidth: 400 }}>
                  <InputLabel id="demo-simple-select-label">
                    Job Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={jobType}
                    label="Job Type"
                    onChange={handleSalary}
                    error={Boolean(jobTypeError)}
                  >
                    <MenuItem value="Full Time">Full Time</MenuItem>
                    <MenuItem value="Part-Time">Part-Time</MenuItem>
                    <MenuItem value="Consulting/Contract">
                      Consulting/Contract
                    </MenuItem>
                  </Select>
                  {jobTypeError && (
                    <FormHelperText error>{jobTypeError}</FormHelperText>
                  )}
                </FormControl>
                <Tooltip
                  title={
                    <Box>
                      <Typography variant="body2">
                        <strong>Full Time:</strong> A permanent position
                        requiring full-time commitment
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="body2">
                        <strong>Part Time:</strong> A position with fewer hours
                        than a full-time job, offering flexibility in work
                        schedule.
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="body2">
                        <strong>Consulting/Contract:</strong> A temporary or
                        project-based role with specific duration, often for
                        specialized tasks.
                      </Typography>
                    </Box>
                  }
                  arrow
                  placement="right"
                  sx={{ cursor: "pointer", ml: 1 }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#F8F8FF",
                        color: "black",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                        padding: "8px 12px",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "darkblue", // Match arrow color with tooltip background
                        boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                      },
                    },
                  }}
                >
                  <HelpOutlineIcon
                    fontSize="small"
                    sx={{
                      color: "#142764",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        transform: "scale(1.1)",
                        color: "#1a75ff",
                      },
                    }}
                  />
                </Tooltip>
              </Box>

              {showSalaryInputs && (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 20,
                    marginTop: 5,
                  }}
                >
                  <TextField
                    label="Minimum Monthly Salary"
                    variant="outlined"
                    margin="normal"
                    type="number"
                    sx={{ width: 190 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={minSalary}
                    onChange={handleMinSalaryChange}
                    error={!!minSalaryError} // Setting the error state based on whether there's an error message
                    helperText={minSalaryError}
                  />

                  <TextField
                    label="Maximum Monthly Salary"
                    variant="outlined"
                    type="number"
                    margin="normal"
                    sx={{ width: 190 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={maxSalary}
                    onChange={handleMaxSalaryChange}
                    error={!!maxSalaryError} // Setting the error state based on whether there's an error message
                    helperText={maxSalaryError} // Displaying the error message
                    // Setting the error state based on whether there's an error message
                  />
                  <Tooltip
                    title={
                      <Typography variant="body2">
                        Enter the monthly salary range for this position
                      </Typography>
                    }
                    arrow
                    placement="right"
                    sx={{ cursor: "pointer", ml: 1 }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#F8F8FF",
                          color: "black",
                          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                          borderRadius: "8px",
                          padding: "8px 12px",
                        },
                      },
                      arrow: {
                        sx: {
                          color: "darkblue", // Match arrow color with tooltip background
                          boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                        },
                      },
                    }}
                  >
                    <HelpOutlineIcon
                      fontSize="small"
                      sx={{
                        color: "#142764",
                        cursor: "pointer",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          transform: "scale(1.1)",
                          color: "#1a75ff",
                        },
                      }}
                    />
                  </Tooltip>
                </Box>
              )}
              {showHourlyInputs && (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 20,
                    marginTop: 5,
                  }}
                >
                  <TextField
                    label="Hourly Minimum Price"
                    variant="outlined"
                    margin="normal"
                    type="number"
                    sx={{ width: 190 }}
                    value={minHourlyPrice}
                    onChange={handleMinHourlyPriceChange}
                    helperText={minHourlyPriceError} // Displaying the error message
                    error={Boolean(minHourlyPriceError)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Hourly Maximum Price"
                    variant="outlined"
                    margin="normal"
                    sx={{ width: 190 }}
                    value={maxHourlyPrice}
                    onChange={handleMaxHourlyPriceChange}
                    helperText={maxHourlyPriceError} // Displaying the error message
                    error={Boolean(maxHourlyPriceError)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Tooltip
                    title={
                      <Typography variant="body2">
                        Enter the hourly price range for this position.
                      </Typography>
                    }
                    arrow
                    placement="right"
                    sx={{ cursor: "pointer", ml: 1 }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#F8F8FF",
                          color: "black",
                          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                          borderRadius: "8px",
                          padding: "8px 12px",
                        },
                      },
                      arrow: {
                        sx: {
                          color: "darkblue", // Match arrow color with tooltip background
                          boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                        },
                      },
                    }}
                  >
                    <HelpOutlineIcon
                      fontSize="small"
                      sx={{
                        color: "#142764",
                        cursor: "pointer",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          transform: "scale(1.1)",
                          color: "#1a75ff",
                        },
                      }}
                    />
                  </Tooltip>
                </Box>
              )}
             
            </Box>
          ) : location === "Remote" ? (
            <Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                <FormControl sx={{ mt: 2, minWidth: 400 }}>
                  <InputLabel id="demo-simple-select-label">
                    Job Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={jobType}
                    label="Job Type"
                    onChange={handleSalary}
                    error={Boolean(jobTypeError)}
                  >
                    <MenuItem value="Full Time">Full Time</MenuItem>
                    <MenuItem value="Part-Time">Part Time</MenuItem>
                    <MenuItem value="Consulting/Contract">
                      Consulting/Contract
                    </MenuItem>
                  </Select>
                  {jobTypeError && (
                    <FormHelperText error>{jobTypeError}</FormHelperText>
                  )}
                </FormControl>
                <Tooltip
                  title={
                    <Box>
                      <Typography variant="body2">
                        <strong>Full Time:</strong> A permanent position
                        requiring full-time commitment
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="body2">
                        <strong>Part Time:</strong> A position with fewer hours
                        than a full-time job, offering flexibility in work
                        schedule.
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="body2">
                        <strong>Consulting/Contract:</strong> A temporary or
                        project-based role with specific duration, often for
                        specialized tasks.
                      </Typography>
                    </Box>
                  }
                  arrow
                  placement="right"
                  sx={{ cursor: "pointer", ml: 1 }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#F8F8FF",
                        color: "black",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                        padding: "8px 12px",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "darkblue", // Match arrow color with tooltip background
                        boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                      },
                    },
                  }}
                >
                  <HelpOutlineIcon
                    fontSize="small"
                    sx={{
                      color: "#142764",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        transform: "scale(1.1)",
                        color: "#1a75ff",
                      },
                    }}
                  />
                </Tooltip>
              </Box>
              {showSalaryInputs && (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 20,
                    marginTop: 5,
                  }}
                >
                  <TextField
                    label="Minimum Monthly Salary"
                    variant="outlined"
                    margin="normal"
                    type="number"
                    sx={{ width: 190 }}
                    value={minSalary}
                    onChange={(e) => {
                      setMinSalary(e.target.value);
                      setMinSalaryError("");
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(minSalaryError)}
                    helperText={minSalaryError}
                  />
                  <TextField
                    label="Maximum Monthly Salary"
                    variant="outlined"
                    type="number"
                    margin="normal"
                    sx={{ width: 190 }}
                    value={maxSalary}
                    onChange={(e) => {
                      setMaxSalary(e.target.value);
                      setMaxSalaryError(""); // Clear previous error message
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(maxSalaryError)}
                    helperText={maxSalaryError}
                  />
                  <Tooltip
                    title={
                      <Typography variant="body2">
                        Enter the monthly salary range for this position
                      </Typography>
                    }
                    arrow
                    placement="right"
                    sx={{ cursor: "pointer", ml: 1 }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#F8F8FF",
                          color: "black",
                          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                          borderRadius: "8px",
                          padding: "8px 12px",
                        },
                      },
                      arrow: {
                        sx: {
                          color: "darkblue", // Match arrow color with tooltip background
                          boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                        },
                      },
                    }}
                  >
                    <HelpOutlineIcon
                      fontSize="small"
                      sx={{
                        color: "#142764",
                        cursor: "pointer",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          transform: "scale(1.1)",
                          color: "#1a75ff",
                        },
                      }}
                    />
                  </Tooltip>
                </Box>
              )}
              {showHourlyInputs && (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 20,
                    marginTop: 5,
                  }}
                >
                  <TextField
                    label="Hourly Minimum Price"
                    variant="outlined"
                    type="number"
                    margin="normal"
                    sx={{ width: 190 }}
                    value={minHourlyPrice}
                    onChange={(e) => {
                      setMinHourlyPrice(e.target.value);
                      setMinHourlyPriceError(""); // Clear previous error message
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(minHourlyPriceError)}
                    helperText={minHourlyPriceError}
                  />
                  <TextField
                    label="Hourly Maximum Price"
                    variant="outlined"
                    type="number"
                    margin="normal"
                    sx={{ width: 190 }}
                    value={maxHourlyPrice}
                    onChange={(e) => {
                      setMaxHourlyPrice(e.target.value);
                      setMaxHourlyPriceError(""); // Clear previous error message
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(maxHourlyPriceError)}
                    helperText={maxHourlyPriceError}
                  />
                  <Tooltip
                    title={
                      <Typography variant="body2">
                        Enter the hourly price range for this position.
                      </Typography>
                    }
                    arrow
                    placement="right"
                    sx={{ cursor: "pointer", ml: 1 }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#F8F8FF",
                          color: "black",
                          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                          borderRadius: "8px",
                          padding: "8px 12px",
                        },
                      },
                      arrow: {
                        sx: {
                          color: "darkblue", // Match arrow color with tooltip background
                          boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                        },
                      },
                    }}
                  >
                    <HelpOutlineIcon
                      fontSize="small"
                      sx={{
                        color: "#142764",
                        cursor: "pointer",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          transform: "scale(1.1)",
                          color: "#1a75ff",
                        },
                      }}
                    />
                  </Tooltip>
                </Box>
              )}
            </Box>
          ) : null}
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 2,
          gap: 32,
          marginBottom: 2,
        }}
      >
        <Button variant="outlined" onClick={handleBack} sx={{ mr: 1, ml: -5 }}>
          Back
        </Button>
        <Button variant="contained" onClick={handleNextStep}>
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 0,
            pl: 3,
            mb: -2,
          }}
        >
          <WarningIcon color="warning" sx={{ marginRight: -2 }} />
          <DialogTitle>Are you sure?</DialogTitle>
        </Box>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel the job creation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseDialog}
            color="primary"
          >
            cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmCancel}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
