import React, { useEffect, useState } from "react";
import { styled, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Button,
  Grid,
  TextField,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import { CognitoAccessToken } from "amazon-cognito-identity-js";
import AWS from "aws-sdk";
import axios from "axios";
import NavBar from "../Navbar/Navbar";

export default function Dashboard() {
  const [iconClicked, setIconClicked] = React.useState({}); // Use an object to store clicked state per row
  const location = useLocation();

  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const [datas, setData] = useState([]);
  const [profilePictureAttribute, setProfilePictureAttribute] = useState(null);
  const [emailAttribute, setEmailAttribute] = useState(null);
  const [nameAttribute, setNameAttribute] = useState(null);
  const [LastnameAttribute, setLastNameAttribute] = useState(null);
  const [creditScore, setCreditScore] = useState("");
  const refreshToken = localStorage.getItem("refreshToken");
  const [pauseData, setPauseData] = useState([]);
  const [handle, setHandle] = useState([]);
  const [rowClickedIcons, setRowClickedIcons] = React.useState(
    () => JSON.parse(localStorage.getItem("iconClicked")) || {}
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [suspendJobId, setSuspendjobId] = useState(false);
  let clientId = localStorage.getItem("client_id");
  const accessToken = localStorage.getItem("accessToken");
  // console.log("...dashboard client id ...", clientId);
  const navigate = useNavigate();
  const token = localStorage.getItem("idToken");
  // console.log(".. dashboard token...", token);
  const DrawerHeader = styled("div")(({ theme }) => ({
    // display: "flex",
    alignItems: "center",
    // justifyContent: "flex-end",
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,
  }));
  const handleResize = () => {
    if (window.innerWidth < 600) {
      // Adjust the threshold as needed
      setOpen(false); // Close the drawer on smaller screens
    } else {
      setOpen(true); // Keep the drawer open on larger screens
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // const handleEdit = (id) => {
  //   // navigate('/edit-job')
  //   console.log("Editing row with ID:", id);

  // };

  const SuspendJOb = (id) => {
    console.log("...job details...");
    console.log("suspendjob...", id);
    const clickedRow = datas.find((row) => row.id === id);
    console.log(".sus[endclickedRow]...", clickedRow.job_id);

    const pausedJobIds = handle
      .filter((item) => item.pause_status == "0")
      .map((item) => item.job_id);
    // Check if job is paused
    const isPaused = pausedJobIds.includes(clickedRow.job_id);

    // If job is paused, skip showing confirmation dialog
    if (isPaused) {
      console.log("Job is paused. Skip showing confirmation dialog.");
      return;
    }

    // Set the suspend job ID and show the confirmation dialog
    setSuspendjobId(clickedRow.job_id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog without deleting
  };

  const SuspendJObConfirm = () => {
    console.log("...suspendJobId...", suspendJobId);
    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    const apiUrl = `${baseUrl}/job/suspend_job`;

    axios
      .post(
        apiUrl,
        {
          client_id: clientId,
          job_id: suspendJobId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const handleConfirmDelete = () => {
    // onDelete(id); // Call the delete function
    setOpenDialog(false); // Close the dialog after deleting
    SuspendJObConfirm();
  };

  const handleRowClick = (params) => {
    const { field } = params;

    if (field !== "actions") {
      handleResume(params);
    }
  };
  const handleClick = (id) => {
    console.log("...id..", id);
    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    const apiUrl = `${baseUrl}/job/pause_job`;
    const clickedRow = datas.find((row) => row.id === id);
    console.log(".sus[endclickedRow]...", clickedRow.job_id);

    // Determine the new pause status based on the current state
    const newPauseStatus = pauseData[id - 1] === "0" ? "1" : "0";

    axios
      .post(
        apiUrl,
        {
          client_id: clientId,
          job_id: clickedRow.job_id,
          pause_status: newPauseStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        setPauseData((prevPauseData) => {
          const updatedPauseData = [...prevPauseData];
          updatedPauseData[id - 1] = newPauseStatus;
          return updatedPauseData;
        });
        if (newPauseStatus === "1") {
          toast.error("Job is temporarily paused");
          console.log("Job is temporarily paused");
        }
        window.location.reload();
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const columns = [
    {
      field: "role",
      headerName: "Role",

      type: "text",
      flex: 1,
      align: "center",
      headerAlign: "center",
      color: isDarkMode ? "#FFF" : "#000",
      cellClassName: "cell-theme",
    },
    {
      field: "resume",
      type: "text",

      headerName: "Total Uploaded Resumes",
      flex: 1,
      align: "center",
      headerAlign: "center",
      cellClassName: "cell-theme",
    },
    {
      field: "processed",
      type: "text",

      headerName: "Processed Resumes",
      flex: 1,
      align: "center",
      headerAlign: "center",
      cellClassName: "cell-theme",
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      align: "center",
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isClicked = rowClickedIcons[id] || false;
        const disabledRow = isClicked ? "disabled-row" : "";
        const pauseStatus = pauseData[id - 1] || "";
        const handleIconClick = isClicked ? null : () => handleClick(id);
        // console.log("id:", id);
        // console.log("pauseStatus:", pauseData[id - 1]); // Adjust for zero-indexing

        return [
          <Tooltip
            title={pauseStatus === "0" ? "enable" : "disable"}
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: pauseStatus === "0" ? "#0039a6" : "#D2122E",
                  color: "#FFF",
                },
              },
            }}
          >
            <GridActionsCellItem
              icon={
                pauseStatus === "0" ? (
                  <DoNotDisturbOnOutlinedIcon sx={{ color: "red" }} />
                ) : (
                  <TaskAltOutlinedIcon
                    sx={{ color: isDarkMode ? "#00C957" : "green" }}
                  />
                )
              }
              label={isClicked ? "Disable" : "Enable"}
              onClick={handleIconClick} // Pass the ID to handleClick
              sx={{ color: isClicked ? "#142764" : "#142764" }}
              border="none"
            />
          </Tooltip>,

          <Tooltip
            title="edit job"
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#0039a6",
                  color: "#FFF",
                },
              },
            }}
          >
            {/* {datas.map((item) => ( */}
            <GridActionsCellItem
              // key={item.id}
              // onClick={() => handlejobedit(item.job_id)}
              icon={<EditIcon />}
              onClick={(event) => {
                event.stopPropagation(); // Stop the event from bubbling up to the row click event
                handleEdit(id);
              }}
              label="Edit"
              className="textPrimary"
              sx={{ color: isDarkMode ? "#78E9DE" : "#142764" }}
              border="none"
            />
          </Tooltip>,

          <Tooltip
            title="delete"
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#D2122E",
                  color: "#FFF",
                },
              },
            }}
          >
            <GridActionsCellItem
              icon={<DeleteForeverIcon />}
              label="Delete"
              onClick={(event) => {
                event.stopPropagation(); // Prevent any row click events from firing
                const clickedRow = datas.find((row) => row.id === id);
                const pausedJobIds = handle
                  .filter((item) => item.pause_status === "0")
                  .map((item) => item.job_id);
                // Check if the job is already paused
                if (pausedJobIds.includes(clickedRow.job_id)) {
                  toast.error("Job is already paused");
                  console.log(
                    "Job is already paused. Skipping opening dialog."
                  );
                  return;
                }
                setOpenDialog(true);
                SuspendJOb(id);
              }}
              // onClick={handleDeleteClick}
              sx={{ color: isDarkMode ? "#FE0000" : "red" }}
              border="none"
            />
            {/* Dialog for confirmation */}
          </Tooltip>,
          <Box>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Confirm Deletion</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this job?
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirmDelete}
                  color="primary"
                  variant="contained"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Box>,
        ];
      },
    },
  ];

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleResume = (params) => {
    const { field, value, row } = params;

    if (field === "role" || field === "resume" || field === "processed") {
      const clickedRow = params.row;

      if (clickedRow) {
        const jobId = clickedRow.job_id;
        const JobTitle = clickedRow.role;
        const techCall = clickedRow.technicalCall;
        const screeningStatus = clickedRow.screeningStatus;
        const codingAssessmentRound = clickedRow.codingAssessment;
        const aptitudeAssessmentRound = clickedRow.aptitudeAssessment;
        const pauseStatus = clickedRow.pause_status;
        const jobDisable = clickedRow.job_disable;

        console.log("column clickedRow", clickedRow);
        const initCall = clickedRow.intialCall;
        navigate("/report", {
          state: {
            job_id: jobId,
            JobTitle: JobTitle,
            tech_call: techCall,
            scr_status: screeningStatus,
            init_call: initCall,
            coding_assessment: codingAssessmentRound,
            aptitude_assessment: aptitudeAssessmentRound,
            pause_status: pauseStatus,
            job_disable: jobDisable,
          },
        });
      }
      // }
    }
  };

  useEffect(() => {
    const fetchData = () => {
      console.log("...job details...");
      const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
      const apiUrl = `${baseUrl}/job/show_jobs`;

      axios
        .post(
          apiUrl,
          {
            client_id: clientId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const jobsData = response.data.jobs;
          console.log("...jshow jobs...", jobsData);
          const updatedData = jobsData.map((job, index) => ({
            id: index + 1,
            role: job.job_title,
            resume: job.number_of_resumes_uploaded,
            processed: job.number_of_resumes_processed,
            job_id: job.job_id,
            technicalCall: job.technical_call,
            screeningStatus: job.screening_call_status,
            intialCall: job.screening_call,
            codingAssessment: job.coding_assessment_check,
            aptitudeAssessment: job.aptitude_assessment_check,
            pause_status: job.pause_status,
            job_disable: job.job_disable,
          }));
          setData(updatedData);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 4000);

    return () => clearInterval(interval);
  }, [clientId]);

  const fetchPauseData = () => {
    console.log("...job details...");
    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    const apiUrl = `${baseUrl}/job/show_jobs`;

    axios
      .post(
        apiUrl,
        {
          client_id: clientId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const jobsData = response.data.jobs;
        console.log(jobsData);
        setHandle(jobsData);
        setPauseData(jobsData.map((data) => data.pause_status));
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  useEffect(() => {
    fetchPauseData();
  }, [clientId]);

  const handleEdit = (id) => {
    const clickedRow = datas.find((row) => row.id === id);
    console.log("Clicked row:", clickedRow);
    const pausedJobIds = handle
      .filter((item) => item.pause_status === "0")
      .map((item) => item.job_id);

    const isPaused = pausedJobIds.includes(clickedRow.job_id);

    if (clickedRow) {
      const jobId = clickedRow.job_id;

      if (isPaused) {
        toast.error("Job is paused. Edit action is disabled.");
        console.log("Job is paused. Edit action is disabled.");
      } else if (clickedRow.job_disable === "True") {
        toast.error("Job is expired. Edit action is disabled.");
        console.log("Job is expired. Edit action is disabled.");
      } else {
        localStorage.setItem("Edit-jobId", clickedRow.job_id);
        navigate("/edit-job", { state: { job_id: jobId } });
      }
    }
  };

  useEffect(() => {
    const getUserAttributes = async () => {
      const cognitoIdentityServiceProvider =
        new AWS.CognitoIdentityServiceProvider();

      try {
        const user = await cognitoIdentityServiceProvider
          .getUser({ AccessToken: accessToken })
          .promise();
        console.log("..user..", user);
        const attributes = user.UserAttributes;
        const emailAttribute = attributes.find((attr) => attr.Name === "email");
        const profilePictureAttribute = attributes.find(
          (attr) => attr.Name === "picture"
        );
        const nameAttribute = attributes.find(
          (attr) => attr.Name === "given_name"
        );
        const LastNameAttribute = attributes.find(
          (attr) => attr.Name == "family_name"
        );
        setProfilePictureAttribute(profilePictureAttribute?.Value);
        setEmailAttribute(emailAttribute?.Value);
        setNameAttribute(nameAttribute?.Value);
        setLastNameAttribute(LastNameAttribute?.Value);
      } catch (error) {
        if (
          error.code === "NotAuthorizedException" &&
          error.message === "Invalid Access Token"
        ) {
          // If the token is expired, attempt to refresh it
          // Implement your token refresh logic here
          console.error("Access token expired. Implement token refresh logic.");
        } else {
          console.error("Error getting user attributes:", error);
        }
      }
    };
    getUserAttributes();
  }, []);
  const handleAccessToken = () => {
    const accessToken = localStorage.getItem("accessToken");

    const navigateAndReturn = (path) => {
      navigate(path);
      return false;
    };

    if (!accessToken || !checkAccessTokenValidity(accessToken)) {
      localStorage.setItem("logedIn", false);
      return navigateAndReturn("/");
    } else {
      // console.log("...to redirect on dashboard")
      // refreshtokenentry()
      checkScreenActivity();
      return navigateAndReturn("/jobs");
    }
    // console.log("...dashboard return ...");
  };

  const checkAccessTokenValidity = (accessToken) => {
    try {
      const decodedToken = new CognitoAccessToken({ AccessToken: accessToken });

      const expirationTime = decodedToken.getExpiration();

      const currentTime = Math.floor(Date.now() / 1000);

      if (expirationTime > currentTime) {
        // console.log("Expiration time is greater than current time. Triggering useEffect logic.");
        return expirationTime > currentTime;
      }
    } catch (error) {
      console.error("Error decoding token or missing expiration time:", error);
      return false;
    }
  };
  const validateEmail = (input) => {
    const regex = /^[a-zA-Z0-9.]+$/;
    if (!regex.test(input)) {
      setEmailError("Only alphanumeric characters are allowed");
    } else {
      setEmailError("");
    }
    setEmail(input);
  };

  const refreshtokenentry = () => {
    // console.log("'...token checking...")
    const region = process.env.REACT_APP_AWS_REGION;

    const apiUrl = `https://cognito-idp.${region}.amazonaws.com/`;
    const requestBody = {
      AuthFlow: "REFRESH_TOKEN",
      ClientId: process.env.REACT_APP_CLIENT_ID_USER,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    };
    const headers = {
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      "Content-Type": "application/x-amz-json-1.1",
    };
    axios
      .post(apiUrl, requestBody, { headers })
      .then((response) => {
        // Handle the response as needed
        // console.log(".....refresh response...", response.data);
      })
      .catch((error) => {
        // Handle errors
        // console.error("Error in Axios refresh POST request:", error);
      });
  };

  //inviteteammate

  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailError, setEmailError] = useState("");

  const ls_clientId = localStorage.getItem("client_id");

  const handleInvite = async (e) => {
    e.preventDefault();
    let client_ids = ls_clientId;
    let converted_client_id = Math.round(client_ids / 1000);

    if (!emailError) {
      try {
        const inviteTeammatesPayload = {
          email_ids_code: [email + "@" + emailAttribute.split("@")[1]],
          client_id: converted_client_id,
          usertype: "false",
          invited_user: "true",
        };

        const createInviteUserPayload = {
          user_email_id: email + "@" + emailAttribute.split("@")[1],
          client_id: ls_clientId.toString(),
        };

        const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
        const inviteTeammatesUrl = `${baseUrl}/invite_teammates`;
        const createInviteUserUrl = `${baseUrl}/user/create_invite_user`;

        // Get the idToken from localStorage
        const idToken = localStorage.getItem("idToken");

        if (!idToken) {
          throw new Error("No idToken found in localStorage");
        }

        // Create headers object with Authorization
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        };

        // Call both APIs concurrently with their respective payloads and the authorization header
        const [inviteResponse, createInviteResponse] = await Promise.all([
          axios.post(inviteTeammatesUrl, inviteTeammatesPayload, { headers }),
          axios.post(createInviteUserUrl, createInviteUserPayload, { headers }),
        ]);

        if (
          inviteResponse.status === 200 &&
          createInviteResponse.status === 200
        ) {
          const userData = inviteResponse.data[0];

          if (userData.status === "User already exists") {
            toast.info("User has already been sent an invite link");
          } else {
            setEmail("");
            setIsModalOpen(false);
            toast.success("Invitation sent successfully");
          }
        } else {
          setIsModalOpen(false);
          toast.success("Invited Link Send Successfully");
        }
      } catch (error) {
        toast.info("User has already been sent an invite link");
      }
    }
  };
  const checkScreenActivity = () => {
    let isActive = false;

    const handleMove = () => {
      isActive = true;
    };

    window.addEventListener("mousemove", handleMove);
    setTimeout(() => {
      window.removeEventListener("mousemove", handleMove);

      if (isActive) {
        // console.log("....yess...")
        refreshtokenentry();
      } else {
        //  console.log("...no...")
      }
    }, 3000); // Adjust the timeout duration as needed (3000 milliseconds = 3 seconds)
  };

  useEffect(() => {
    handleAccessToken();
    const timeoutId = setTimeout(() => {
      console.log("...trigger after 2 min");
      handleAccessToken();
      // 55 * 60 * 1000
    }, 55 * 60 * 1000);

    const foraccessToken = setTimeout(() => {
      console.log("...trigger after 5 min");
      // toast.error("Session expired");
      handleAccessToken();
      // 60 * 60 * 1000
    }, 60 * 60 * 1000);
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(foraccessToken);
    };
  }, []);
  useEffect(() => {
    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    const apiUrl = `${baseUrl}/show_credit_balance`;

    const requestBody = {
      client_id: ls_clientId,
    };
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(apiUrl, requestBody, { headers })
      .then((response) => {
        // Handle the response as needed
        console.log(
          ".....credit balance response...",
          response.data.credit_balance
        );
        setCreditScore(response.data.credit_balance);
      })
      .catch((error) => {
        // Handle errors
        // console.error("Error in Axios refresh POST request:", error);
      });
  }, [ls_clientId]);

  return (
    <>
      <Box
        sx={{
          maxHeight: "calc(100vh - 40px)",
          overflowY: "auto",
          width: "auto",
          height: "100vh",
          paddingLeft: 6,
          backgroundColor: isDarkMode ? "#171717" : "#F9F9F9",
        }}
      >
        <CssBaseline />
        <NavBar
          pageTitle="Jobs"
          showNameWithTitle={false}
          nameAttribute={nameAttribute}
          LastnameAttribute={LastnameAttribute}
          profilePictureAttribute={profilePictureAttribute}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          open={open}
          setOpen={setOpen}
        />
        <Box
          component="main"
          sx={{
            // flexGrow: 1,
            pt: 3,
            pl: open ? 20 : 5,
            pr: 3,
            pb: 5,
            backgroundColor: isDarkMode ? "#171717" : "#F9F9F9",
          }}
        >
          <DrawerHeader />
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
          >
            <Typography
              variant="h5"
              component="h2"
              color={isDarkMode ? "#FFF" : "#142764"}
              sx={{ fontWeight: "500" }}
            >
              Jobs
            </Typography>
            <Button
              onClick={() => {
                navigate("/create-job", { state: { client_id: clientId } });
              }}
              sx={{
                backgroundColor: isDarkMode ? "white" : "#142764",
                color: isDarkMode ? "black" : "primary",
                borderRadius: 2,
              }}
              variant="contained"
            >
              Create Job
            </Button>
          </Grid>
          <Box
            sx={
              {
                // "& .header-theme": {
                //   backgroundColor: isDarkMode ? "#171717" : "#CFD4FF",
                //   width: "auto",
                // },
                // height: "80vh",
              }
            }
          >
            <DataGrid
              columns={columns}
              getRowClassName={(params) => {
                const jobId = params.row.id; // Assuming 'id' field holds the job ID
                const isDisabled = iconClicked[jobId] || false; // Check if job is disabled

                // Existing logic for row styling
                const rowClass =
                  params.indexRelativeToCurrentPage % 2 === 0
                    ? "evenRow"
                    : "oddRow";
                return isDisabled ? `${rowClass} disabled-row` : rowClass; // Add 'disabled-row' class if job is disabled
              }}
              classes={{
                row: "gridRow",
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              // rows={initialRows}
              onCellClick={(params) => {
                if (params.field === "actions") {
                  const clickedRow = params.row;
                  console.log("Clicked Row Data:", iconClicked);
                  // handleEdit(clickedRow.id); // Assuming 'job_id' is the field containing the job ID
                } else {
                  handleResume(params);
                }
              }}
              onRowClick={handleRowClick}
              rows={datas}
              sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: isDarkMode ? "#000" : "#FFF",
                color: isDarkMode ? "#FFF" : "#000",
                height: "70vh",
              }}
              className="centered-rows"
            />
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: isDarkMode ? "#080808" : "#EEF3F7",
            position: "fixed",
            bottom: 0,
            width: "100%",
            padding: 2,

            textAlign: "center",
            height: 50,
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: isDarkMode ? "#FFF" : "#142764" }}
          >
            &copy; copyright {new Date().getFullYear()}: Udyat Technologies
          </Typography>
        </Box>
        <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <DialogTitle>Invite a Teammate</DialogTitle>
          <form onSubmit={handleInvite}>
            <DialogContent>
              <DialogContentText>
                Welcome! We're excited to have you invite a new teammate.
              </DialogContentText>
              <DialogContentText style={{ marginBottom: "20px" }}>
                Send an invitation to join your team!
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="User Name"
                type="text"
                fullWidth
                variant="outlined"
                value={email}
                onChange={(e) => validateEmail(e.target.value)}
                error={!!emailError}
                helperText={emailError}
                required
                style={{ marginBottom: "16px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography style={{ marginLeft: "8px" }}>
                        @
                        {emailAttribute
                          ? emailAttribute.split("@")[1]
                          : "udyattech.com"}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </DialogContent>
            <DialogActions style={{ marginTop: "5px" }}>
              <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!!emailError}
              >
                Invite
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </>
  );
}
