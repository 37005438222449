import { useState } from "react";
import Login from "../Auth/index";
import { Route, Routes } from "react-router-dom";
import Register from "./Register";
import { Account } from "./Account";
import Status from "./Status";
import ForgotPassword from "./ForgotPassword";
import PublicRoute from "../Routes/PublicRoute";
import ProtectedRoute from "../Routes/ProtectedRoute";
import ExistingPage from "./ExistingPage";
import UserCheck from "./UserCheck";
import Create_Job from "./Job/Create_Job";
import JobDetails from "./Job/JobDetails";
import Updates from "./Job/Update";
import Deletes from "./Job/Delete";
import Pauses from "./Job/Pause";
import Suspends from "./Job/Suspend";
import UserProfile from "./UserProfile";
import Checkjs from "./checkjs";
import ResumeDetails from "./Job/ResumeDetails";
import Thankupage from "./Thankupage";
import Confirmationpage from "./Confirmationpage";
import CreateCompany from "./CreateCompany";
import Dashboard from "./Dashboard/Dashboard";
import Profileupdate from "./Profileupdate";
import StepperMain from "./Stepper/StepperMain";
import Report from "./Reports/Report";
import Setting from "./Setting";
import Documentation from "./Documentation";
import EditJob from "./EditJob";
import ResumeInsight from "./Insights/ResumeInsight";
import InviteUserConfirmPwd from "./InviteUserConfirmpwd";
import Home from "./Home/Home";


function View() {
  const [logedIn, setlogedIn] = useState(
    localStorage.getItem("logedIn") === "true"
  );

  return (
    <Account>
      <Status />
      <Routes>
        <Route path="/">
          <Route path="" element={<Login setlogedIn={setlogedIn} />} />
          <Route path="thank-you" element={<Thankupage />} />
          <Route path="Confirmation" element={<Confirmationpage />} />
          <Route path="register" element={<Register />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
          <Route path="checkjs" element={<Checkjs />} />
        </Route>

        <Route path="/" element={<ProtectedRoute logedIn={logedIn} />}>
          <Route path="check-user" element={<UserCheck />} />
          <Route path="profile-update" element={<Profileupdate />} />
          <Route path="create-job" element={<StepperMain />} />
          <Route path="create-company" element={<CreateCompany />} />
          <Route path="jobs" element={<Dashboard />} />
          <Route path="dashboard" element={<Home />} />
          <Route path="existing-page" element={<ExistingPage />} />
          <Route path="resume-details" element={<ResumeDetails />} />
          {/* <Route path="create-job" element={<Create_Job />} /> */}
          <Route path="job-details" element={<JobDetails />} />
          <Route path="update-job" element={<Updates />} />
          <Route path="delete-job" element={<Deletes />} />
          <Route path="pause-job" element={<Pauses />} />
          <Route path="suspend-job" element={<Suspends />} />
          {/* <Route path="user-profile" element={<UserProfile />} /> */}
          <Route path="report" element={<Report />} />
          <Route path="setting" element={<Setting />} />
          <Route path="documentation" element={<Documentation />} />
          <Route path="edit-job" element={<EditJob />} />
          <Route path="resume-insights" element={<ResumeInsight />} />
          <Route path="invite-user-form" element={<InviteUserConfirmPwd />} />
        </Route>
      </Routes>
    </Account>
  );
}

export default View;
