import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import LinkIcon from "@mui/icons-material/Link";
import axios from "axios";
import { useEffect } from "react";
import AWS from "aws-sdk";
import {
  Button,
  Container,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
// import animated from "../assets/animated_logo.gif"
import { CognitoAccessToken } from "amazon-cognito-identity-js";
import NavBar from "./Navbar/Navbar";
import { useState } from "react";

export default function Setting() {
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [companyData, setCompanyData] = React.useState("");
  const [formData, setFormData] = React.useState("");
  const [emailLocal, setEmailLocal] = React.useState("");
  const [initialFormData, setInitialFormData] = React.useState({});
  const [nameAttribute, setNameAttribute] = React.useState(null);
  const [LastnameAttribute, setLastNameAttribute] = useState(null);
  const [loading, setLoading] = React.useState(true); // To manage the loading state
  const [formErrors, setFormErrors] = React.useState({
    company: false,
    city: false,
    website: false,
  });
  const navigate = useNavigate();

  const refreshToken = localStorage.getItem("refreshToken");
  const handleAccessToken = () => {
    const accessToken = localStorage.getItem("accessToken");

    const navigateAndReturn = (path) => {
      navigate(path);
      return false;
    };

    if (!accessToken || !checkAccessTokenValidity(accessToken)) {
      // console.log("...to redirect on login")
      localStorage.setItem("logedIn", false);
      return navigateAndReturn("/");
    } else {
      // console.log("...to redirect on dashboard")
      // refreshtokenentry()
      checkScreenActivity();
      return navigateAndReturn("/setting");
    }
    // console.log("...dashboard return ...");
  };
  const ProfileDetailsSkeleton = () => {
    return (
      <Box sx={{ padding: 2 }}>
        <Box sx={{ marginTop: 2 }}>
          {/* Skeleton for Company Name */}
          <Skeleton
            variant="text"
            width={500}
            height={80}
            sx={{ marginBottom: 2 }}
          />
          <Skeleton
            variant="text"
            width={500}
            height={80}
            sx={{ marginBottom: 2 }}
          />
          <Skeleton
            variant="text"
            width={500}
            height={80}
            sx={{ marginBottom: 2 }}
          />
          <Skeleton
            variant="text"
            width={500}
            height={80}
            sx={{ marginBottom: 2 }}
          />
        </Box>
      </Box>
    );
  };
  const checkAccessTokenValidity = (accessToken) => {
    try {
      const decodedToken = new CognitoAccessToken({ AccessToken: accessToken });

      const expirationTime = decodedToken.getExpiration();

      const currentTime = Math.floor(Date.now() / 1000);

      if (expirationTime > currentTime) {
        // console.log("Expiration time is greater than current time. Triggering useEffect logic.");
        return expirationTime > currentTime;
      }
    } catch (error) {
      console.error("Error decoding token or missing expiration time:", error);
      return false;
    }
  };

  const refreshtokenentry = () => {
    // console.log("'...token checking...")
    const region = process.env.REACT_APP_AWS_REGION;

    const apiUrl = `https://cognito-idp.${region}.amazonaws.com/`;
    const requestBody = {
      AuthFlow: "REFRESH_TOKEN",
      ClientId: process.env.REACT_APP_CLIENT_ID_USER,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    };
    const headers = {
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      "Content-Type": "application/x-amz-json-1.1",
    };
    axios
      .post(apiUrl, requestBody, { headers })
      .then((response) => {
        // Handle the response as needed
        // console.log(".....refresh response...", response.data);
      })
      .catch((error) => {
        // Handle errors
        // console.error("Error in Axios refresh POST request:", error);
      });
  };

  const checkScreenActivity = () => {
    let isActive = false;

    const handleMove = () => {
      isActive = true;
    };

    window.addEventListener("mousemove", handleMove);

    // You can add more event listeners like 'keydown' or 'touchmove' if needed
    // window.addEventListener('keydown', handleMove);
    // window.addEventListener('touchmove', handleMove);

    setTimeout(() => {
      window.removeEventListener("mousemove", handleMove);
      // Remove other event listeners if added
      // window.removeEventListener('keydown', handleMove);
      // window.removeEventListener('touchmove', handleMove);

      // console.log(isActive ? 'Yes' : 'No');
      if (isActive) {
        // console.log("....yess...")
        refreshtokenentry();
      } else {
        //  console.log("...no...")
      }
    }, 3000); // Adjust the timeout duration as needed (3000 milliseconds = 3 seconds)
  };

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false); // Simulate data fetching
  //   }, 2000); // 2-second loading simulation
  // }, []);

  React.useEffect(() => {
    handleAccessToken();
    const timeoutId = setTimeout(() => {
      console.log("...trigger after 2 min");
      handleAccessToken();
    }, 55 * 60 * 1000);

    const foraccessToken = setTimeout(() => {
      console.log("...trigger after 5 min");
      // toast.error("Session expired");
      handleAccessToken();
    }, 60 * 60 * 1000);
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(foraccessToken);
    };
  }, []);

  const DrawerHeader = styled("div")(({ theme }) => ({
    // display: "flex",
    alignItems: "center",
    // justifyContent: "flex-end",
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,
  }));

  const client_data = localStorage.getItem("client_id");
  React.useEffect(() => {
    const idToken = localStorage.getItem("idToken");
    if (idToken) {
      const decodedToken = decodeJwt(idToken);
      const email = decodedToken.email;
      setEmailLocal(email);
    } else {
      console.error("ID token not found in localStorage");
    }
  }, []); // Empty dependency array ensures this effect runs only once

  // Function to decode JWT token
  function decodeJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  const validateFields = () => {
    let errors = {};

    if (!formData.company) {
      errors.company = "Company Name is required";
    }
    if (!formData.city) {
      errors.city = "Address is required";
    }
    if (!formData.website) {
      errors.website = "Website is required";
    }

    setFormErrors(errors);

    // Return true if there are no errors
    return Object.keys(errors).length === 0;
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    // Optionally reset the form to initial values when canceled
    setFormData({ ...initialFormData });
  };

  const token = localStorage.getItem("idToken");
  React.useEffect(() => {
    // Set loading to true when starting the API request
    setLoading(true);

    // Define the API endpoint
    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    const apiUrl = `${baseUrl}/client/show_client`;

    // Make the POST request using Axios
    axios
      .post(
        apiUrl,
        {
          creator_email_id: emailLocal,
          client_id: client_data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // Handle the API response data here
        console.log("show Client...", response.data);

        // Set the company data and update form data
        setCompanyData(response.data);
        if (response) {
          setInitialFormData({
            country: response.data.address,
            city: response.data.address,
            company: response.data.client_name,
            website: response.data.client_website,
            email: response.data.creator_email_id,
          });
          setFormData({
            country: response.data.address,
            city: response.data.address,
            company: response.data.client_name,
            website: response.data.client_website,
            email: response.data.creator_email_id,
          });
          setLoading(false); // Data loaded, stop loading
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false); // Stop loading if there's an error
      });
  }, []);

  const handleSaveClick = () => {
    if (validateFields()) {
      setEditMode(false);

      // Your save logic here
      console.log("Form data:", formData);
      // console.log(formData.response)

      try {
        const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
        axios
          .post(
            `${baseUrl}/client/update_client`,
            {
              creator_email_id: emailLocal,
              client_id: client_data,
              client_name: formData.company,
              address: formData.city,
              client_website: formData.website,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log("Response:", response);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Reset error when user starts typing
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const accessToken = localStorage.getItem("accessToken");
  const [emailAttribute, setEmailAttribute] = useState(null);
  const [profilePictureAttribute, setProfilePictureAttribute] = useState(null);

  useEffect(() => {
    const getUserAttributes = async () => {
      const cognitoIdentityServiceProvider =
        new AWS.CognitoIdentityServiceProvider();

      try {
        // Get the authenticated user
        const user = await cognitoIdentityServiceProvider
          .getUser({ AccessToken: accessToken })
          .promise();
        console.log("..user..", user);
        // Extract user attributes
        const attributes = user.UserAttributes;

        // Log user attributes, you can filter and extract the ones you need
        // console.log('User attributes:', attributes);

        // Example: Get email and custom profile picture attribute
        const emailAttribute = attributes.find((attr) => attr.Name === "email");
        const profilePictureAttribute = attributes.find(
          (attr) => attr.Name === "picture"
        );
        const nameAttribute = attributes.find(
          (attr) => attr.Name === "given_name"
        );
        const LastNameAttribute = attributes.find(
          (attr) => attr.Name == "family_name"
        );
        // console.log("..Name..",nameAttribute?.Value)
        // console.log('Email:', emailAttribute?.Value);
        // console.log('Profile Picture:', profilePictureAttribute?.Value);
        setProfilePictureAttribute(profilePictureAttribute?.Value);

        setEmailAttribute(emailAttribute?.Value);
        setNameAttribute(nameAttribute?.Value);
        setLastNameAttribute(LastNameAttribute?.Value);
      } catch (error) {
        if (
          error.code === "NotAuthorizedException" &&
          error.message === "Invalid Access Token"
        ) {
          // If the token is expired, attempt to refresh it
          // Implement your token refresh logic here
          console.error("Access token expired. Implement token refresh logic.");
        } else {
          console.error("Error getting user attributes:", error);
        }
      }
    };
    getUserAttributes();
  }, []);

  return (
    <Box
      sx={{
        maxHeight: "calc(100vh - 40px)",
        overflowY: "auto",
        width: "auto",
        height: "100vh",
        paddingLeft: 6,
        backgroundColor: isDarkMode ? "black" : "#F9F9F9",
      }}
    >
      <CssBaseline />
      <NavBar
        pageTitle="Company Profile"
        showNameWithTitle={false}
        nameAttribute={nameAttribute}
        LastnameAttribute={LastnameAttribute}
        profilePictureAttribute={profilePictureAttribute}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        open={open} // Pass the open state
        setOpen={setOpen} // Pass the setOpen function
      />
      <Box
        component="main"
        sx={{
          height: "100%",
          flexGrow: 1,
          pt: 3,
          pl: open ? 20 : 5,
          pr: 3,
          pb: 5,
          backgroundColor: isDarkMode ? "black" : "#F9F9F9",
        }}
      >
        <DrawerHeader />
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            color={isDarkMode ? "#FFF" : "#142764"}
            sx={{ fontWeight: "500", marginLeft: 1 }}
          >
            Profile Details
          </Typography>
          {!editMode && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button
                onClick={handleEditClick}
                variant="contained"
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Box>
          )}
        </Box>
        {/* <Paper style={containerStyle}> */}

        <Container maxWidth="sm" marginTop="10">
          {loading ? (
            // A single skeleton covering the whole form section
            <ProfileDetailsSkeleton />
          ) : (
            <Grid container spacing={4} marginTop={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!editMode}
                  value={formData.company}
                  onChange={handleInputChange}
                  fullWidth
                  id="company"
                  label="Company Name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="company"
                  error={!!formErrors.company}
                  helperText={formErrors.company}
                  autoComplete="tel"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  disabled={!editMode}
                  value={formData.city}
                  onChange={handleInputChange}
                  fullWidth
                  // sx={{ width: 268 }}
                  id="city"
                  label="Address"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="city"
                  error={!!formErrors.city}
                  helperText={formErrors.city}
                  autoComplete="family-name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!editMode}
                  fullWidth
                  value={formData.website}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkIcon sx={{ color: "#B9B9B9" }} />
                      </InputAdornment>
                    ),
                  }}
                  id="website"
                  label="Website"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="website"
                  error={!!formErrors.website}
                  helperText={formErrors.website}
                  autoComplete="website"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={true}
                  value={formData.email}
                  // onChange={handleInputChange}
                  autoComplete="given-name"
                  name="email"
                  fullWidth
                  // sx={{ width: 268 }}
                  id="email"
                  label="Email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoFocus
                />
              </Grid>
              {editMode && (
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: 16 }}
                >
                  <Button
                    onClick={handleCancelClick}
                    style={{ marginRight: 8, width: 100 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSaveClick}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: isDarkMode ? "#080808" : "#EEF3F7",
          position: "fixed",
          bottom: 0,
          width: "100%",
          padding: 2,

          textAlign: "center",
          height: 50,
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: isDarkMode ? "#FFF" : "#142764" }}
        >
          &copy; copyright {new Date().getFullYear()}: Udyat Technologies
        </Typography>
      </Box>
    </Box>
  );
}
