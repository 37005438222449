import * as React from "react";
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router-dom';
import UserPool from '../UserPool';
import { toast } from 'react-toastify';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import logo from "../assets/Icon.png";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

function Register() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [fields, setFields] = React.useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = React.useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    password: false,
  });

  const navigate = useNavigate();

  const onSubmit = React.useCallback((fields) => {
    const countryCode = '+91';
    const formattedPhoneNumber = countryCode + fields.phone.replace(/[^\d+]/g, '');

    const attributeList = [
      { Name: 'email', Value: fields.email },
      { Name: 'given_name', Value: fields.firstName },
      { Name: 'family_name', Value: fields.lastName },
      { Name: 'phone_number', Value: formattedPhoneNumber }
    ].map(attr => new CognitoUserAttribute(attr));

    console.log('Attribute List:', attributeList);

    UserPool.signUp(fields.email, fields.password, attributeList, null, (err, data) => {
      if (err) {
        if (err.message === 'PreSignUp failed with error Signup with public email domains is not allowed..') {
          toast.error('Signup using public email domains is not allowed. Please use a company email.');
        } else if(err.message === 'Password did not conform with policy: Password not long enough') {
          toast.error('Password not long enough Please enter valid password');
         } else if(err.message === "PreSignUp failed with error Existing user found but 'custom:invitedUser' attribute is not present. Denying signup..") {
        toast.error('User with same email domain already exist');
        }else if(err.message === "Existing user found but 'custom:invited' User value is not true. Denying signup..") {
        toast.error('User is not invited');
        }
        else{
          toast.error(err.message)
        }
        console.error('Registration error:', err);
      } else {
        console.log('Registration success:', data);
        navigate("/thank-you", { state: { email: fields.email }});
      }
    });
    
  }, [navigate]);

  const handleTogglePasswordVisibility = React.useCallback(() => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  }, []);

  const handleChange = React.useCallback((event) => {
    const { name, value } = event.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  }, []);

  const handleSubmit = React.useCallback(async (event) => {
    event.preventDefault();
    let formValid = true;
    const updatedErrors = { ...errors };

    Object.entries(fields).forEach(([fieldName, value]) => {
      if (value.trim() === "") {
        updatedErrors[fieldName] = true;
        formValid = false;
      } else {
        updatedErrors[fieldName] = false;
      }
    });

    setErrors(updatedErrors);

    if (formValid) {
      console.log("Form submitted:", fields);
      await onSubmit(fields);
    } else {
      console.log("Form has errors. Please fill in all fields.");
    }
  }, [fields, errors, onSubmit]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: 80, height: 80, padding: 10 }}
            src={logo}
            alt="icon"
          />
          <Typography
            component="h1"
            variant="h5"
            marginTop={2}
            sx={{ color: "#363640", fontWeight: "600" }}
          >
            Create your account!
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3, pb: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.firstName}
                  helperText={errors.firstName ? "Please enter your first name" : ""}
                  autoComplete="given-name"
                  onChange={handleChange}
                  name="firstName"
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.lastName}
                  helperText={errors.lastName ? "Please enter your last name" : ""}
                  onChange={handleChange}
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.phone}
                  helperText={errors.phone ? "Please enter your phone number" : ""}
                  onChange={handleChange}
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  type="tel"
                  autoComplete="tel"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  error={errors.email}
                  helperText={errors.email ? "Please enter your email address" : ""}
                  onChange={handleChange}
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.password}
                  helperText={errors.password ? "Please enter your password" : ""}
                  onChange={handleChange}
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={handleTogglePasswordVisibility}
                        tabIndex={-1}
                        sx={{ padding: 0 }}
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </Button>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, height: 50 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="center" sx={{mb: 5}}>
              <Grid item>
                <Link href="/" variant="body2" underline="none">
                  <Typography
                    variant="caption2"
                    sx={{
                      color: "#797B7C",
                      display: "flex",
                      flexDirection: "row",
                      mt: 0,
                    }}
                  >
                    Already have an account?&nbsp;
                    <Typography
                      variant="caption2"
                      sx={{
                        color: "#115293",
                        fontWeight: "600",
                      }}
                    >
                      Sign In
                    </Typography>
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Box
        sx={{
          backgroundColor: "#EEF3F7",
          position: "fixed",
          bottom: 0,
          width: "100%",
          padding: 2,
          textAlign: "center",
          height: 50,
        }}
      >
        <Typography variant="body2" sx={{ color: "#142764" }}>
          &copy; copyright {new Date().getFullYear()}: Udyat Technologies
        </Typography>
      </Box>
    </ThemeProvider>
  );
}

export default Register;