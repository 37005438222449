import axios from 'axios';
import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../../css/edit.module.css'
import { AuthContext } from '../../Auth/AuthContext';

function Update() {
const [data,setData] = useState('')
const [job_title, setjob_title] = useState('');
  const  [address,setaddress] = useState('');
  const [job_type,setjob_type] = useState('');
  const [schedule,setschedule] = useState('');
  const [number_of_vacancies,setnumber_of_vacancies] = useState('');
  const [target_hiring_date,settarget_hiring_date] = useState('');
  const [skills,setskills] = useState('');
  const [experience,setexperience] = useState('');
  const [qualification,setqualification] = useState('');
  const [key_responsiblities,setkey_responsiblities] = useState('');
 const [deal_breaker_questions,setdeal_breaker_questions] = useState('');
 const [arraydata,setarraydata] = useState([])
 const [normalArray,setnormalquestionarray] = useState([])
 const [number_of_resumes,setnumber_of_resumes] = useState('');
 const [start_date,setstart_date] = useState('');
 const [job_description,setjob_description] = useState('');
 const [job_category,setjob_category] = useState('');
 const [normal_questions,setnormal_questions] = useState('')
//    const [creator_email_id,setcreator_email_id] = useState('');
//   const [creation_time,setcreation_time] = useState('');
//   const [client_website,setclient_website] = useState('');
//   const [client_name,setclient_name] = useState('')

// const { token } = useContext(AuthContext);
// console.log("...editndex ...",token)

const token = localStorage.getItem("idToken")
const location = useLocation()
console.log("...settion location...",location.state);

const client_data = localStorage.getItem("client_id")

 const navigate = useNavigate()

  const UpdateAPi = async () =>{
      try {
        const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
          const response = axios.post(`${baseUrl}/job/update_job` ,{
            job_id : location.state.job_id,
             client_id:  client_data,
             job_title : job_title ? job_title : location.state.job_title,
             address: address ? address : location.state.address,
              deal_breaker_questions: deal_breaker_questions ? deal_breaker_questions : location.state.deal_breaker_questions,
             experience: experience ? experience : location.state.experience,
             job_category: job_category ? job_category : location.state.job_category,
             job_description:  job_description ? job_description : location.state.job_description,
             job_title: job_title ? job_title : location.state.job_title,
             job_type:  job_type ? job_type :location.state.job_type,
             key_responsiblities: key_responsiblities ? key_responsiblities : location.state.key_responsiblities,
             normal_questions:  normal_questions ? normal_questions : location.state.normal_questions,
             number_of_resumes: number_of_resumes ? number_of_resumes : location.state.number_of_resumes,
             number_of_vacancies: number_of_vacancies ? number_of_vacancies : location.state.number_of_vacancies,
             qualification: qualification ? qualification : location.state.qualification,
             schedule: schedule ? schedule : location.state.schedule,
             skills: skills ? skills : location.state.skills,
             start_date:  start_date ?  start_date : location.state.start_date,
             target_hiring_date: target_hiring_date ? target_hiring_date : location.state.target_hiring_date

              // client_name:  client_name ? client_name : location.state.client_name,
              // address: address ? address : location.state.address,
              // client_website : client_website ? client_website : location.state.client_website,
              // creation_time : creation_time ? creation_time : location.state.creation_time
            },{
              headers: {
                  Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
          }
            )
            
            console.log(".jhjhjjhhhj,,",response);
          //   console.log(response.data.message);
            navigate('/dashboard')
            setData('Data sent successfully!');
      }
      catch(error){
          console.error("..error..",error);
          setData('Error sending data.');
          // return toast.error(error.message)
      }
 
  }

  
    const CancelAPi = () => {
      // Check if location.state and location.state.job_id are defined
      if (location.state && location.state.job_id) {
        // If defined, navigate to the appropriate page
         const job_id = location.state.job_id
         console.log("...job_id...",job_id)
        navigate('/job-details',{state:job_id});
        localStorage.setItem('job_id', location.state.job_id);
      } else {
        // Handle the case where job_id is not available in location.state
        console.error("Cannot read properties of null (reading 'job_id')");
        // You may choose to navigate to a default page or handle the situation differently
      }
    };
  
  
const handleDealBreakerChange = (index, value) => {
  const newArray = [...arraydata];
  newArray[index] = value;
  setarraydata(newArray);
  setdeal_breaker_questions(newArray.join(', '));
};
const handleDeleteDealBreaker = (index) => {
  const newArray = [...arraydata];
  newArray.splice(index, 1);
  setarraydata(newArray);
  setdeal_breaker_questions(newArray.join(', '));
};

const handleAddDealBreaker = () => {
  setarraydata([...arraydata, '']);
};
const handleNormalQuestionChange = (index, value) => {
  const newArray = [...normalArray];
  newArray[index] = value;
  setnormalquestionarray(newArray);
  setnormal_questions(newArray.join(', '));
};
const handleDeleteNormalQuestion = (index) => {
  const newArray = [...normalArray];
  newArray.splice(index, 1);
  setnormalquestionarray(newArray);
  setnormal_questions(newArray.join(', '));
};
const handleAddNormalQuestion = () => {
  setnormalquestionarray([...normalArray, '']);
};

  useEffect(() => {
    const separatedValuesArray = location.state.deal_breaker_questions
      ? location.state.deal_breaker_questions
          .replace(/\[|\]|'/g, '') // Remove [ ] and single quotes
          .split(',')
          .map(value => value.trim()) // Trim leading and trailing spaces
      : [];
  
    setarraydata(separatedValuesArray.map((value, index) => `${value}`));
    const separatednormalQuestionValuesArray = location.state.normal_questions
    ? location.state.normal_questions
        .replace(/\[|\]|'/g, '') // Remove [ ] and single quotes
        .split(',')
        .map(value => value.trim()) // Trim leading and trailing spaces
    : [];

  setnormalquestionarray(separatednormalQuestionValuesArray.map((value, index) => `${value}`));
  }, [location.state.deal_breaker_questions,location.state.normal_questions]);
  return (
    <div  className= {styles.maineditdiv}>
       <div>
              <label htmlFor="name" style={{ marginLeft: "88px",color: "gray" }}>JOB TITLE</label>
              <input className={styles.editpageinput}
              type="text"
              id="job_title"
              name="job_title"
              defaultValue={location.state.job_title}
              // value={location.state.job_title}
              onChange={(e) => setjob_title(e.target.value)}
              
            />
        </div>
        <div>
              <label htmlFor="name" style={{ marginLeft: "88px",color: "gray" }}>ADDRESS</label>
              <input className={styles.editpageinput}
              type="text"
              id="address"
              name="address"
              defaultValue={location.state.address}
              onChange={(e) => setaddress(e.target.value)}
              
            />
        </div>
        <div>
              <label htmlFor="name" style={{ marginLeft: "88px",color: "gray" }}>JOB TYPE</label>
              <input className={styles.editpageinput}
              type="text"
              id="job_type"
              name="job_type"
              defaultValue={location.state.job_type}
              onChange={(e) => setjob_type(e.target.value)}
              
            />
        </div>
        <div>
              <label htmlFor="name" style={{ marginLeft: "88px",color: "gray" }}>SCHEDULE</label>
              <input className={styles.editpageinput}
              type="text"
              id="schedule"
              name="schedule"
              defaultValue={location.state.schedule}
              onChange={(e) => setschedule(e.target.value)}
              
            />
        </div>
        <div>
              <label htmlFor="name" style={{ marginLeft: "88px",color: "gray" }}>NO OF VACANCIES</label>
              <input className={styles.editpageinput}
              type="text"
              id="number_of_vacancies"
              name="number_of_vacancies"
              defaultValue={location.state.number_of_vacancies}
              onChange={(e) => setnumber_of_vacancies(e.target.value)}
              
            />
        </div>
        <div>
              <label htmlFor="name" style={{ marginLeft: "88px",color: "gray" }}>TARGET HIRING DATE</label>
              <input className={styles.editpageinput}
              type="text"
              id="target_hiring_date"
              name="target_hiring_date"
              defaultValue={location.state.target_hiring_date}
              onChange={(e) => settarget_hiring_date(e.target.value)}
              
            />
        </div>
        <div>
              <label htmlFor="name" style={{ marginLeft: "88px",color: "gray" }}>SKILLS</label>
              <input className={styles.editpageinput}
              type="text"
              id="skills"
              name="skills"
              defaultValue={location.state.skills}
              onChange={(e) => setskills(e.target.value)}
              
            />
        </div>
        <div>
              <label htmlFor="name" style={{ marginLeft: "88px",color: "gray" }}>EXPERIENCE</label>
              <input className={styles.editpageinput}
              type="text"
              id="experience"
              name="experience"
              defaultValue={location.state.experience}
              onChange={(e) => setexperience(e.target.value)}
              
            />
        </div>
        <div>
              <label htmlFor="name" style={{ marginLeft: "88px",color: "gray" }}>QUALIFICATION</label>
              <input className={styles.editpageinput}
              type="text"
              id="qualification"
              name="qualification"
              defaultValue={location.state.qualification}
              onChange={(e) => setqualification(e.target.value)}
              
            />
        </div>
        <div>
              <label htmlFor="name" style={{ marginLeft: "88px",color: "gray" }}>QUALIFICATION</label>
              <input className={styles.editpageinput}
              type="text"
              id="number_of_resumes"
              name="number_of_resumes"
              defaultValue={location.state.number_of_resumes}
              onChange={(e) => setnumber_of_resumes(e.target.value)}
              
            />
        </div>
        <div>
              <label htmlFor="name" style={{ marginLeft: "88px",color: "gray" }}>KEY RESPONSIBILITY</label>
              <input className={styles.editpageinput}
              type="text"
              id="key_responsiblities"
              name="key_responsiblities"
              value={location.state.key_responsiblities}
              onChange={(e) => setkey_responsiblities(e.target.value)}
              
            />
        </div>
        {/* <div>
              <label htmlFor="name" style={{ marginLeft: "88px",color: "gray" }}>DEAL BREAKER QUESTIONS</label>
              <input className={styles.editpageinput}
              type="text"
              id="deal_breaker_questions"
              name="deal_breaker_questions"
              value={location.state.deal_breaker_questions}
              onChange={(e) => setdeal_breaker_questions(e.target.value)}
              
            />
        </div> */}
        {/* <div>
        <label style={{ marginLeft: "88px",color: "gray" }}>Deal Breaker Questions:</label>
        {arraydata.map((formattedString, index) => (
          <input  className={styles.editpageinput}
            key={index}
        
            type="text"
            value={formattedString}
            onChange={(e) => {
              const newArray = [...arraydata];
              newArray[index] = e.target.value;
              setarraydata(newArray);
              setdeal_breaker_questions(newArray.join(', '));
            }}
          />
        ))}
      </div>  */}
      <div>
  <label style={{ marginLeft: "88px", color: "gray" }}>Deal Breaker Questions:</label>
  {arraydata.map((formattedString, index) => (
    <div key={index} className={styles.inputContainer}>
      <input
        className={styles.editpageinput}
        type="text"
        value={formattedString}
        onChange={(e) => handleDealBreakerChange(index, e.target.value)}
      />
      {index === arraydata.length - 1 && (
        <button className={styles.plusButton} onClick={() => handleAddDealBreaker()}>
          +
        </button>
      )}
      {arraydata.length > 1 && (
        <button className={styles.crossButton} onClick={() => handleDeleteDealBreaker(index)}>
          ✖
        </button>
      )}
    </div>
  ))}
</div>
        <div>
              <label htmlFor="name" style={{ marginLeft: "88px",color: "gray" }}>START DATE</label>
              <input className={styles.editpageinput}
              type="text"
              id="start_date"
              name="start_date"
              value={location.state.start_date}
              onChange={(e) => setstart_date(e.target.value)}
              
            />
        </div>
        <div>
              <label htmlFor="name" style={{ marginLeft: "88px",color: "gray" }}>JOB DESCRIPTION</label>
              <input className={styles.editpageinput}
              type="text"
              id="job_description"
              name="job_description"
              defaultValue={location.state.job_description}
              onChange={(e) => setjob_description(e.target.value)}
              
            />
        </div>
        <div>
              <label htmlFor="name" style={{ marginLeft: "88px",color: "gray" }}>JOB CATEGORY</label>
              <input className={styles.editpageinput}
              type="text"
              id="job_category"
              name="job_category"
              defaultValue={location.state.job_category}
              onChange={(e) => setjob_category(e.target.value)}
              
            />
        </div>
        {/* <div>
              <label htmlFor="name" style={{ marginLeft: "88px",color: "gray" }}>NORMAL QUESTIONS</label>
              <input className={styles.editpageinput}
              type="text"
              id="normal_questions"
              name="normal_questions"
             
              value={location.state.normal_questions}
              onChange={(e) => setnormal_questions(e.target.value)}
              
            />
        </div> */}
         {/* <div>
        <label style={{ marginLeft: "88px",color: "gray" }}>NORMAL QUESTIONS</label>
        {normalArray.map((formattedString, index) => (
          <input  className={styles.editpageinput}
            key={index}
        
            type="text"
            value={formattedString}
            onChange={(e) => {
              const newArray = [...normalArray];
              newArray[index] = e.target.value;
              setnormalquestionarray(newArray);
              setnormal_questions(newArray.join(', '));
            }}
          />
        ))}
      </div> */}
      <div>
        <label style={{ marginLeft: "88px",color: "gray" }}>NORMAL QUESTIONS</label>
        {normalArray.map((formattedString, index) => (
          <div key={index} className={styles.inputContainer}>
          <input 
           className={styles.editpageinput}
           type="text"
            value={formattedString}
            onChange={(e) => handleNormalQuestionChange(index, e.target.value)}
          />
          {index === normalArray.length - 1 &&(
            <button className={styles.plusButton} onClick={() => handleAddNormalQuestion()}>
            +
          </button>
          )}
          {arraydata.length > 1 && (
        <button className={styles.crossButton} onClick={() => handleDeleteNormalQuestion(index)}>
          ✖
        </button>
      )}
          </div>
        ))}
      </div>
        <button onClick={() => UpdateAPi()} className={styles.editupdatebutton} type='submit'>Update</button>
        
        <button onClick={() => CancelAPi()} className={styles.editupdatebutton} type='submit'>Cancel</button>
    </div>

  )
}

export default Update