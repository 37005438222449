// import React from 'react';
// import '../css/ExistingPage.css'
// function ExistingPage(){
  


//     return(
//         <div className='data'>
        
//   <form>
//     <label for="fname">Company Name</label>
//     <input className='formData' type="text" id="fname" name="firstname" placeholder="Company name.."/>

//     <label for="lname">Company Website</label>
//     <input className='formData' type="text" id="lname" name="lastname" placeholder="Company website.."/>
   
   
//     <label for="lname">EmailId</label>
//     <input className='formData' type="email" id="lname" name="lastname" placeholder="Emailid.."/>
    
   
  
//     <input  className='formSubmit' type="submit" value="Submit"/>
//   </form>
// </div>
//     )
// }


// export default ExistingPage;

// ContactForm.js
import React, { useState } from 'react';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';

function ExistingPage() {

  const [creator_email_id, setcreator_email_id] = useState('');
  const [client_name , setclient_name] = useState('');
  const [client_website,setclient_website] = useState('');
  const[user_email_id,setuser_email_id] = useState('')

  const navigate = useNavigate()

  
  const CreateUser = async () =>{
    try {
      const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
      const response = await axios.post(`${baseUrl}/user/create_user`, {
        creator_email_id : creator_email_id,
        client_name : client_name,
        client_website : client_website,
        user_email_id : user_email_id
      });

      // Handle a successful response here
     
      navigate('/dashboard')
    } catch (error) {
      // Handle errors here
      console.error('Errorr:', error);
    }
      };

  const handleSubmit = async (e) => {
    e.preventDefault();

  
  };

  return (
    <div>
      <h2>Contact Us</h2>
      <div onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={client_name}
            onChange={(e) => setclient_name(e.target.value)}
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={creator_email_id}
            onChange={(e) => setcreator_email_id(e.target.value)}
          />
        </div>
        <div>
          <label>Client-Website:</label>
          <input
            type="text"
            name="email"
            value={client_website}
            onChange={(e) => setclient_website(e.target.value)}
          />
        </div>
        <div>
          <label>User-EmailId:</label>
          <input
            type="email"
            name="email"
            value={user_email_id}
            onChange={(e) => setuser_email_id(e.target.value)}
          />
        </div>
        
        <div>
          <button onClick={() => CreateUser()}>Submit</button>
        </div>
      </div>
    </div>
  );
}

export default ExistingPage ;
