import React, { useContext, useEffect, useState } from "react";
import { styled, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { toast } from "react-toastify";
import ListItemText from "@mui/material/ListItemText";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Avatar,
  Button,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import { CognitoAccessToken } from "amazon-cognito-identity-js";
import logo_Text from "../../assets/Coucal_Text.png";
import logo from "../../assets/Icon.png";
import AWS from "aws-sdk";
import axios from "axios";
// import animated from "../../assets/animated_logo.gif";
import Chip from "@mui/material/Chip";

export default function NavBar({showNameWithTitle = false,  pageTitle, nameAttribute, LastnameAttribute, profilePictureAttribute,open,  handleDrawerOpen, handleDrawerClose, setOpen,   }) {
  const [iconClicked, setIconClicked] = React.useState({}); // Use an object to store clicked state per row
  const location = useLocation();

  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [datas, setData] = useState([]);
//   const [profilePictureAttribute, setProfilePictureAttribute] = useState(null);
  const [emailAttribute, setEmailAttribute] = useState(null);
  // const [nameAttribute, setNameAttribute] = useState(null);
  // const [LastnameAttribute, setLastNameAttribute] = useState(null);
  const [creditScore, setCreditScore] = useState("");
  const refreshToken = localStorage.getItem("refreshToken");
  const [disabledJobs, setDisabledJobs] = useState([]);
  const [pauseData, setPauseData] = useState([]);
  const [handle, setHandle] = useState([]);
  const [rowClickedIcons, setRowClickedIcons] = React.useState(
    () => JSON.parse(localStorage.getItem("iconClicked")) || {}
  );
  const drawerWidth = 200;
  const backgroundDrawer = isDarkMode ? "#1F1F1F" : "#FFF";
  const colorDrawer = isDarkMode ? "#FFF" : "black";
  const [openDialog, setOpenDialog] = useState(false);
  const [suspendJobId, setSuspendjobId] = useState(false);
  let clientId = localStorage.getItem("client_id");
  const accessToken = localStorage.getItem("accessToken");
  // console.log("...dashboard client id ...", clientId);
  const navigate = useNavigate();
  const token = localStorage.getItem("idToken");
  // console.log(".. dashboard token...", token);
  const DrawerHeader = styled("div")(({ theme }) => ({
    // display: "flex",
    alignItems: "center",
    // justifyContent: "flex-end",
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const handleClickAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleResize = () => {
    if (window.innerWidth < 600) {
      // Adjust the threshold as needed
      setOpen(false); // Close the drawer on smaller screens
    } else {
      setOpen(true); // Keep the drawer open on larger screens
    }
  };
  const onCellClick = (params) => {
    const { field } = params;

    if (field === "actions") {
      // handle actions
    } else {
      handleResume(params);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  // const handleEdit = (id) => {
  //   // navigate('/edit-job')
  //   console.log("Editing row with ID:", id);

  // };

  const SuspendJOb = (id) => {
    console.log("...job details...");
    console.log("suspendjob...", id);
    const clickedRow = datas.find((row) => row.id === id);
    console.log(".sus[endclickedRow]...", clickedRow.job_id);

    const pausedJobIds = handle
      .filter((item) => item.pause_status == "0")
      .map((item) => item.job_id);
    // Check if job is paused
    const isPaused = pausedJobIds.includes(clickedRow.job_id);

    // If job is paused, skip showing confirmation dialog
    if (isPaused) {
      console.log("Job is paused. Skip showing confirmation dialog.");
      return;
    }

    // Set the suspend job ID and show the confirmation dialog
    setSuspendjobId(clickedRow.job_id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog without deleting
  };

  const SuspendJObConfirm = () => {
    console.log("...suspendJobId...", suspendJobId);
    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    const apiUrl = `${baseUrl}/job/suspend_job`;

    axios
      .post(
        apiUrl,
        {
          client_id: clientId,
          job_id: suspendJobId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const handleConfirmDelete = () => {
    // onDelete(id); // Call the delete function
    setOpenDialog(false); // Close the dialog after deleting
    SuspendJObConfirm();
  };

  const handleRowClick = (params) => {
    const { field } = params;

    if (field !== "actions") {
      handleResume(params);
    }
  };
  const [forceRender, setForceRender] = useState(false);

  const handleClick = (id) => {
    console.log("...id..", id);
    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    const apiUrl = `${baseUrl}/job/pause_job`;
    const clickedRow = datas.find((row) => row.id === id);
    console.log(".sus[endclickedRow]...", clickedRow.job_id);

    // Determine the new pause status based on the current state
    const newPauseStatus = pauseData[id - 1] === "0" ? "1" : "0";

    axios
      .post(
        apiUrl,
        {
          client_id: clientId,
          job_id: clickedRow.job_id,
          pause_status: newPauseStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        // Update pauseData with the new pause status
        setPauseData((prevPauseData) => {
          const updatedPauseData = [...prevPauseData];
          updatedPauseData[id - 1] = newPauseStatus;
          return updatedPauseData;
        });
        // Optionally, update other relevant state variables or trigger side effects

        // Display toast message if the job is paused
        if (newPauseStatus === "1") {
          toast.error("Job is temporarily paused");
          console.log("Job is temporarily paused");
        }
        window.location.reload();
        // No need to manually refresh the page here
        // Trigger re-render by updating the refreshComponent state variable
        // setForceRender((prevValue) => !prevValue);
      })
      .catch((error) => {
        console.log("Error", error);
        // Handle error or display error message
      });
  };
  const columns = [
    {
      field: "role",
      headerName: "Role",

      type: "text",
      flex: 1,
      align: "center",
      headerAlign: "center",
      color: isDarkMode ? "#FFF" : "#000",
      cellClassName: "cell-theme",
    },
    {
      field: "resume",
      type: "text",

      headerName: "Total Uploaded Resumes",
      flex: 1,
      align: "center",
      headerAlign: "center",
      cellClassName: "cell-theme",
    },
    {
      field: "processed",
      type: "text",

      headerName: "Processed Resumes",
      flex: 1,
      align: "center",
      headerAlign: "center",
      cellClassName: "cell-theme",
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      align: "center",
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isClicked = rowClickedIcons[id] || false;
        const disabledRow = isClicked ? "disabled-row" : "";
        const pauseStatus = pauseData[id - 1] || "";
        const handleIconClick = isClicked ? null : () => handleClick(id);
        // console.log("id:", id);
        // console.log("pauseStatus:", pauseData[id - 1]); // Adjust for zero-indexing

        return [
          <Tooltip
            title={pauseStatus === "0" ? "enable" : "disable"}
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: pauseStatus === "0" ? "#0039a6" : "#D2122E",
                  color: "#FFF",
                },
              },
            }}
          >
            <GridActionsCellItem
              icon={
                pauseStatus === "0" ? (
                  <DoNotDisturbOnOutlinedIcon sx={{ color: "red" }} />
                ) : (
                  <TaskAltOutlinedIcon
                    sx={{ color: isDarkMode ? "#00C957" : "green" }}
                  />
                )
              }
              label={isClicked ? "Disable" : "Enable"}
              onClick={handleIconClick} // Pass the ID to handleClick
              sx={{ color: isClicked ? "#142764" : "#142764" }}
              border="none"
            />
          </Tooltip>,

          <Tooltip
            title="edit job"
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#0039a6",
                  color: "#FFF",
                },
              },
            }}
          >
            {/* {datas.map((item) => ( */}
            <GridActionsCellItem
              // key={item.id}
              // onClick={() => handlejobedit(item.job_id)}
              icon={<EditIcon />}
              onClick={(event) => {
                event.stopPropagation(); // Stop the event from bubbling up to the row click event
                handleEdit(id);
              }}
              label="Edit"
              className="textPrimary"
              sx={{ color: isDarkMode ? "#78E9DE" : "#142764" }}
              border="none"
            />
          </Tooltip>,

          <Tooltip
            title="delete"
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#D2122E",
                  color: "#FFF",
                },
              },
            }}
          >
            <GridActionsCellItem
              icon={<DeleteForeverIcon />}
              label="Delete"
              onClick={(event) => {
                event.stopPropagation(); // Prevent any row click events from firing
                const clickedRow = datas.find((row) => row.id === id);
                const pausedJobIds = handle
                  .filter((item) => item.pause_status === "0")
                  .map((item) => item.job_id);
                // Check if the job is already paused
                if (pausedJobIds.includes(clickedRow.job_id)) {
                  toast.error("Job is already paused");
                  console.log(
                    "Job is already paused. Skipping opening dialog."
                  );
                  return;
                }
                setOpenDialog(true);
                SuspendJOb(id);
              }}
              // onClick={handleDeleteClick}
              sx={{ color: isDarkMode ? "#FE0000" : "red" }}
              border="none"
            />
            {/* Dialog for confirmation */}
          </Tooltip>,
          <Box>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Confirm Deletion</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this job?
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirmDelete}
                  color="primary"
                  variant="contained"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Box>,
        ];
      },
    },
  ];
  const [theme, setTheme] = React.useState(false);

  const handleDarkModeUI = () => {
    setIsDarkMode(!isDarkMode);
    const darkTheme = createTheme({
      palette: {
        mode: isDarkMode ? "light" : "dark",
      },
    });
    setTheme(darkTheme);
  };
  const openedMixin = (theme, isDarkMode) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    backgroundColor: backgroundDrawer,
    color: colorDrawer,
  });

  const closedMixin = (theme, isDarkMode) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    backgroundColor: backgroundDrawer,
    color: colorDrawer,
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open, isDarkMode }) => ({
    width: drawerWidth,
    backgroundColor: isDarkMode ? "black" : "#FFF",
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme, isDarkMode),
      "& .MuiDrawer-paper": {
        ...openedMixin(theme, isDarkMode),
      },
    }),
    ...(!open && {
      ...closedMixin(theme, isDarkMode),
      "& .MuiDrawer-paper": {
        ...closedMixin(theme, isDarkMode),
      },
    }),
  }));

//   const [open, setOpen] = React.useState(false);

//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };

  const handleLogout = () => {
    localStorage.setItem("logedIn", false);

    navigate("/");
  };
  const handleDocumentation = () => {
    navigate("/documentation");
  };
  const handleDashboard = () => {
    navigate("/dashboard");
  };
  const handleSetting = () => {
    navigate("/setting");
  };

  const handleJobs = () => {
    navigate("/jobs");
  };

  const handleResume = (params) => {
    const { field, value, row } = params;

    if (field === "role" || field === "resume" || field === "processed") {
      const clickedRow = params.row;

      if (clickedRow) {
        const jobId = clickedRow.job_id;
        const JobTitle = clickedRow.role;
        const techCall = clickedRow.technicalCall;
        const screeningStatus = clickedRow.screeningStatus;
        const codingAssessmentRound = clickedRow.codingAssessment;
        const aptitudeAssessmentRound = clickedRow.aptitudeAssessment;
        const pauseStatus = clickedRow.pause_status;
        const jobDisable = clickedRow.job_disable;

        console.log("column clickedRow", clickedRow);
        const initCall = clickedRow.intialCall;

        // const pausedJobIds = handle
        //   .filter((item) => item.pause_status === "0")
        //   .map((item) => item.job_id);
        // const isPaused = pausedJobIds.includes(jobId);

        // if (isPaused) {
        //   toast.error("Job is temporarily paused");
        // } else {
        navigate("/report", {
          state: {
            job_id: jobId,
            JobTitle: JobTitle,
            tech_call: techCall,
            scr_status: screeningStatus,
            init_call: initCall,
            coding_assessment: codingAssessmentRound,
            aptitude_assessment: aptitudeAssessmentRound,
            pause_status: pauseStatus,
            job_disable: jobDisable,
          },
        });
      }
      // }
    }
  };

  const handleEdit = (id) => {
    const clickedRow = datas.find((row) => row.id === id);
    console.log("Clicked row:", clickedRow);
    const pausedJobIds = handle
      .filter((item) => item.pause_status === "0")
      .map((item) => item.job_id);

    // Check if clicked row's job_id is present in pausedJobIds array
    const isPaused = pausedJobIds.includes(clickedRow.job_id);

    if (clickedRow) {
      const jobId = clickedRow.job_id;

      if (isPaused) {
        toast.error("Job is paused. Edit action is disabled.");
        console.log("Job is paused. Edit action is disabled.");
      } else if (clickedRow.job_disable === "True") {
        toast.error("Job is expired. Edit action is disabled.");
        console.log("Job is expired. Edit action is disabled.");
      } else {
        localStorage.setItem("Edit-jobId", clickedRow.job_id);
        navigate("/edit-job", { state: { job_id: jobId } });
      }
    }
  };

  //   useEffect(() => {
  //     const getUserAttributes = async () => {
  //       const cognitoIdentityServiceProvider =
  //         new AWS.CognitoIdentityServiceProvider();

  //       try {
  //         // Get the authenticated user
  //         const user = await cognitoIdentityServiceProvider
  //           .getUser({ AccessToken: accessToken })
  //           .promise();
  //         console.log("..user..", user);
  //         // Extract user attributes
  //         const attributes = user.UserAttributes;

  //         // Log user attributes, you can filter and extract the ones you need
  //         // console.log('User attributes:', attributes);

  //         // Example: Get email and custom profile picture attribute
  //         const emailAttribute = attributes.find((attr) => attr.Name === "email");
  //         const profilePictureAttribute = attributes.find(
  //           (attr) => attr.Name === "picture"
  //         );
  //         const nameAttribute = attributes.find(
  //           (attr) => attr.Name === "given_name"
  //         );
  //         const LastNameAttribute = attributes.find(
  //           (attr) => attr.Name == "family_name"
  //         );
  //         // console.log("..Name..",nameAttribute?.Value)
  //         // console.log('Email:', emailAttribute?.Value);
  //         // console.log('Profile Picture:', profilePictureAttribute?.Value);
  //         setProfilePictureAttribute(profilePictureAttribute?.Value);

  //         setEmailAttribute(emailAttribute?.Value);
  //         setNameAttribute(nameAttribute?.Value);
  //         setLastNameAttribute(LastNameAttribute?.Value);
  //       } catch (error) {
  //         if (
  //           error.code === "NotAuthorizedException" &&
  //           error.message === "Invalid Access Token"
  //         ) {
  //           // If the token is expired, attempt to refresh it
  //           // Implement your token refresh logic here
  //           console.error("Access token expired. Implement token refresh logic.");
  //         } else {
  //           console.error("Error getting user attributes:", error);
  //         }
  //       }
  //     };
  //     getUserAttributes();
  //   }, []);
  const handleAccessToken = () => {
    const accessToken = localStorage.getItem("accessToken");

    const navigateAndReturn = (path) => {
      navigate(path);
      return false;
    };

    if (!accessToken || !checkAccessTokenValidity(accessToken)) {
      localStorage.setItem("logedIn", false);
      return navigateAndReturn("/");
    } else {
      // console.log("...to redirect on dashboard")
      // refreshtokenentry()
      checkScreenActivity();
      return navigateAndReturn("/navbar");
    }
    // console.log("...dashboard return ...");
  };

  const checkAccessTokenValidity = (accessToken) => {
    try {
      const decodedToken = new CognitoAccessToken({ AccessToken: accessToken });

      const expirationTime = decodedToken.getExpiration();

      const currentTime = Math.floor(Date.now() / 1000);

      if (expirationTime > currentTime) {
        // console.log("Expiration time is greater than current time. Triggering useEffect logic.");
        return expirationTime > currentTime;
      }
    } catch (error) {
      console.error("Error decoding token or missing expiration time:", error);
      return false;
    }
  };
  const validateEmail = (input) => {
    const regex = /^[a-zA-Z0-9.]+$/;
    if (!regex.test(input)) {
      setEmailError("Only alphanumeric characters are allowed");
    } else {
      setEmailError("");
    }
    setEmail(input);
  };

  const refreshtokenentry = () => {
    // console.log("'...token checking...")
    const region = process.env.REACT_APP_AWS_REGION;

    const apiUrl = `https://cognito-idp.${region}.amazonaws.com/`;
    const requestBody = {
      AuthFlow: "REFRESH_TOKEN",
      ClientId: process.env.REACT_APP_CLIENT_ID_USER,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    };
    const headers = {
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      "Content-Type": "application/x-amz-json-1.1",
    };
    axios
      .post(apiUrl, requestBody, { headers })
      .then((response) => {
        // Handle the response as needed
        // console.log(".....refresh response...", response.data);
      })
      .catch((error) => {
        // Handle errors
        // console.error("Error in Axios refresh POST request:", error);
      });
  };

  //inviteteammate

  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailError, setEmailError] = useState("");

  const ls_clientId = localStorage.getItem("client_id");

  const handleInvite = async (e) => {
    e.preventDefault();
    let client_ids = ls_clientId;
    let converted_client_id = Math.round(client_ids / 1000);

    if (!emailError) {
      try {
        const inviteTeammatesPayload = {
          email_ids_code: [email + "@" + emailAttribute.split("@")[1]],
          client_id: converted_client_id,
          usertype: "false",
          invited_user: "true",
        };

        const createInviteUserPayload = {
          user_email_id: email + "@" + emailAttribute.split("@")[1],
          client_id: ls_clientId.toString(),
        };

        const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
        const inviteTeammatesUrl = `${baseUrl}/invite_teammates`;
        const createInviteUserUrl = `${baseUrl}/user/create_invite_user`;

        // Get the idToken from localStorage
        const idToken = localStorage.getItem("idToken");

        if (!idToken) {
          throw new Error("No idToken found in localStorage");
        }

        // Create headers object with Authorization
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        };

        // Call both APIs concurrently with their respective payloads and the authorization header
        const [inviteResponse, createInviteResponse] = await Promise.all([
          axios.post(inviteTeammatesUrl, inviteTeammatesPayload, { headers }),
          axios.post(createInviteUserUrl, createInviteUserPayload, { headers }),
        ]);

        if (
          inviteResponse.status === 200 &&
          createInviteResponse.status === 200
        ) {
          const userData = inviteResponse.data[0];

          if (userData.status === "User already exists") {
            toast.info("User has already been sent an invite link");
          } else {
            setEmail("");
            setIsModalOpen(false);
            toast.success("Invitation sent successfully");
          }
        } else {
            setIsModalOpen(false);
          toast.success("Invited Link Send Successfully");
        }
      } catch (error) {
        toast.info("User has already been sent an invite link");
      }
    }
  };
  const checkScreenActivity = () => {
    let isActive = false;

    const handleMove = () => {
      isActive = true;
    };

    window.addEventListener("mousemove", handleMove);
    setTimeout(() => {
      window.removeEventListener("mousemove", handleMove);

      if (isActive) {
        // console.log("....yess...")
        refreshtokenentry();
      } else {
        //  console.log("...no...")
      }
    }, 3000); // Adjust the timeout duration as needed (3000 milliseconds = 3 seconds)
  };

  //   useEffect(() => {
  //     handleAccessToken();
  //     const timeoutId = setTimeout(() => {
  //       console.log("...trigger after 2 min");
  //       handleAccessToken();
  //       // 55 * 60 * 1000
  //     }, 55 * 60 * 1000);

  //     const foraccessToken = setTimeout(() => {
  //       console.log("...trigger after 5 min");
  //       // toast.error("Session expired");
  //       handleAccessToken();
  //       // 60 * 60 * 1000
  //     }, 60 * 60 * 1000);
  //     return () => {
  //       clearTimeout(timeoutId);
  //       clearTimeout(foraccessToken);
  //     };
  //   }, []);
  useEffect(() => {
    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    const apiUrl = `${baseUrl}/show_credit_balance`;

    const requestBody = {
      client_id: ls_clientId,
    };
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    axios
      .post(apiUrl, requestBody, { headers })
      .then((response) => {
        // Handle the response as needed
        console.log(
          ".....credit balance response...",
          response.data.credit_balance
        );
        setCreditScore(response.data.credit_balance);
      })
      .catch((error) => {
        // Handle errors
        // console.error("Error in Axios refresh POST request:", error);
      });
  }, [ls_clientId]);

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{
          paddingLeft: 0,
          paddingRight: 35,
          backgroundColor: isDarkMode ? "#1F1F1F" : "#F9F9F9",
          color: isDarkMode ? "#000" : "#DDE8EE",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Toolbar style={{}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon sx={{ color: isDarkMode ? "#FFF" : "grey" }} />
          </IconButton>
          <Typography
            variant="h5"
            component="h2"
            color={isDarkMode ? "#FFF" : "#142764"}
            fontWeight="700"
            sx={{ paddingTop: 1.5, paddingBottom: 1 }}
          >
            {showNameWithTitle 
              ? `Hello, ${nameAttribute} ${LastnameAttribute}`
              : pageTitle
            }
          </Typography>
        </Toolbar>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 30,
          }}
        >
          <Box
            sx={{
              mt: 1, // Margin top
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              color={isDarkMode ? "#FFF" : "text.secondary"}
              sx={{ mr: 1 }} // Margin-right for spacing
            >
              Credit balance:
            </Typography>
            <Avatar
              sx={{
                width: 45, // Width of the Avatar
                height: 30, // Height of the Avatar
                borderRadius: 1, // Rounded corners
                backgroundColor:
                  creditScore < 50
                    ? "error.main" // Red for credit score < 50
                    : creditScore >= 50 && creditScore <= 100
                    ? "warning.main" // Yellow for credit score 50-100
                    : "success.main", // Green for credit score > 100
                color: "white",
                paddingX: 1, // Horizontal padding
                paddingY: 0.5, // Vertical padding
              }}
            >
              <Typography variant="subtitle2" color="inherit">
                {creditScore || 0}
              </Typography>
            </Avatar>
          </Box>

          <Tooltip title="Profile settings">
            <IconButton
              onClick={handleClickAnchor}
              size="small"
              sx={{ mt: 1 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              {profilePictureAttribute ? (
                <Avatar
                  src={profilePictureAttribute}
                  onClick={() => navigate("/profile-update")}
                  sx={{ width: 35, height: 35 }}
                />
              ) : (
                <Avatar
                  onClick={() => navigate("/profile-update")}
                  sx={{ width: 35, height: 35, backgroundColor: "#1f098c" }}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginTop: "3px",
                    }}
                  >
                    {nameAttribute?.charAt(0).toUpperCase()}
                    {LastnameAttribute?.charAt(0).toUpperCase()}
                  </Typography>
                </Avatar>
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          backgroundColor: "red",
        }}
      >
        <DrawerHeader>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "6px",
              marginTop: "12px",
            }}
          >
            <img style={{ width: 26, height: 26 }} src={logo} alt="icon" />
            <img
              style={{ width: 90, height: 20, marginTop: 0 }}
              src={logo_Text}
              alt="icon"    
            />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon
                  sx={{ color: isDarkMode ? "#FFF" : "inherit" }}
                />
              ) : (
                <ChevronLeftIcon
                  sx={{ color: isDarkMode ? "#FFF" : "inherit" }}
                />
              )}
            </IconButton>
          </Box>
          <Box>
            <Typography
              sx={{
                height: "20px",
                fontSize: "10px",
                marginTop: "-12px",
                marginBottom: "5px",
                marginLeft: "110px",
                fontWeight: "bold",
                color: "#0998FA",
              }}
            >
              {process.env.REACT_APP_LOGO}
            </Typography>
          </Box>
        </DrawerHeader>
        <Divider
          sx={{ marginTop: "-1px" }}
          color={isDarkMode ? "#FFF" : "#E6E6E6"}
        />
        <List sx={{ padding: 1 }}>
          {/* Dashboard */}
          <ListItem key="Dashboard" disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                height: 20,
                justifyContent: open ? "initial" : "center",
                my: 1,
                pt: 3,
                pb: 3,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                  borderRadius: "8px",
                },
              }}
              onClick={handleDashboard}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
                onClick={handleDashboard}
              >
                <HomeOutlinedIcon
                  sx={{ color: isDarkMode ? "#FFF" : "inherit" }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          {/* Jobs */}
          <ListItem key="Jobs" disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={handleJobs}
              sx={{
                height: 20,
                justifyContent: open ? "initial" : "center",
                my: 1,
                pt: 3,
                pb: 3,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                  borderRadius: "8px",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <WorkOutlineIcon
                  sx={{ color: isDarkMode ? "#FFF" : "inherit" }}
                  onClick={handleJobs}
                />
              </ListItemIcon>
              <ListItemText primary="Jobs" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          {/* Documentation */}
          <ListItem
            key="Help"
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                height: 20,
                justifyContent: open ? "initial" : "center",
                my: 1,
                pt: 3,
                pb: 3,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                  borderRadius: "8px",
                },
              }}
              onClick={handleDocumentation}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
                onClick={handleDocumentation}
              >
                <DescriptionOutlinedIcon
                  sx={{ color: isDarkMode ? "#FFF" : "inherit" }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Help"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>

        <Box sx={{ marginTop: "auto" }}>
          <Divider color={isDarkMode ? "#FFF" : "#E6E6E6"} />
          <List sx={{ padding: 1 }}>
            {/* Invite Teammate */}
            <ListItem
              key="inviteteamate"
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  height: 20,
                  justifyContent: open ? "initial" : "center",
                  my: 0.5,
                  pt: 3,
                  pb: 3,
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                    borderRadius: "8px",
                  },
                }}
                onClick={() => setIsModalOpen(true)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PersonAddIcon
                    sx={{ color: isDarkMode ? "#FFF" : "inherit" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Invite Teammate"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>

            {/* Settings */}
            <ListItem key="Settings" disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  height: 20,
                  justifyContent: open ? "initial" : "center",
                  my: 1,
                  pt: 3,
                  pb: 3,
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                    borderRadius: "8px",
                  },
                }}
                onClick={handleSetting}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SettingsOutlinedIcon
                    sx={{ color: isDarkMode ? "#FFF" : "inherit" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Settings"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>

            {/* Log Out */}
            <ListItem key="Log Out" disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  height: 20,
                  justifyContent: open ? "initial" : "center",
                  my: 1,
                  pt: 3,
                  pb: 3,
                  "&:hover": {
                    backgroundColor: "rgba(255, 36, 0, 0.08)",
                    borderRadius: "8px",
                  },
                }}
                onClick={handleLogout}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <LogoutOutlinedIcon
                    sx={{ color: isDarkMode ? "#FF2400" : "#FF0000" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Log Out"
                  sx={{
                    opacity: open ? 1 : 0,
                    color: "#FF2400",
                    fontWeight: "800",
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        sx={{
          backgroundColor: isDarkMode ? "#080808" : "#EEF3F7",
          position: "fixed",
          bottom: 0,
          width: "100%",
          padding: 2,

          textAlign: "center",
          height: 50,
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: isDarkMode ? "#FFF" : "#142764" }}
        >
          &copy; copyright {new Date().getFullYear()}: Udyat Technologies
        </Typography>
      </Box>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Invite a Teammate</DialogTitle>
        <form onSubmit={handleInvite}>
          <DialogContent>
            <DialogContentText>
              Welcome! We're excited to have you invite a new teammate.
            </DialogContentText>
            <DialogContentText style={{ marginBottom: "20px" }}>
              Send an invitation to join your team!
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="User Name"
              type="text"
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => validateEmail(e.target.value)}
              error={!!emailError}
              helperText={emailError}
              required
              style={{ marginBottom: "16px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography style={{ marginLeft: "8px" }}>
                      @
                      {emailAttribute
                        ? emailAttribute.split("@")[1]
                        : "udyattech.com"}
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <DialogActions style={{ marginTop: "5px" }}>
            <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!!emailError}
            >
              Invite
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}