import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import AWS from "aws-sdk";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import { useState, useEffect } from "react";

import {
  Button,
  Container,
  Grid,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Chip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
// import animated from "../assets/animated_logo.gif"
import { CognitoAccessToken } from "amazon-cognito-identity-js";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers";
import NavBar from "./Navbar/Navbar";

export default function EditJob() {
  const [myDefaultCity, setMydefaultCity] = useState("");
  const [myDefaultState, setMyDefaultState] = useState("");
  const [targetHiringDate, setTargetHiringDate] = useState("");
  const [userSelectState, setUserSelectState] = useState("");
  const [disableJobSite, setDisableJobSite] = useState("");
  const [nameAttribute, setNameAttribute] = useState(null);
  const [LastnameAttribute, setLastNameAttribute] = useState(null);

  const [iconClicked, setIconClicked] = React.useState(false);
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const drawerWidth = 200;
  const backgroundDrawer = isDarkMode ? "black" : "#FFF";
  const colorDrawer = isDarkMode ? "#FFF" : "black";
  const [editMode, setEditMode] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [isEditingWebsite, setIsEditingWebsite] = React.useState(false);
  const [jobData, setJobData] = React.useState("");
  const [formData, setFormData] = React.useState("");

  const [screeningQuestionCity, setScreeningQuestionCity] = React.useState("");
  const [screeningQuestionState, setScreeningQuestionState] =
    React.useState("");
    const accessToken = localStorage.getItem("accessToken");
    const [emailAttribute, setEmailAttribute] = useState(null);  
    useEffect(() => {
      const getUserAttributes = async () => {
        const cognitoIdentityServiceProvider =
          new AWS.CognitoIdentityServiceProvider();
  
        try {
          // Get the authenticated user
          const user = await cognitoIdentityServiceProvider
            .getUser({ AccessToken: accessToken })
            .promise();
          console.log("..user..", user);
          // Extract user attributes
          const attributes = user.UserAttributes;
  
          // Log user attributes, you can filter and extract the ones you need
          // console.log('User attributes:', attributes);
  
          // Example: Get email and custom profile picture attribute
          const emailAttribute = attributes.find((attr) => attr.Name === "email");
          const profilePictureAttribute = attributes.find(
            (attr) => attr.Name === "picture"
          );
          const nameAttribute = attributes.find(
            (attr) => attr.Name === "given_name"
          );
          const LastNameAttribute = attributes.find(
            (attr) => attr.Name == "family_name"
          );
          // console.log("..Name..",nameAttribute?.Value)
          // console.log('Email:', emailAttribute?.Value);
          // console.log('Profile Picture:', profilePictureAttribute?.Value);
          setProfilePictureAttribute(profilePictureAttribute?.Value);
  
          setEmailAttribute(emailAttribute?.Value);
          setNameAttribute(nameAttribute?.Value);
          setLastNameAttribute(LastNameAttribute?.Value);
        } catch (error) {
          if (
            error.code === "NotAuthorizedException" &&
            error.message === "Invalid Access Token"
          ) {
            // If the token is expired, attempt to refresh it
            // Implement your token refresh logic here
            console.error("Access token expired. Implement token refresh logic.");
          } else {
            console.error("Error getting user attributes:", error);
          }
        }
      };
      getUserAttributes();
    }, []);
  const [changePassword, setChangePassword] = React.useState(false);
  const [passwordFields, setPasswordFields] = React.useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const jobId = location.state && location.state?.job_id;
  const [profilePictureAttribute, setProfilePictureAttribute] = useState(null);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "16px",
  };

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const handleClickAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [theme, setTheme] = React.useState(false);

  const handleClick = () => {
    setIconClicked((prevState) => !prevState);
  };
  const handleWebsiteEditClick = () => {
    setIsEditingWebsite(true);
  };

  const handleWebsiteSaveClick = () => {
    setIsEditingWebsite(false);
  };

  const handleWebsiteCancelClick = () => {
    setIsEditingWebsite(false);
  };
  const handlePasswordCancelClick = () => {
    setChangePassword(false);

    setPasswordFields({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const handlePasswordChangeClick = () => {
    setChangePassword(true);
  };

  const handlePasswordSaveClick = () => {
    setPasswordFields({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setChangePassword(false);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handlePasswordInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };
  const dayAbbreviationToFullName = (abbr) => {
    const days = {
      Mo: "Monday",
      Tu: "Tuesday",
      We: "Wednesday",
      Th: "Thursday",
      Fr: "Friday",
      Sa: "Saturday",
      Su: "Sunday",
    };
    return days[abbr];
  };
  const CallScheduleDetails = ({ formData, setFormData, editMode }) => {
    const handleWeekRangeChange = (day) => {
      const currentWeekRange = formData.week_range
        ? formData.week_range.split(",").map((d) => d.trim())
        : [];
      const updatedWeekRange = currentWeekRange.includes(day)
        ? currentWeekRange.filter((d) => d !== day)
        : [...currentWeekRange, day];
      setFormData({ ...formData, week_range: updatedWeekRange.join(",") });
    };

    const handleTimeChange = (type, time) => {
      if (time) {
        const timeString = time.format("HH:mm");
        setFormData((prevData) => ({
          ...prevData,
          [type]: timeString,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [type]: null,
        }));
      }
    };

    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    // Convert the week_range string to an array
    const selectedDays = formData.week_range
      ? formData.week_range.split(",")
      : [];

    return (
      <>
        {editMode && (
          <>
            <Grid item xs={8} mt={2}>
              <Typography variant="subtitle1" sx={{ color: "#142764", mb: 1 }}>
                Select Days
              </Typography>
              <Grid container spacing={1}>
                {days.map((day) => (
                  <Grid item key={day}>
                    <Chip
                      label={day.substring(0, 2)}
                      clickable
                      color={selectedDays.includes(day) ? "primary" : "default"}
                      onClick={() => handleWeekRangeChange(day)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid container spacing={8}>
              <Grid item xs={6} marginTop={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Start Time"
                    value={
                      formData.perday_start_time
                        ? dayjs(formData.perday_start_time, "HH:mm")
                        : null
                    }
                    onChange={(time) =>
                      handleTimeChange("perday_start_time", time)
                    }
                    format="HH:mm"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} marginTop={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="End Time"
                    value={
                      formData.perday_end_time
                        ? dayjs(formData.perday_end_time, "HH:mm")
                        : null
                    }
                    onChange={(time) =>
                      handleTimeChange("perday_end_time", time)
                    }
                    format="HH:mm"
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  };

  const token = localStorage.getItem("idToken");
  const [skipHoliday, setSkipHoliday] = useState("");
  const [screeningCall, setScreeningCall] = useState("");
  const [technicalCall, setTechnicalCall] = useState("");
  const [codingAssessment, setCodingAssessment] = useState("");
  const [aptitudeAssessment, setAptitudeAssessment] = useState("");

  let storedJobId;
  React.useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        storedJobId = localStorage.getItem("Edit-jobId");
        const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
        const apiUrl = `${baseUrl}/job/show_job_detail`;
        const response = await axios.post(
          apiUrl,
          {
            job_id: storedJobId, // Assuming you're always fetching job with ID "26240630"
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        // Handle the API response data here
        const responseData = response.data;
        setJobData(response);
        setMydefaultCity(responseData?.job_city);
        setMyDefaultState(responseData?.job_state);
        setTargetHiringDate(responseData?.target_hiring_date);
        setValue(dayjs(responseData?.target_hiring_date));
        setStartDate(dayjs(responseData?.start_date));
        setScreeningCall(responseData?.screening_call);
        setSkipHoliday(responseData?.skip_holidays);
        setTechnicalCall(responseData?.technical_call);
        setCodingAssessment(responseData?.coding_assessment);
        setAptitudeAssessment(responseData?.aptitude_assessment_check);
        setScreeningQuestionCity(responseData?.job_city);
        setScreeningQuestionState(responseData?.job_state);
        setDisableJobSite(responseData?.job_site);
        console.log("job details", responseData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchJobDetails();
  }, [jobId, storedJobId]);
  console.log("technical check", formData.technical_call);
  console.log("screening check", formData.screening_call);
  React.useEffect(() => {
    if (jobData) {
      setFormData({
        job_title: jobData.data.job_title,
        job_type: jobData.data.job_type,
        job_category: jobData.data.job_category,
        job_site: jobData.data.job_site,
        vacancy: jobData.data.number_of_vacancies,
        minimum_experience: jobData.data.min_experience, // Remove parseInt
        maximum_experience: jobData.data.max_experience, // Remove parseInt
        minimimum_salary: parseInt(jobData.data.min_salary, 10),
        maximum_salary: parseInt(jobData.data.max_salary, 10),
        location: jobData.data.address,
        job_city: jobData.data.job_city,
        country: jobData.data.country,
        job_description: jobData.data.job_description,
        key_responsiblities: jobData.data.key_responsiblities,
        skills: jobData.data.skills,
        screening_questions: jobData.data.screening_questions,
        normal_questions: jobData.data.normal_questions,
        qualification: jobData.data.qualification,
        skip_holidays: jobData.data.skip_holidays,
        screening_call: jobData.data.screening_call,
        start_date: jobData.data.start_date,
        target_hiring_date: jobData.data.target_hiring_date,
        technical_call: jobData.data.technical_call,
        coding_assessment: jobData.data.coding_assessment,
        aptitude_assessment: jobData.data.aptitude_assessment_check,
        perday_start_time: jobData.data.perday_start_time || null,
        perday_end_time: jobData.data.perday_end_time || null,
        week_range: jobData.data.week_range,
      });
    }
  }, [jobData]);

  // console.log("...", jobData.data)
  const handleEditClick = () => {
    setEditMode(true);
  };
  const handleCancelClick = () => {
    setEditMode(false);
  };
  const client_data = localStorage.getItem("client_id");
  const handleSaveClick = () => {
    setEditMode(false);

    const minExperience = formData.minimum_experience;
    const maxExperience = formData.maximum_experience;
    const minSalary = parseInt(formData.minimimum_salary, 10);
    const maxSalary = parseInt(formData.maximum_salary, 10);

    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;

    const data = {
      job_id: jobData.data.job_id,
      client_id: client_data,
      job_title: formData.job_title,
      address: formData.location,
      job_city: formData.job_city,
      job_state: formData.job_state,
      country: formData.country,
      job_description: formData.job_description,
      key_responsiblities: formData.key_responsiblities,
      deal_breaker_questions: "Not Available",
      min_experience: minExperience,
      max_experience: maxExperience,
      salary_range: `${minSalary}-${maxSalary}`,
      qualification: formData.qualification,
      number_of_vacancies: formData.vacancy,
      skills: formData.skills,
      screening_questions: JSON.stringify(formData.screening_questions),
      qualification: formData.qualification,
      skip_holidays: formData.skip_holidays,
      screening_call: formData.screening_call,
      technical_call: formData.technical_call,
      coding_assessment_check: formData.coding_assessment,
      aptitude_assessment_check: formData.aptitude_assessment,
    };

    // Add call schedule fields if screening_call is "1"
    if (formData.screening_call === "1" || formData.technical_call === "1") {
      data.start_date = dayjs(formData.start_date).format("YYYY-MM-DD");
      data.target_hiring_date = dayjs(formData.target_hiring_date).format(
        "YYYY-MM-DD"
      );
      
      // Convert week_range to an array and then stringify it
      data.week_range = JSON.stringify(
        Array.isArray(formData.week_range) 
          ? formData.week_range 
          : formData.week_range.split(',').map(day => day.trim())
      );

      // Format perday_start_time and perday_end_time
      if (formData.perday_start_time) {
        data.perday_start_time = dayjs(
          formData.perday_start_time,
          "HH:mm"
        ).format("HH:mm");
      }
      if (formData.perday_end_time) {
        data.perday_end_time = dayjs(formData.perday_end_time, "HH:mm").format(
          "HH:mm"
        );
      }
    }

    axios
      .post(`${baseUrl}/job/update_job`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        navigate("/jobs");
      })
      .catch((error) => {
        console.error("Error updating job:", error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const newData = { ...prevData, [name]: value };

      // If screening_call is set to false, also set technical_call to false
      if (name === "screening_call" && value === "0") {
        newData.technical_call = "0";
      }

      return newData;
    });
  };

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const refreshToken = localStorage.getItem("refreshToken");
  const handleAccessToken = () => {
    const accessToken = localStorage.getItem("accessToken");

    const navigateAndReturn = (path) => {
      navigate(path);
      return false;
    };

    if (!accessToken || !checkAccessTokenValidity(accessToken)) {
      // console.log("...to redirect on login")
      localStorage.setItem("logedIn", false);
      return navigateAndReturn("/");
    } else {
      // console.log("...to redirect on dashboard")
      // refreshtokenentry()
      checkScreenActivity();
      return navigateAndReturn("/edit-job");
    }
    // console.log("...dashboard return ...");
  };

  const checkAccessTokenValidity = (accessToken) => {
    try {
      const decodedToken = new CognitoAccessToken({ AccessToken: accessToken });

      const expirationTime = decodedToken.getExpiration();

      const currentTime = Math.floor(Date.now() / 1000);

      if (expirationTime > currentTime) {
        // console.log("Expiration time is greater than current time. Triggering useEffect logic.");
        return expirationTime > currentTime;
      }
    } catch (error) {
      console.error("Error decoding token or missing expiration time:", error);
      return false;
    }
  };

  const refreshtokenentry = () => {
    // console.log("'...token checking...")
    const region = process.env.REACT_APP_AWS_REGION;
    const apiUrl = `https://cognito-idp.${region}.amazonaws.com/`;
    const requestBody = {
      AuthFlow: "REFRESH_TOKEN",
      ClientId: process.env.REACT_APP_CLIENT_ID_USER,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    };
    const headers = {
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      "Content-Type": "application/x-amz-json-1.1",
    };
    axios
      .post(apiUrl, requestBody, { headers })
      .then((response) => {})
      .catch((error) => {});
  };

  const checkScreenActivity = () => {
    let isActive = false;

    const handleMove = () => {
      isActive = true;
    };

    window.addEventListener("mousemove", handleMove);

    setTimeout(() => {
      window.removeEventListener("mousemove", handleMove);

      if (isActive) {
        refreshtokenentry();
      } else {
      }
    }, 3000); // Adjust the timeout duration as needed (3000 milliseconds = 3 seconds)
  };

  React.useEffect(() => {
    handleAccessToken();
    const timeoutId = setTimeout(() => {
      // console.log("...trigger after 2 min")
      handleAccessToken();
    }, 55 * 60 * 1000);

    const foraccessToken = setTimeout(() => {
      // console.log("...trigger after 5 min")
      // toast.error("Session expired");
      handleAccessToken();
    }, 60 * 60 * 1000);
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(foraccessToken);
    };
  }, []);

  // city state country
  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const countries = await GetCountries();
        const india = countries.find((country) => country.name === "India");

        const defaultState =
          userSelectState.trim() === ""
            ? myDefaultState.trim()
            : userSelectState.trim().toString();
        // state
        const countryIdDemo = india.id;
        const statesDemo = await GetState(countryIdDemo);
        setStateList(statesDemo);

        const state = statesDemo.find((item) => item.name === defaultState);
        if (state) {
          setStateId(state.id);

          // Fetch cities based on the selected state
          const cities = await GetCity(countryIdDemo, state.id);
          setCityList(cities);

          // If myDefaultCity is not in the list of cities for the selected state, reset it
          const defaultCity = myDefaultCity.trim();
          const myCity = cities.find((item) => item.name === defaultCity);
          if (myCity) {
            setCityId(myCity.id);
          } else {
            setCityId(""); // Reset cityId if the default city is not in the list
          }
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    }
    fetchData();
  }, [myDefaultState, userSelectState]); // Include userSelectState in the dependency array

  const [value, setValue] = React.useState();
  const [startDate, setStartDate] = React.useState();

  return (
    <Box
      sx={{
        maxHeight: "calc(100vh - 40px)",
        overflowY: "auto",
        width: "auto",
        height: "100vh",
        paddingLeft: 6,
        backgroundColor: isDarkMode ? "black" : "#F9F9F9",
      }}
    >
      <CssBaseline />
      <NavBar
        pageTitle="Job Details"
        showNameWithTitle={false}
        nameAttribute={nameAttribute}
        LastnameAttribute={LastnameAttribute}
        profilePictureAttribute={profilePictureAttribute}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        open={open} // Pass the open state
        setOpen={setOpen} // Pass the setOpen function
      />
      <Box
        component="main"
        sx={{
          height: "100%",
          flexGrow: 1,
          pt: 3,
          pl: open ? 20 : 5,
          pr: 3,
          backgroundColor: isDarkMode ? "black" : "#F9F9F9",
        }}
      >
        <Box sx={{ mt: 4 }}>
          <Container
            maxWidth="md"
            sx={{ paddingBottom: "20px", paddingTop: "20px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                color={isDarkMode ? "#FFF" : "#142764"}
                sx={{ fontWeight: "500" }}
              >
                Edit Job
              </Typography>
              {!editMode && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={handleEditClick}
                >
                  Edit
                </Button>
              )}
            </Box>

            <Grid container spacing={3}>
              {Object.keys(formData)
                .filter(
                  (key) =>
                    ![
                      "week_range",
                      "perday_start_time",
                      "perday_end_time",
                      ...(formData.screening_call === "0" &&
                      formData.technical_call === "0"
                        ? ["start_date", "target_hiring_date"]
                        : []),
                    ].includes(key)
                )
                .map((key) => (
                  <Grid item xs={12} key={key}>
                    {key === "qualification" && editMode ? (
                      // The qualification selection is handled using a Select component
                      <FormControl fullWidth>
                        <InputLabel id="qualification-label">
                          Qualification
                        </InputLabel>
                        <Select
                          labelId="qualification-label"
                          id="qualification"
                          value={formData[key]} // Use the formData state value
                          onChange={(event) => {
                            const value = event.target.value;
                            // console.log("Selected qualification:", value);
                            // Update formData state when selection changes
                            setFormData((prevData) => ({
                              ...prevData,
                              qualification: value,
                            }));
                          }}
                          label="Qualification"
                        >
                          <MenuItem value="Bachelor's Degree">
                            Bachelor's Degree
                          </MenuItem>
                          <MenuItem value="Masters Degree">
                            Masters Degree
                          </MenuItem>
                          <MenuItem value="Post Masters Degree">
                            Post Masters Degree
                          </MenuItem>
                          <MenuItem value="PHD">PHD</MenuItem>
                          <MenuItem value="Non-Bachelor Degree">
                            Non-Bachelor Degree
                          </MenuItem>
                        </Select>
                      </FormControl>
                    ) : key === "skip_holidays" && editMode ? (
                      <FormControl fullWidth>
                        <InputLabel id="skip-holidays-label">
                          Skip Holidays
                        </InputLabel>
                        <Select
                          labelId="skip-holidays-label"
                          id="skip-holidays"
                          value={formData[key]}
                          onChange={(event) => {
                            const value = event.target.value;
                            // Update formData state when selection changes
                            setFormData((prevData) => ({
                              ...prevData,
                              [key]: value,
                            }));
                          }}
                          label="Skip Holidays"
                        >
                          <MenuItem value={"1"}>Yes</MenuItem>
                          <MenuItem value={"0"}>No</MenuItem>
                        </Select>
                        {/* <Typography variant="body1">
                           Selected Value: {formData.skip_holidays ?  `${typeof(formData.skip_holidays)}`: `${formData.skip_holidays}`}
                           </Typography> */}
                      </FormControl>
                    ) : key === "skip_holidays" && !editMode ? (
                      <TextField
                        style={{ width: "100%" }}
                        label="Skip Holidays"
                        value={skipHoliday === "1" ? "Yes" : "No"}
                        InputProps={{ readOnly: true }}
                        disabled={!editMode || ["skip_holidays"].includes(key)}
                      />
                    ) : key === "technical_call" && editMode ? (
                      <>
                        <FormControl fullWidth>
                          <InputLabel id="tech-call-label">
                            Technical Call
                          </InputLabel>
                          <Select
                            labelId="tech-call-label"
                            id="tech-call"
                            value={formData[key]}
                            onChange={(event) => {
                              const value = event.target.value;
                              setFormData((prevData) => ({
                                ...prevData,
                                [key]: value,
                              }));
                            }}
                            label="Technical Call"
                            disabled={!editMode}
                          >
                            <MenuItem value={"1"}>True</MenuItem>
                            <MenuItem value={"0"}>False</MenuItem>
                          </Select>
                        </FormControl>
                        {formData[key] === "1" && (
                          <CallScheduleDetails
                            formData={formData}
                            setFormData={setFormData}
                            editMode={editMode}
                            isTechnicalCall={true}
                          />
                        )}
                      </>
                    ) : key === "technical_call" && !editMode ? (
                      <TextField
                        style={{ width: "100%" }}
                        label="Technical Call"
                        value={technicalCall === "1" ? "True" : "False"}
                        InputProps={{ readOnly: true }}
                        disabled={!editMode || ["technical_call"].includes(key)}
                      />
                    ) : key === "screening_call" ? (
                      <>
                        <FormControl fullWidth>
                          <InputLabel id="scr-call-label">
                            Screening Call
                          </InputLabel>
                          <Select
                            labelId="scr-call-label"
                            id="scr-call"
                            value={formData[key] || "1"} // Set default value to "1" (True) if undefined
                            onChange={(event) => {
                              const value = event.target.value;
                              setFormData((prevData) => ({
                                ...prevData,
                                screening_call: value,
                              }));
                            }}
                            label="Screening Call"
                            disabled={!editMode || formData[key] === "1"} // Disable when default is "1"
                          >
                            <MenuItem value={"1"}>True</MenuItem>
                            <MenuItem value={"0"}>False</MenuItem>
                          </Select>
                        </FormControl>

                        {formData[key] === "1" &&
                          formData.technical_call !== "1" && (
                            <CallScheduleDetails
                              formData={formData}
                              setFormData={setFormData}
                              editMode={editMode}
                              isTechnicalCall={false}
                            />
                          )}
                      </>
                    ) : key === "coding_assessment" && editMode ? (
                      <FormControl fullWidth>
                        <InputLabel id="coding-assessment-label">
                          Coding Assessment
                        </InputLabel>
                        <Select
                          labelId="coding-assessment-label"
                          id="code-assessment"
                          // defaultValue={formData[key]}
                          value={formData[key]}
                          onChange={(event) => {
                            const value = event.target.value;
                            // Update formData state when selection changes
                            setFormData((prevData) => ({
                              ...prevData,
                              [key]: value,
                            }));
                          }}
                          label="Coding Assessment"
                        >
                          <MenuItem value={"1"}>True</MenuItem>
                          <MenuItem value={"None" || "0"}>False</MenuItem>
                        </Select>
                        {/* <Typography variant="body1">
                                     Selected Value: {formData.skip_holidays ?  `${typeof(formData.skip_holidays)}`: `${formData.skip_holidays}`}
                                     </Typography> */}
                      </FormControl>
                    ) : key === "coding_assessment" && !editMode ? (
                      <TextField
                        style={{ width: "100%" }}
                        label="Coding Assessment"
                        value={codingAssessment === "1" ? "True" : "False"}
                        InputProps={{ readOnly: true }}
                        disabled={
                          !editMode || ["coding_assessment"].includes(key)
                        }
                      />
                    ) : key === "aptitude_assessment" && editMode ? (
                      <FormControl fullWidth>
                        <InputLabel id="coding-assessment-label">
                          Aptitude Assessment
                        </InputLabel>
                        <Select
                          labelId="aptitude-assessment-label"
                          id="aptitude-assessment"
                          // defaultValue={formData[key]}
                          value={formData[key]}
                          onChange={(event) => {
                            const value = event.target.value;
                            // Update formData state when selection changes
                            setFormData((prevData) => ({
                              ...prevData,
                              [key]: value,
                            }));
                          }}
                          label="Aptitude Assessment"
                        >
                          <MenuItem value={"1"}>True</MenuItem>
                          <MenuItem value={"None" || "0"}>False</MenuItem>
                        </Select>
                        {/* <Typography variant="body1">
                                     Selected Value: {formData.skip_holidays ?  `${typeof(formData.skip_holidays)}`: `${formData.skip_holidays}`}
                                     </Typography> */}
                      </FormControl>
                    ) : key === "aptitude_assessment" && !editMode ? (
                      <TextField
                        style={{ width: "100%" }}
                        label="Aptitude Assessment"
                        value={aptitudeAssessment === "1" ? "True" : "False"}
                        InputProps={{ readOnly: true }}
                        disabled={
                          !editMode || ["aptitude_assessment"].includes(key)
                        }
                      />
                    ) : key === "country" && editMode ? (
                      <FormControl fullWidth>
                        <InputLabel id="country-label">Country</InputLabel>
                        <Select
                          style={{ width: "100%", marginTop: "1%" }}
                          onChange={(e) => {
                            const selectedCountryId = parseInt(e.target.value);
                            setCountryId(selectedCountryId);
                            setFormData((prevData) => ({
                              ...prevData,
                              [key]: "India",
                            }));
                            setStateId(0);
                            setCityId(0);
                          }}
                          value={countryId}
                          label="Country"
                        >
                          <MenuItem value={countryId}>India</MenuItem>
                        </Select>
                      </FormControl>
                    ) : key === "job_city" && editMode ? (
                      <FormControl fullWidth>
                        <InputLabel id="city-label">Job City</InputLabel>
                        <Select
                          labelId="city-label"
                          id="city"
                          onChange={(e) => {
                            const selectedCityId = parseInt(e.target.value);
                            setCityId(selectedCityId);

                            // Find the selected city object from cityList
                            const selectedCity = cityList.find(
                              (city) => city.id === selectedCityId
                            );
                            // If the selected city is found, update formData with its name
                            if (selectedCity) {
                              setFormData((prevData) => ({
                                ...prevData,
                                [key]: selectedCity.name,
                              }));
                            }
                          }}
                          value={cityId}
                          label="Job City"
                          disabled={editMode && disableJobSite === "Remote"}
                        >
                          {cityList.map((city) => (
                            <MenuItem key={city.id} value={city.id}>
                              {city.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : key === "target_hiring_date" ? (
                      editMode &&
                      (formData.technical_call === "1" ||
                        formData.screening_call === "1") ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              label="Call End date"
                              value={value}
                              sx={{ width: "100%" }}
                              onChange={(newValue) => {
                                const date = dayjs(newValue)
                                  .format("YYYY-MM-DD")
                                  .toString();
                                setValue(newValue);
                                setFormData((prevData) => ({
                                  ...prevData,
                                  [key]: date,
                                }));
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      ) : (
                        <TextField
                          fullWidth
                          label="Call End Date"
                          value={formData[key]}
                          InputProps={{ readOnly: true }}
                          disabled={true}
                        />
                      )
                    ) : key === "start_date" ? (
                      editMode &&
                      (formData.technical_call === "1" ||
                        formData.screening_call === "1") ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              label="Call Start Date"
                              value={startDate}
                              sx={{ width: "100%" }}
                              onChange={(newValue) => {
                                const date = dayjs(newValue)
                                  .format("YYYY-MM-DD")
                                  .toString();
                                setStartDate(newValue);
                                setFormData((prevData) => ({
                                  ...prevData,
                                  [key]: date,
                                }));
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      ) : (
                        <TextField
                          fullWidth
                          label="Call Start Date"
                          value={formData[key]}
                          InputProps={{ readOnly: true }}
                          disabled={true}
                        />
                      )
                    ) : key === "job_state" && editMode ? (
                      <FormControl fullWidth>
                        <InputLabel id="state-label">Job State</InputLabel>
                        <Select
                          labelId="state-label"
                          id="state"
                          onChange={(e) => {
                            const selectedCityId = parseInt(e.target.value);
                            setStateId(selectedCityId);

                            // Find the selected city object from cityList
                            const selectedCity = stateList.find(
                              (city) => city.id === selectedCityId
                            );
                            setUserSelectState(selectedCity.name);
                            if (selectedCity) {
                              setFormData((prevData) => ({
                                ...prevData,
                                [key]: selectedCity.name,
                              }));
                            }
                          }}
                          value={stateId}
                          label="Job State"
                          disabled={editMode && disableJobSite === "Remote"}
                        >
                          {stateList.map((city) => (
                            <MenuItem key={city.id} value={city.id}>
                              {city.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : key === "location" && editMode ? (
                      <TextField
                        style={{ width: "100%" }}
                        label="Address"
                        value={formData[key]}
                        onChange={(event) => {
                          const value = event.target.value;
                          setFormData((prevData) => ({
                            ...prevData,
                            location: value,
                          }));
                        }}
                      />
                    ) : key === "location" && !editMode ? (
                      <TextField
                        style={{ width: "100%" }}
                        label="Address"
                        value={formData[key]}
                        InputProps={{ readOnly: true }}
                        disabled={!editMode || ["location"].includes(key)}
                      />
                    ) : key === "job_site" && editMode ? (
                      <TextField
                        style={{ width: "100%" }}
                        label="Job Site"
                        value={formData[key]}
                        InputProps={{ readOnly: true }}
                        disabled={!editMode || ["job_site"].includes(key)}
                      />
                    ) : key === "screening_questions" && !editMode ? (
                      formData[key] instanceof Array &&
                      formData[key].map((item, index) => (
                        <Box style={{ marginTop: "1%" }}>
                          <TextField
                            key={index} // Key should be unique for each TextField
                            style={{
                              width: "102%",
                              padding: "1%",
                              marginLeft: " -6px",
                            }}
                            label={` Screening Question ${index + 1}`}
                            multiline
                            disabled={
                              !editMode || ["screening_questions"].includes(key)
                            }
                            value={item}
                          />
                        </Box>
                      ))
                    ) : key === "screening_questions" && editMode ? (
                      formData[key] instanceof Array &&
                      formData[key].map((item, index) => (
                        <Box key={index} style={{ marginTop: "1%" }}>
                          <TextField
                            style={{
                              width: "102%",
                              padding: "1%",
                              marginLeft: "-6px",
                            }}
                            label={`Screening Question ${index + 1}`}
                            multiline
                            value={item}
                            helperText={
                              item === "What is your current location?" ||
                              item ===
                                `Are you open to relocate or open to work in  ${screeningQuestionCity.trim()}, ${screeningQuestionState.trim()}` ||
                              item ===
                                "Are you open to working in different shifts?" ||
                              item ===
                                "What is your expected monthly salary?" ||
                              item === "What is your current monthly salary?" ||
                              item === "What is your notice period?" ||
                              item === "When can you start working?" ||
                              item === "What is your expected hourly price?"
                                ? ""
                                : `${item.length}/100`
                            }
                            disabled={
                              item ===
                                `Are you open to relocate or open to work in  ${screeningQuestionCity.trim()}, ${screeningQuestionState.trim()}` ||
                              item === "What is your current location?" ||
                              item ===
                                "Are you open to working in different shifts?" ||
                              item ===
                                "What is your expected monthly salary?" ||
                              item === "What is your current monthly salary?" ||
                              item === "What is your notice period?" ||
                              item === "When can you start working?" ||
                              item === "What is your expected hourly price? "
                            }
                            onChange={(event) => {
                              const updatedValue = event.target.value;
                              setFormData((prevData) => {
                                const newData = { ...prevData };
                                newData[key][index] = updatedValue;
                                return newData;
                              });
                            }}
                          />
                        </Box>
                      ))
                    ) : key === "normal_questions" && !editMode ? (
                      formData[key] instanceof Array &&
                      formData[key].map((item, index) => (
                        <Box key={index} style={{ marginTop: "1%" }}>
                          <TextField
                            style={{
                              width: "102%",
                              padding: "1%",
                              marginLeft: "-6px",
                            }}
                            label={` Technical Question ${index + 1}`}
                            multiline
                            value={item}
                            disabled={item}
                            // onChange={(event) => {
                            //   const updatedValue = event.target.value;
                            //   setFormData((prevData) => {
                            //     const newData = { ...prevData };
                            //     newData[key][index] = updatedValue;
                            //     return newData;
                            //   });
                            // }}
                          />
                        </Box>
                      ))
                    ) : key === "normal_questions" && editMode ? (
                      formData[key] instanceof Array &&
                      formData[key].map((item, index) => (
                        <Box key={index} style={{ marginTop: "1%" }}>
                          <TextField
                            style={{
                              width: "102%",
                              padding: "1%",
                              marginLeft: "-6px",
                            }}
                            label={` Technical Question ${index + 1}`}
                            multiline
                            value={item}
                            disabled={item}

                            // onChange={(event) => {
                            //   const updatedValue = event.target.value;
                            //   setFormData((prevData) => {
                            //     const newData = { ...prevData };
                            //     newData[key][index] = updatedValue;
                            //     return newData;
                            //   });
                            // }}
                          />
                        </Box>
                      ))
                    ) : (
                      // Display the TextField for other form data
                      <TextField
  fullWidth
  multiline={
    (key !== "minimum_experience" &&
      key !== "minimimum_salary" &&
      key !== "maximum_salary" &&
      key !== "vacancy" &&
      key !== "maximum_experience") ||
    key == "screening_questions"
  }
  label={
    !editMode && key === "start_date"
      ? "Call Start Date"
      : !editMode && key === "target_hiring_date"
      ? "Call End Date"
      : key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
  }
  name={key}
  value={formData[key] ?? ''} // Use nullish coalescing to handle undefined
  onChange={handleInputChange}
  disabled={
    !editMode || ["job_title", "job_category", "job_type"].includes(key)
  }
  variant="outlined"
  InputLabelProps={{
    shrink: true,
  }}
  type={
    key === "minimum_experience" ||
    key === "minimimum_salary" ||
    key === "maximum_salary" ||
    key === "vacancy" ||
    key === "maximum_experience"
      ? "text" // Change from "number" to "text"
      : "text"
  }
  inputProps={
    key === "minimum_experience" ||
    key === "minimimum_salary" ||
    key === "maximum_salary" ||
    key === "vacancy" ||
    key === "maximum_experience"
      ? { 
          step: "any", // Allow any decimal value
          pattern: "[0-9]*([.][0-9]+)?" // Regex to allow numbers with optional decimal
        }
      : {}
  }
/>
                    )}
                  </Grid>
                ))}
            </Grid>

            {editMode && (
              <Box
                sx={{
                  mt: 4,
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                }}
              >
                <Button color="primary" onClick={handleCancelClick}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveClick}
                >
                  Save
                </Button>
              </Box>
            )}
          </Container>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: isDarkMode ? "#080808" : "#EEF3F7",
          position: "fixed",
          bottom: 0,
          width: "100%",
          padding: 2,

          textAlign: "center",
          height: 50,
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: isDarkMode ? "#FFF" : "#142764" }}
        >
          &copy; copyright {new Date().getFullYear()}: Udyat Technologies
        </Typography>
      </Box>
    </Box>
  );
}
