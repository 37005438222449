import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { createContext } from 'react';
import UserPool from '../UserPool';

const AccountContext = createContext();

const Account = (props) => {

  const getSession = async () => {
    await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        console.log("...account session")
        user.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        });
      } else {
        reject();
      }
    });
  };

  const authenticate = async (Username, Password) => {
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool: UserPool,
      });

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (result) => {
          console.log('login success', result);
          resolve(result);
        },
        onFailure: (err) => {
          console.log('login failure', err);
          reject(err);
        },
        newPasswordRequired: (data) => {
          console.log('new password required', data);
          resolve(data);
        },
      });
    });
  };

  const logout = () => {
    const user = UserPool.getCurrentUser();
    console.log("...user...",user)
    user.signOut();
   
    window.location.href = '/';
  };
const login = () =>{
    // user.signIn():
    window.location.href = "/login"
    
}
  return (
    <AccountContext.Provider value={{ authenticate, getSession, logout ,login }}>
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };