import * as React from "react";
import { styled, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import { useLocation, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import LinkIcon from "@mui/icons-material/Link";
import { toast } from "react-toastify";
import axios from "axios";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import AWS from "aws-sdk";
import {
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  InputAdornment,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import logo_Text from "../assets/Coucal_Text.png";
import logo from "../assets/Icon.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import animated from "../assets/animated_logo.gif"
import { CognitoAccessToken } from "amazon-cognito-identity-js";
import { ArrowForward, CheckCircleOutline } from "@mui/icons-material";
import NavBar from "./Navbar/Navbar";
import { useEffect } from "react";
import { useState } from "react";

export default function Documentation() {
  const [iconClicked, setIconClicked] = React.useState(false);
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const drawerWidth = 200;
  const backgroundDrawer = isDarkMode ? "black" : "#FFF";
  const colorDrawer = isDarkMode ? "#FFF" : "black";
  const [editMode, setEditMode] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [isEditingWebsite, setIsEditingWebsite] = React.useState(false);
  const [companyData, setCompanyData] = React.useState("");
  const [formData, setFormData] = React.useState("");
  const [emailLocal, setEmailLocal] = React.useState("");
  const [changePassword, setChangePassword] = React.useState(false);
  const [passwordFields, setPasswordFields] = React.useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [profilePictureAttribute, setProfilePictureAttribute] = useState(null);
  const [emailAttribute, setEmailAttribute] = useState(null);
  const accessToken = localStorage.getItem("accessToken");
  const [nameAttribute, setNameAttribute] = useState(null);
  const [LastnameAttribute, setLastNameAttribute] = useState(null);

  useEffect(() => {
    const getUserAttributes = async () => {
      const cognitoIdentityServiceProvider =
        new AWS.CognitoIdentityServiceProvider();

      try {
        // Get the authenticated user
        const user = await cognitoIdentityServiceProvider
          .getUser({ AccessToken: accessToken })
          .promise();
        console.log("..user..", user);
        // Extract user attributes
        const attributes = user.UserAttributes;

        // Log user attributes, you can filter and extract the ones you need
        // console.log('User attributes:', attributes);

        // Example: Get email and custom profile picture attribute
        const emailAttribute = attributes.find((attr) => attr.Name === "email");
        const profilePictureAttribute = attributes.find(
          (attr) => attr.Name === "picture"
        );
        const nameAttribute = attributes.find(
          (attr) => attr.Name === "given_name"
        );
        const LastNameAttribute = attributes.find(
          (attr) => attr.Name == "family_name"
        );
        // console.log("..Name..",nameAttribute?.Value)
        // console.log('Email:', emailAttribute?.Value);
        // console.log('Profile Picture:', profilePictureAttribute?.Value);
        setProfilePictureAttribute(profilePictureAttribute?.Value);

        setEmailAttribute(emailAttribute?.Value);
        setNameAttribute(nameAttribute?.Value);
        setLastNameAttribute(LastNameAttribute?.Value);
      } catch (error) {
        if (
          error.code === "NotAuthorizedException" &&
          error.message === "Invalid Access Token"
        ) {
          // If the token is expired, attempt to refresh it
          // Implement your token refresh logic here
          console.error("Access token expired. Implement token refresh logic.");
        } else {
          console.error("Error getting user attributes:", error);
        }
      }
    };
    getUserAttributes();
  }, []);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    {
      label: "Sign Up and Create a Company Profile",
      intro: "To begin using the application, complete the following steps:",
      points: [
        {
          title: "Sign Up",
          description:
            "Use your company's official email address for sign up. Please note, public email domains such as Gmail or Yahoo are not accepted.",
        },
        {
          title: "Set Up Company Profile",
          description:
            "Once logged in, create your company profile, ensuring that all activities are correctly attributed to your organization.",
        },
      ],
    },
    {
      label: "Create a Job",
      intro:
        "After setting up your profile, you can now create job postings. Follow these steps:",
      points: [
        {
          title: "Job Details",
          description:
            "Fill in all necessary job information like job title, location (Remote, Office or InField), employment type (Part-time, Full-time or Consultant), and a clear salary range specifying both minimum and maximum compensation.",
        },
        {
          title: "Job Title",
          description:
            "Provide a clear job title, e.g., Software Engineer, Full Stack Developer.",
        },
        {
          title: "Job location",
          description:
            "Specify whether the position is office-based, remote, or fieldwork.",
        },
        {
          title: "Job Type",
          description:
            "Indicate if the role is full-time, part-time, or remote.",
        },
        {
          title: "Salary Range",
          description: "Set both minimum and maximum salary ranges.",
        },
        {
          title: "Enable Features",
          description:
            "Enable options such as screening calls, technical interviews, coding assessments or aptitude assessments as needed.",
        },
      ],
    },
    {
      label: "View and Manage Jobs",
      intro: "Once a job is created, you can manage it through the dashboard:",
      points: [
        {
          title: "Dashboard Overview",
          description:
            "Monitor the total number of job listings and track the progress of resumes uploaded and processed.",
        },
        {
          title: "Job Details",
          description:
            "Access detailed information about each job including job role, total uploaded resumes, proccessed resumes and status of job(enable or disable).",
        },
      ],
    },
    {
      label: "Upload Resumes",
      intro:
        "To begin evaluating candidates, upload their resumes by following these steps:",
      points: [
        {
          title: "Upload Files",
          description:
            "Go to the reports section, click 'Upload File,' and submit a ZIP file containing resumes in PDF, DOC, or DOCX formats. Ensure the ZIP file is under 50 MB.",
        },
        {
          title: "Processing",
          description:
            "Within a few seconds, the resumes will be processed and displayed in the table according to their ranking, making it easy to shortlist candidates.",
        },
      ],
    },
    {
      label: "Schedule Interviews",
      intro:
        "With resumes shortlisted, you can proceed to the interview phase:",
      points: [
        {
          title: "Schedule Rounds",
          description:
            "Select the candidates you're interested in and schedule technical rounds, coding assessments or aptitude assessments. Use the checkboxes to organize interviews.",
        },
        {
          title: "Track Interview Progress",
          description:
            "Monitor the status of each interview round (e.g - completed, pending, or scheduled).",
        },
      ],
    },
    {
      label: "View Resume Insights",
      intro: "To gain more insights into a candidate’s profile:",
      points: [
        {
          title: "Resume Insights",
          description:
            "Select any candidate’s resume to view detailed insights and download the resume along with any associated call recordings.",
        },
      ],
    },
    {
      label: "Close or Pause Jobs:",
      intro:
        "Once the recruitment process is complete or if you need to pause a job listing:",
      points: [
        {
          title: "Actions Button",
          description:
            "In the dashboard, use the actions button to view the job details, pause or delete job listings. This feature also allows you to view detailed job information and manage jobs efficiently.",
        },
      ],
    },
  ];

  const navigate = useNavigate();

  const refreshToken = localStorage.getItem("refreshToken");
  const handleAccessToken = () => {
    const accessToken = localStorage.getItem("accessToken");

    const navigateAndReturn = (path) => {
      navigate(path);
      return false;
    };

    if (!accessToken || !checkAccessTokenValidity(accessToken)) {
      // console.log("...to redirect on login")
      localStorage.setItem("logedIn", false);
      return navigateAndReturn("/");
    } else {
      // console.log("...to redirect on dashboard")
      // refreshtokenentry()
      checkScreenActivity();
      return navigateAndReturn("/documentation");
    }
    // console.log("...dashboard return ...");
  };

  const checkAccessTokenValidity = (accessToken) => {
    try {
      const decodedToken = new CognitoAccessToken({ AccessToken: accessToken });

      const expirationTime = decodedToken.getExpiration();

      const currentTime = Math.floor(Date.now() / 1000);

      if (expirationTime > currentTime) {
        // console.log("Expiration time is greater than current time. Triggering useEffect logic.");
        return expirationTime > currentTime;
      }
    } catch (error) {
      console.error("Error decoding token or missing expiration time:", error);
      return false;
    }
  };

  const refreshtokenentry = () => {
    // console.log("'...token checking...")
    const region = process.env.REACT_APP_AWS_REGION;

    const apiUrl = `https://cognito-idp.${region}.amazonaws.com/`;
    const requestBody = {
      AuthFlow: "REFRESH_TOKEN",
      ClientId: process.env.REACT_APP_CLIENT_ID_USER,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    };
    const headers = {
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      "Content-Type": "application/x-amz-json-1.1",
    };
    axios
      .post(apiUrl, requestBody, { headers })
      .then((response) => {
        // Handle the response as needed
        // console.log(".....refresh response...", response.data);
      })
      .catch((error) => {
        // Handle errors
        // console.error("Error in Axios refresh POST request:", error);
      });
  };

  const checkScreenActivity = () => {
    let isActive = false;

    const handleMove = () => {
      isActive = true;
    };

    window.addEventListener("mousemove", handleMove);

    // You can add more event listeners like 'keydown' or 'touchmove' if needed
    // window.addEventListener('keydown', handleMove);
    // window.addEventListener('touchmove', handleMove);

    setTimeout(() => {
      window.removeEventListener("mousemove", handleMove);
      // Remove other event listeners if added
      // window.removeEventListener('keydown', handleMove);
      // window.removeEventListener('touchmove', handleMove);

      // console.log(isActive ? 'Yes' : 'No');
      if (isActive) {
        // console.log("....yess...")
        refreshtokenentry();
      } else {
        //  console.log("...no...")
      }
    }, 3000); // Adjust the timeout duration as needed (3000 milliseconds = 3 seconds)
  };

  React.useEffect(() => {
    handleAccessToken();
    const timeoutId = setTimeout(() => {
      console.log("...trigger after 2 min");
      handleAccessToken();
    }, 55 * 60 * 1000);

    const foraccessToken = setTimeout(() => {
      console.log("...trigger after 5 min");
      // toast.error("Session expired");
      handleAccessToken();
    }, 60 * 60 * 1000);
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(foraccessToken);
    };
  }, []);

  useEffect(() => {
    const getUserAttributes = async () => {
      const cognitoIdentityServiceProvider =
        new AWS.CognitoIdentityServiceProvider();

      try {
        // Get the authenticated user
        const user = await cognitoIdentityServiceProvider
          .getUser({ AccessToken: accessToken })
          .promise();
        console.log("..user..", user);
        // Extract user attributes
        const attributes = user.UserAttributes;

        // Log user attributes, you can filter and extract the ones you need
        // console.log('User attributes:', attributes);

        // Example: Get email and custom profile picture attribute
        const emailAttribute = attributes.find((attr) => attr.Name === "email");
        const profilePictureAttribute = attributes.find(
          (attr) => attr.Name === "picture"
        );
        const nameAttribute = attributes.find(
          (attr) => attr.Name === "given_name"
        );
        const LastNameAttribute = attributes.find(
          (attr) => attr.Name == "family_name"
        );
        // console.log("..Name..",nameAttribute?.Value)
        // console.log('Email:', emailAttribute?.Value);
        // console.log('Profile Picture:', profilePictureAttribute?.Value);
        setProfilePictureAttribute(profilePictureAttribute?.Value);

        setEmailAttribute(emailAttribute?.Value);
        setNameAttribute(nameAttribute?.Value);
        setLastNameAttribute(LastNameAttribute?.Value);
      } catch (error) {
        if (
          error.code === "NotAuthorizedException" &&
          error.message === "Invalid Access Token"
        ) {
          // If the token is expired, attempt to refresh it
          // Implement your token refresh logic here
          console.error("Access token expired. Implement token refresh logic.");
        } else {
          console.error("Error getting user attributes:", error);
        }
      }
    };
    getUserAttributes();
  }, []);
  const DrawerHeader = styled("div")(({ theme }) => ({
    // display: "flex",
    alignItems: "center",
    // justifyContent: "flex-end",
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const client_data = localStorage.getItem("client_id");
  const idToken = localStorage.getItem("idToken");
  React.useEffect(() => {
    const idToken = localStorage.getItem("idToken");
    if (idToken) {
      const decodedToken = decodeJwt(idToken);
      const email = decodedToken.email;
      setEmailLocal(email);
    } else {
      console.error("ID token not found in localStorage");
    }
  }, []); // Empty dependency array ensures this effect runs only once

  // Function to decode JWT token
  function decodeJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  const [theme, setTheme] = React.useState(false);

  const handleJobs = () => {
    navigate("/dashboard");
  };

  const openedMixin = (theme, isDarkMode) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    backgroundColor: backgroundDrawer,
    color: colorDrawer,
  });

  const closedMixin = (theme, isDarkMode) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    backgroundColor: backgroundDrawer,
    color: colorDrawer,
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open, isDarkMode }) => ({
    width: drawerWidth,
    backgroundColor: isDarkMode ? "black" : "#FFF",
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme, isDarkMode),
      "& .MuiDrawer-paper": {
        ...openedMixin(theme, isDarkMode),
      },
    }),
    ...(!open && {
      ...closedMixin(theme, isDarkMode),
      "& .MuiDrawer-paper": {
        ...closedMixin(theme, isDarkMode),
      },
    }),
  }));

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSetting = () => {
    navigate("/setting");
  };

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };
  const BackToDashboard = () => {
    navigate("/dashboard");
  };
  const handleLogout = () => {
    localStorage.setItem("logedIn", false);

    navigate("/");
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        maxHeight: "calc(100vh - 40px)",
        overflowY: "auto",
        width: "auto",
        height: "100vh",
        paddingLeft: 6,
        backgroundColor: isDarkMode ? "black" : "#F9F9F9",
      }}
    >
      <CssBaseline />
      <NavBar
        pageTitle="Help"
        showNameWithTitle={false}
        nameAttribute={nameAttribute}
        LastnameAttribute={LastnameAttribute}
        profilePictureAttribute={profilePictureAttribute}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        open={open} // Pass the open state
        setOpen={setOpen} // Pass the setOpen function
      />

      <Box
        component="main"
        sx={{
          // height: "100%",
          flexGrow: 1,
          pt: 3,
          pl: open ? 20 : 5,
          pr: 3,
          pb: 5,
          mb: 2,
          backgroundColor: isDarkMode ? "black" : "#F9F9F9",
        }}
      >
        <DrawerHeader />
        <Box sx={{ maxWidth: 800, margin: "auto" }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: isDarkMode ? "#FFF" : "#142764",
              marginBottom: 4,
              textAlign: "center",
            }}
          >
            Getting Started Guide
          </Typography>

          <Card
            sx={{
              marginBottom: 4,
              backgroundColor: isDarkMode ? "#333" : "#f0f0f0",
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ color: isDarkMode ? "#FFF" : "#142764" }}
              >
                Watch Our Introduction Video
              </Typography>
              <Box
                sx={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                  overflow: "hidden",
                }}
              >
                <iframe
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  src="https://www.youtube.com/embed/uPkkoQ0hniI?si=_29Jc1XY9y20OTFR"
                  title="Introduction Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Box>
            </CardContent>
          </Card>

          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: isDarkMode ? "#FFF" : "#142764",
              marginTop: 4,
              marginBottom: 3,
            }}
          >
            How to Use Coucal: A Step-by-Step Guide
          </Typography>

          <Typography
            variant="body1"
            sx={{ color: isDarkMode ? "#CCC" : "#555", marginBottom: 3 }}
          >
            Welcome to our comprehensive guide on using our product to
            streamline your recruitment process. This tutorial will walk you
            through each step, from signing up to managing candidates, ensuring
            you make the most of our features. Let's get started!
          </Typography>

          <Box
            sx={{
              background: isDarkMode ? "#1e1e1e" : "#f9f9f9",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
            }}
          >
            {steps.map((step, index) => (
              <Box key={index} sx={{ marginBottom: 4 }}>
                <Typography
                  variant="h5"
                  sx={{
                    color: isDarkMode ? "#FFF" : "#142764",
                    marginBottom: 2,
                  }}
                >
                  {index + 1}. {step.label}
                </Typography>

                <Typography
                  variant="body1"
                  sx={{ color: isDarkMode ? "#CCC" : "#666", marginBottom: 2 }}
                >
                  {step.intro}
                </Typography>

                <ul
                  style={{
                    marginLeft: "20px",
                    color: isDarkMode ? "#CCC" : "#666",
                    fontSize: "16px",
                    listStyleType: "disc",
                  }}
                >
                  {step.points.map((point, i) => (
                    <li key={i} style={{ marginBottom: "10px" }}>
                      <strong>{point.title}:</strong> {point.description}
                    </li>
                  ))}
                </ul>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: isDarkMode ? "#080808" : "#EEF3F7",
          position: "fixed",
          bottom: 0,
          width: "100%",
          padding: 2,

          textAlign: "center",
          height: 50,
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: isDarkMode ? "#FFF" : "#142764" }}
        >
          &copy; copyright {new Date().getFullYear()}: Udyat Technologies
        </Typography>
      </Box>
    </Box>
  );
}
