import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("idToken"));
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));
  const [accessToken , setAccessToken] = useState(localStorage.getItem('accessToken'));
  const [email,setEmail] = useState('')

  return (
    <AuthContext.Provider value={{ token, setToken, refreshToken, setRefreshToken,accessToken,setAccessToken ,email,setEmail}}>
      {children}
    </AuthContext.Provider>
  );
};
