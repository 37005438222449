import React, { useState, useEffect } from 'react';
import { CognitoIdentityProviderClient, ChangePasswordCommand, UpdateUserAttributesCommand } from "@aws-sdk/client-cognito-identity-provider";
import { TextField, Button, Box, Typography, InputAdornment, IconButton } from '@mui/material';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

const InviteUserConfirmpwd = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { email, accessToken, clientId } = location.state || {};

  const lsClient_id = localStorage.getItem("idToken")

  useEffect(() => {
    if (!email || !accessToken) {
      setError("Missing required credentials. Please try logging in again.");
    } else {
      // Disable the browser's back button
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        window.history.pushState(null, null, window.location.href);
      };
    }
  }, [email, accessToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!email || !accessToken) {
      setError("Missing required credentials. Please try logging in again.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("New passwords don't match");
      return;
    }

    const client = new CognitoIdentityProviderClient({ region: process.env.REACT_APP_AWS_REGION });
    try {
      // Change password
      const changePasswordCommand = new ChangePasswordCommand({
        PreviousPassword: currentPassword,
        ProposedPassword: newPassword,
        AccessToken: accessToken,
      });
      await client.send(changePasswordCommand);

      // Update the user's custom:invitedUser attribute to false
      const updateAttributeCommand = new UpdateUserAttributesCommand({
        AccessToken: accessToken,
        UserAttributes: [
          {
            Name: "custom:invitedUser",
            Value: "false"
          }
        ]
      });
      await client.send(updateAttributeCommand);

      // Make API call to verify invite user and get client_id
      try {
        const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
        const apiResponse = await axios.post(
          `${baseUrl}/user/verify_invite_user`,
          { user_email_id: email },
          {
            headers: {
              'Authorization': `Bearer ${lsClient_id}`,
              'Content-Type': 'application/json'
            }
          }
        );

        if (apiResponse.data && apiResponse.data.client_id) {
          localStorage.setItem("client_id", apiResponse.data.client_id);
          navigate("/dashboard", {
            state: { client_id: apiResponse.data.client_id },
          });
        } else {
          console.error("Unexpected API response");
          toast.error("An unexpected error occurred. Please try again.");
        }
      } catch (apiError) {
        console.error("API error", apiError);
        toast.error("An error occurred while verifying your account. Please try again.");
      }
    } catch (error) {
      console.error("Error updating password or attribute:", error);
      setError(error.message || 'Failed to update password. Please try again.');
    }
  };

  const handleTogglePasswordVisibility = (setter) => () => {
    setter((prev) => !prev);
  };

  return (
    <Box 
      component="form" 
      onSubmit={handleSubmit} 
      sx={{ 
        maxWidth: 400, 
        margin: 'auto', 
        mt: 4, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '82vh',
        boxShadow: 3,
        p: 4,
        borderRadius: 2,
        backgroundColor: 'background.paper'
      }}
    >
      <Typography variant="h6" gutterBottom>Set New Password</Typography>
      
      <TextField
        fullWidth
        margin="normal"
        label="Email"
        value={email || ''}
        disabled
      />
      
      <TextField
        fullWidth
        margin="normal"
        label="Current Password"
        type={showCurrentPassword ? "text" : "password"}
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleTogglePasswordVisibility(setShowCurrentPassword)}
                edge="end"
              >
                {showCurrentPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      
      <TextField
        fullWidth
        margin="normal"
        label="New Password"
        type={showNewPassword ? "text" : "password"}
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleTogglePasswordVisibility(setShowNewPassword)}
                edge="end"
              >
                {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      
      <TextField
        fullWidth
        margin="normal"
        label="Confirm New Password"
        type={showConfirmPassword ? "text" : "password"}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleTogglePasswordVisibility(setShowConfirmPassword)}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      
      {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
      
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 3 }}>
        Set New Password
      </Button>
    </Box>
  );
};

export default InviteUserConfirmpwd;