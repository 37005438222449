import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/animated_logo.gif";

function UserCheck() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { email, AccessToken, IdToken, RefreshToken } = location.state;

    navigate("/create-company", {
      state: {email:email,AccessToken:AccessToken,IdToken:IdToken,RefreshToken:RefreshToken}
    });
  }, [navigate, location.state]);

  // Show loading spinner while navigating
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        margin: "0",
      }}
    >
      <img
        style={{ width: "50px", height: "50px" }}
        src={logo}
        alt="User Check GIF"
      />
    </div>
  );
}

export default UserCheck;

