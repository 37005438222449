import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Container } from "@mui/material";
import styles from "../css/Confirmation.module.css";
import logo from "../assets/Icon.png";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

function ConfirmationPage() {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(30);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          navigate("/");
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate]);

  const handleLogin = () => {
    console.log("Login clicked");
    navigate("/");
  };

  return (
    <Container style={{display:"flex"}} className={styles.container}>
      <div className={styles.content}>
        <img className={styles.logo} src={logo} alt="Coucal AI logo" />
        <Typography variant="h3" fontWeight={"bold"} className={styles.welcomeText}>
          Welcome to Coucal AI
        </Typography>
        <Typography variant="body1" className={styles.caption}>
          Let's dive into the world of AI wonders. Hire Smartly
        </Typography>
        <Button
          onClick={handleLogin}
          variant="contained"
          className={styles.startButton}
          endIcon={<ArrowForwardRoundedIcon />}
        >
          Get Started
        </Button>
        <Typography variant="body2" className={styles.timer}>
          Redirecting in {timeLeft} seconds...
        </Typography>
      </div>
    </Container>
  );
}

export default ConfirmationPage;