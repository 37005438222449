import React, { useState, useEffect } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Grid,
  Paper,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import dayjs from "dayjs";



const renderDataSection = (title, items, formData) => (
  <Paper elevation={3} sx={{ mb: 3, p: 2, borderRadius: 2 }}>
    <Typography
      variant="h6"
      gutterBottom
      color="primary"
      sx={{ marginBottom: 1.5 }}
    >
      {title}
    </Typography>
    <Grid container spacing={2}>
      {items.map(({ label, value }) => (
        <Grid
          item
          xs={12}
          md={label === "Skill Required" ? 12 : 6}
          key={label}
          sx={{ marginBottom: 1 }}
        >
          <Typography variant="subtitle2" sx={{ color: "#044583" }}>
            {label}
          </Typography>
          {label === "Skill Required" && Array.isArray(value) ? (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                width: "100%",
                marginTop: "2px",
              }}
            >
              {value.map((skill, index) => (
                <Chip
                  key={index}
                  label={skill}
                  variant="outlined"
                  sx={{ width: "auto", color: "#142764" }}
                />
              ))}
            </Box>
          ) : (
            <Typography variant="body1" sx={{ color: "#142764" }}>
              {value}
            </Typography>
          )}
        </Grid>
      ))}
    </Grid>
  </Paper>
);

const Step7 = ({
  handleBack,
  handleNext,
  formData,
  setFormData,
  handleAPICall,
}) => {
  const [activeStep, setActiveStep] = useState(6);
  const [callScheduleDate, setCallScheduleDate] = useState("");
  const [hiringDate, setHiringDate] = useState("");
  const [codingDate, setCodingDate] = useState("");
  const [codingEndDate, setCodingEndDate] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const steps = [
    "Job Details",
    "Other Details",
    "Job Description",
    "Features",
    ...(formData.initialChecked ? ["Initial Screening Questions"] : []), // Include conditionally
    // "Technical Screening Questions",
    "Complete!",
  ];
  console.log("intial checking", formData.checked)
  const handleFinishClick = () => {
    handleAPICall(formData);
  };
console.log("form", formData)
  const handleCancel = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmCancel = () => {
    window.location.href = "/dashboard";
  };
  const isLocationRemote = formData.jobLocation !== 'Remote';

  const handleBackButton = () => {
    // Determine the number of steps to go back based on the formData.checked value
    const shouldGoBackTwice = !formData.checked; // Back twice if formData.checked is false
  
    const numberOfBacks = shouldGoBackTwice ? 2 : 1; // Back twice or once based on the condition
  
    // Execute handleBack for the determined number of times
    for (let i = 0; i < numberOfBacks; i++) {
      handleBack();
    }
  };
  
  
  
  console.log("back check", formData.screening_questions)

  useEffect(() => {
    const callSchedule = formData.call_schedule;
    if (dayjs(callSchedule).isValid()) {
      const extractedDate = dayjs(callSchedule).format("YYYY-MMM-DD");
      setCallScheduleDate(extractedDate);
    }
  }, [formData.call_schedule]);
  
  useEffect(() => {
    const targetHiringDate = formData.target_hiring_date;
    if (dayjs(targetHiringDate).isValid()) {
      const extractedHiringDate = dayjs(targetHiringDate).format("YYYY-MMM-DD");
      setHiringDate(extractedHiringDate);
    }
  }, [formData.target_hiring_date]);

  useEffect(() => {
    const coding_assessment = formData.coding_assessment?.coding_start_date;
    if (dayjs(coding_assessment).isValid()) {
      const extractedCodingDate = dayjs(coding_assessment).format("YYYY-MMM-DD");
      setCodingDate(extractedCodingDate);
    }
  }, [formData.coding_assessment?.coding_start_date]);
console.log("coding start date",codingDate )  

useEffect(() => {
  const coding_assessment_end = formData.coding_assessment?.coding_end_date;
  if (dayjs(coding_assessment_end).isValid()) {
    const extractedCodingEndDate = dayjs(coding_assessment_end).format("YYYY-MMM-DD");
    setCodingEndDate(extractedCodingEndDate);
  }
}, [formData.coding_assessment?.coding_end_date]);
console.log("coding end date",codingEndDate ) 
  return (
    <Box sx={{ width: "100%", margin: "0 auto", mt: 2, p: 2 }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{ marginBottom: 3 }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ width: "60%", maxWidth: 1200, margin: "0 auto", mt: 4, p: 2 }}>
        {[
          {
            title: "Job Details",
            items: [
              { label: "Job Title", value: formData.jobTitle },
              { label: "Job Type", value: formData.jobType },
              { label: "Job Category", value: formData.jobCategory },
              { label: "Number Of Vacancy", value: formData.vacancy },
              {
                label: "Experience",
                value: `${formData.minExp} - ${formData.maxExp} years`,
              },
              { label: "Job Location", value: formData.location },
              // { label: "Maximum Experience", value: formData.maxExp },
            ],
          },
          formData.jobLocation !== "Remote" && {
            title: "Location Details",
            items: [
              { label: "City", value: formData.city ? formData.city : " -" },
              { label: "State", value: formData.state ? formData.state : " -" },
              { label: "Address", value: formData.streetAddress ? formData.streetAddress : " -" },
              { label: "Country", value: formData.country ? formData.country : " -" },
            ],
          },

          {
            title: "Job Description",
            items: [
              {
                label: "Job Description",
                value: (
                  <p style={{ textAlign: "justify" }}>
                    {formData.jobDescription}
                  </p>
                ),
              },
              {
                label: "Key Responsibility",
                value: (
                  <p style={{ textAlign: "justify" }}>
                    {formData.KeyResponsibility}
                  </p>
                ),
              },
              {
                label: "Skill Required",
                value: formData.skills ? formData.skills.split(",") : [],
              },
            ],
          },
          {
            title: "Additional Details",
            items: [
              { label: "Campaign start date", value: callScheduleDate ? callScheduleDate : "Not available"},
              { label: "Target Hiring Date", value: hiringDate ? hiringDate : "Not available"},
              { label: "Qualification", value: formData.qualification },
              formData.coding_assessment && { label: "Programming Language", value: formData.coding_assessment.coding_language },
              formData.coding_assessment && { label: "Coding Assessment Start Date", value: codingDate },
              formData.coding_assessment && { label: "Coding Assessment End Date", value: codingEndDate },
            ].filter(Boolean),
          },
        ].filter(Boolean).map(({ title, items }) => renderDataSection(title, items, formData))}

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          <div>
            <Button
              variant="outlined"
              color="error"
              onClick={handleCancel}
              sx={{ marginRight: 1 }}
            >
              Cancel
            </Button>
          </div>
          <Box>
            <Button
              variant="outlined"
              onClick={handleBackButton}
              sx={{ marginRight: 1 }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleFinishClick}
              sx={{
                bgcolor: "#4caf50",
                color: "#fff",
                "&:hover": { bgcolor: "#388e3c" },
              }}
            >
             Finish
            </Button>
          </Box>
        </Box>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 0,
              pl: 3,
              mb: -2,
            }}
          >
            <WarningIcon color="warning" sx={{ marginRight: -2 }} />
            <DialogTitle>Are you sure?</DialogTitle>
          </Box>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to cancel the job creation?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleCloseDialog}
              color="primary"
            >
              cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirmCancel}
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Step7;
