import React, { useState, useRef, useEffect } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import Pool from "../UserPool";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Avatar,
  Container,
  CssBaseline,
  Snackbar,
  IconButton,
  Grow,
  Paper,
  Card,
  CardContent,
  SvgIcon,
} from "@mui/material";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import AWS from "aws-sdk";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

function ForgotPassword() {
  const [stage, setStage] = useState(1);
  const [email, setEmail] = useState("");
  const [timer, setTimer] = useState(60);
  const [showResendButton, setShowResendButton] = useState(false);
  const [resendClicked, setResendClicked] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const [isLoading, setIsLoading] = useState(false);
  const clientId = process.env.REACT_APP_CLIENT_ID_USER;

  useEffect(() => {
    AWS.config.update({ region: process.env.REACT_APP_AWS_REGION });
  }, []);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setShowResendButton(true);
    }

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (otp.every((digit) => digit !== "")) {
      setShowPasswordFields(true);
    } else {
      setShowPasswordFields(false);
    }
  }, [otp]);

  useEffect(() => {
    let timer;
    if (stage === 3) {
      timer = setTimeout(() => {
        navigate("/"); // Redirect to homepage after 30 seconds
      }, 30000); // 30 seconds (30000 milliseconds)
    }

    // Cleanup timeout if component unmounts or stage changes
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [stage]);

  const cognito = new AWS.CognitoIdentityServiceProvider();
  const userPool = Pool;

  const getUser = (username) => {
    return new CognitoUser({
      Username: username.toLowerCase(),
      Pool: userPool,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      showSnackbar("Please enter a valid email address", "error");
      return;
    }
    try {
      await resetPassword(email);
      showSnackbar("Verification code sent successfully", "success");
      setStage(2);
    } catch (error) {
      showSnackbar(error.message, "error");
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const sendCode = () => {
    getUser(email).forgotPassword({
      onSuccess: (data) => {
        console.log("onSuccess:", data);
        showSnackbar("Verification code sent successfully", "success");
        setStage(2);
      },
      onFailure: (err) => {
        console.error("onFailure:", err);
        showSnackbar(
          "Failed to send verification code. Please try again.",
          "error"
        );
      },
      inputVerificationCode: (data) => {
        console.log("Input code:", data);
        setStage(2);
      },
    });
  };

  const handleResendCode = () => {
    setTimer(60);
    setShowResendButton(false);
    setResendClicked(true);
    sendCode();
  };

  const resetPassword = (username) => {
    const cognitoUser = getUser(username);

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          console.log("Password reset initiated successfully:", result);
          resolve(result);
        },
        onFailure: function (err) {
          console.error("Password reset failed:", err);
          reject(err);
        },
        inputVerificationCode: function (data) {
          console.log("Verification code needed:", data);
          setStage(2);
        },
      });
    });
  };

  const confirmPasswords = (username, verificationCode, newPassword) => {
    const cognitoUser = getUser(username);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onFailure(err) {
          console.error("Confirm password failed:", err);
          reject(err);
        },
        onSuccess() {
          console.log("Password confirmed successfully");
          resolve();
        },
      });
    });
  };

  const handleChange = (index, value) => {
    if (/^\d?$/.test(value)) {
      if (value.length > 1) {
        value = value.charAt(0);
      }
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== "" && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (index, event) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);
      inputRefs.current[index - 1].focus();
    }
  };

  const handleVerifyAndResetPassword = async () => {
    if (otp.some((digit) => digit === "")) {
      showSnackbar("Please enter all OTP digits", "error");
      return;
    }

    if (password !== confirmPassword) {
      showSnackbar("Passwords do not match", "error");
      return;
    }

    const verificationCode = otp.join("");
    try {
      await confirmPasswords(email, verificationCode, password);
      // showSnackbar("Password reset successful!", "success");
      setStage(3); // Set stage to show the success message
    } catch (error) {
      if (error.code === "CodeMismatchException") {
        showSnackbar("Invalid verification code. Please try again.", "error");
      } else if (error.code === "ExpiredCodeException") {
        showSnackbar(
          "Verification code has expired. Please request a new code.",
          "error"
        );
        setStage(1);
      } else {
        showSnackbar(`Error resetting password: ${error.message}`, "error");
      }
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };
  const ShieldIcon = (props) => (
    <SvgIcon {...props}>
      <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z" />
    </SvgIcon>
  );
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth={stage === 3 ? "md" : "xs"}>
        <CssBaseline />

        <Box
          sx={{
            marginTop: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {stage !== 3 && (
            <>
              <Avatar sx={{ bgcolor: "#E1EBEE", width: 56, height: 56 }}>
                <VpnKeyRoundedIcon
                  sx={{ width: 30, height: 30, color: "#002D62" }}
                />
              </Avatar>
              <Typography
                component="h1"
                variant="h4"
                marginTop={2}
                style={{ color: "#363640", fontWeight: "600" }}
              >
                Forgot Password
              </Typography>
            </>
          )}
          {stage === 1 && (
            <>
              <Typography
                variant="subtitle1"
                marginTop={2}
                style={{ color: "#363640", textAlign: "center" }}
              >
                Enter your email and we'll send you a code to reset your
                password.
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={(event) => handleSubmit(event)}
                sx={{ mt: 3, pb: 3, width: "100%" }}
              >
                <TextField
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  sx={{ width: "100%" }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={email !== "" && !validateEmail(email)}
                  helperText={
                    email !== "" && !validateEmail(email)
                      ? "Invalid email address"
                      : ""
                  }
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 4,
                    mb: 2,
                    height: 50,
                    textTransform: "inherit",
                    fontSize: "18px",
                  }}
                >
                  Send Verification Code
                </Button>
              </Box>
            </>
          )}
          {stage === 2 && (
            <>
              <Typography
                variant="subtitle1"
                style={{
                  color: "#363640",
                  fontWeight: "400",
                  textAlign: "center",
                  marginBottom: 3,
                }}
              >
                The code has been sent to: <strong>{email}</strong>
              </Typography>
              <Box sx={{ width: "100%", mt: 2 }}>
                <Grid container spacing={2} justifyContent="center">
                  {otp.map((digit, index) => (
                    <Grid item key={index} xs={2}>
                      <TextField
                        type="text"
                        inputRef={(ref) => (inputRefs.current[index] = ref)}
                        value={digit}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => handleBackspace(index, e)}
                        variant="outlined"
                        inputProps={{
                          maxLength: 1,
                          pattern: "[0-9]*",
                          inputMode: "numeric",
                          style: {
                            fontSize: 24,
                            padding: "12px",
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "#363640",
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#E0E0E0",
                              borderRadius: "8px",
                            },
                            "&:hover fieldset": {
                              borderColor: "#1976d2",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#1976d2",
                            },
                          },
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Grow
                in={showPasswordFields}
                style={{ transformOrigin: "1 2 0" }}
                timeout={800}
              >
                <Box sx={{ width: "100%", mt: 4 }}>
                  <TextField
                    required
                    fullWidth
                    id="password"
                    label="New Password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ mb: 2 }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                  <TextField
                    required
                    fullWidth
                    id="confirm-password"
                    label="Confirm Password"
                    name="confirm-password"
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    sx={{ mb: 2 }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={handleToggleConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                  <Button
                    onClick={handleVerifyAndResetPassword}
                    variant="contained"
                    sx={{
                      mt: 4,
                      height: 60,
                      textTransform: "inherit",
                      fontSize: "20px",
                      width: "100%",
                      maxWidth: "360px",
                      marginLeft: "18px",
                    }}
                  >
                    Verify and Reset Password
                  </Button>
                  {showResendButton && !resendClicked && (
                    <Button
                      onClick={handleResendCode}
                      variant="text"
                      sx={{
                        mt: 2,
                        height: 50,
                        textTransform: "inherit",
                        fontSize: "18px",
                        width: "100%",
                        maxWidth: "360px",
                        color: "#9C9C9C",
                        marginLeft: "27px",
                      }}
                    >
                      Resend Code
                    </Button>
                  )}
                </Box>
              </Grow>
            </>
          )}
          {stage === 3 && (
            <Container maxWidth="lg">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "80vh",
                  py: { xs: 4, sm: 6, md: 8 },
                }}
              >
                <Card
                  elevation={6}
                  sx={{
                    width: {
                      xs: "95%",
                      sm: "80%",
                      md: "70%",
                      lg: "90%",
                      xl: "90%",
                    },
                    maxWidth: "600px",
                    borderRadius: { xs: "16px", sm: "20px" },
                    boxShadow: "0 8px 24px rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <CardContent
                    sx={{
                      textAlign: "center",
                      py: { xs: 4, sm: 5, md: 6, lg: 6 },
                      px: { xs: 2, sm: 4, md: 5, lg: 5 },
                    }}
                  >
                    <Box
                      sx={{
                        mb: { xs: 3, sm: 4, md: 5, lg: 5 },
                      }}
                    >
                      <VerifiedUserOutlinedIcon
                        sx={{
                          fontSize: { xs: 64, sm: 80, md: 96 },
                          color: "#4caf50",
                          backgroundColor: "#e8f5e9",
                          borderRadius: "50%",
                          padding: {
                            xs: "12px",
                            sm: "16px",
                            md: "20px",
                            lg: "20px",
                          },
                        }}
                      />
                    </Box>
                    <Typography
                      variant="h4"
                      gutterBottom
                      sx={{
                        fontWeight: 700,
                        color: "text.primary",
                        fontSize: {
                          xs: "1.5rem",
                          sm: "1.75rem",
                          md: "2rem",
                          lg: "2rem",
                        },
                        mb: { xs: 1, sm: 2 },
                      }}
                    >
                      Your Password has been reset
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 600,
                        color: "success.main",
                        mb: { xs: 3, sm: 4 },
                        fontSize: {
                          xs: "1.25rem",
                          sm: "1.5rem",
                          md: "1.75rem",
                          lg: "1.75rem",
                        },
                      }}
                    >
                      Successfully
                    </Typography>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => {
                        navigate("/");
                      }}
                      sx={{
                        mt: { xs: 2, sm: 3 },
                        backgroundColor: "success.main",
                        color: "common.white",
                        borderRadius: "8px",
                        px: { xs: 3, sm: 4, md: 5 },
                        py: { xs: 1, sm: 1.5 },
                        fontSize: {
                          xs: "1rem",
                          sm: "1.1rem",
                          md: "1.2rem",
                          lg: "1.2rem",
                        },
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "success.dark",
                        },
                      }}
                    >
                      Login
                    </Button>
                  </CardContent>
                </Card>
              </Box>
            </Container>
          )}
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbar.message}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
          ContentProps={{
            sx: {
              background: snackbar.severity === "error" ? "#f44336" : "#4caf50",
            },
          }}
        />
      </Container>

      <Box
        sx={{
          backgroundColor: "#EEF3F7",
          position: "fixed",
          bottom: 0,
          width: "100%",
          padding: 2,
          textAlign: "center",
          height: 50,
        }}
      >
        <Typography variant="body2" sx={{ color: "#142764" }}>
          &copy; copyright {new Date().getFullYear()}: Udyat Technologies
        </Typography>
      </Box>
    </ThemeProvider>
  );
}

export default ForgotPassword;
