import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {
  Avatar,
  Badge,
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
// import animated from "../assets/animated_logo.gif";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import axios from "axios";
import { CognitoAccessToken } from "amazon-cognito-identity-js";
import NavBar from "./Navbar/Navbar";

export default function Profileupdate() {
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordNew, setShowPasswordNew] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const [profilePictureAttribute, setProfilePictureAttribute] = useState(null);
  const [emailAttribute, setEmailAttribute] = useState(null);
  const [nameAttribute, setNameAttribute] = useState(null);
  const [LastnameAttribute, setLastNameAttribute] = useState(null);
  const [phonenumberAttribute, setphoneAttribute] = useState(null);
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [PictureData, setPicture] = useState(null);
  const [imageLoading, setImageLoading] = useState(true);

  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "********",
  });

  const [changePassword, setChangePassword] = React.useState(false);
  const [passwordFields, setPasswordFields] = React.useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errorDetails, setErrorDetails] = useState({});

  const validateFields = () => {
    let isValid = true;
    const newErrors = { oldPassword: "", newPassword: "", confirmPassword: "" };

    if (!passwordFields.oldPassword) {
      newErrors.oldPassword = "Old password is required";
      isValid = false;
    }

    if (!passwordFields.newPassword) {
      newErrors.newPassword = "New password is required";
      isValid = false;
    } else if (passwordFields.newPassword.length < 8) {
      newErrors.newPassword = "New password must be at least 8 characters long";
      isValid = false;
    }

    if (!passwordFields.confirmPassword) {
      newErrors.confirmPassword = "Confirm password is required";
      isValid = false;
    } else if (passwordFields.newPassword !== passwordFields.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const navigate = useNavigate();

  const refreshToken = localStorage.getItem("refreshToken");
  const handleAccessToken = () => {
    const accessToken = localStorage.getItem("accessToken");

    const navigateAndReturn = (path) => {
      navigate(path);
      return false;
    };

    if (!accessToken || !checkAccessTokenValidity(accessToken)) {
      // console.log("...to redirect on login")
      localStorage.setItem("logedIn", false);
      return navigateAndReturn("/");
    } else {
      // console.log("...to redirect on dashboard")
      // refreshtokenentry()
      checkScreenActivity();
      return navigateAndReturn("/profile-update");
    }
    // console.log("...dashboard return ...");
  };

  const checkAccessTokenValidity = (accessToken) => {
    try {
      const decodedToken = new CognitoAccessToken({ AccessToken: accessToken });

      const expirationTime = decodedToken.getExpiration();

      const currentTime = Math.floor(Date.now() / 1000);

      if (expirationTime > currentTime) {
        // console.log("Expiration time is greater than current time. Triggering useEffect logic.");
        return expirationTime > currentTime;
      }
    } catch (error) {
      console.error("Error decoding token or missing expiration time:", error);
      return false;
    }
  };

  const refreshtokenentry = () => {
    // console.log("'...token checking...")
    const region = process.env.REACT_APP_AWS_REGION;
    const apiUrl = `https://cognito-idp.${region}.amazonaws.com/`;
    const requestBody = {
      AuthFlow: "REFRESH_TOKEN",
      ClientId: process.env.REACT_APP_CLIENT_ID_USER,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    };
    const headers = {
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      "Content-Type": "application/x-amz-json-1.1",
    };
    axios
      .post(apiUrl, requestBody, { headers })
      .then((response) => {
        // Handle the response as needed
        // console.log(".....refresh response...", response.data);
      })
      .catch((error) => {
        // Handle errors
        // console.error("Error in Axios refresh POST request:", error);
      });
  };

  const checkScreenActivity = () => {
    let isActive = false;

    const handleMove = () => {
      isActive = true;
    };

    window.addEventListener("mousemove", handleMove);

    // You can add more event listeners like 'keydown' or 'touchmove' if needed
    // window.addEventListener('keydown', handleMove);
    // window.addEventListener('touchmove', handleMove);

    setTimeout(() => {
      window.removeEventListener("mousemove", handleMove);
      // Remove other event listeners if added
      // window.removeEventListener('keydown', handleMove);
      // window.removeEventListener('touchmove', handleMove);

      // console.log(isActive ? 'Yes' : 'No');
      if (isActive) {
        // console.log("....yess...")
        refreshtokenentry();
      } else {
        //  console.log("...no...")
      }
    }, 3000); // Adjust the timeout duration as needed (3000 milliseconds = 3 seconds)
  };

  useEffect(() => {
    handleAccessToken();
    const timeoutId = setTimeout(() => {
      console.log("...trigger after 2 min");
      handleAccessToken();
    }, 55 * 60 * 1000);

    const foraccessToken = setTimeout(() => {
      console.log("...trigger after 5 min");
      // toast.error("Session expired");
      handleAccessToken();
    }, 60 * 60 * 1000);
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(foraccessToken);
    };
  }, []);

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: "none",
    backgroundColor: "blue",
  }));

  const DrawerHeader = styled("div")(({ theme }) => ({
    // display: "flex",
    alignItems: "center",
    // justifyContent: "flex-end",
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,
  }));

  const accessToken = localStorage.getItem("accessToken");
  const handlePasswordCancelClick = () => {
    setChangePassword(false);
    // setPasswordFields({ oldPassword: '', newPassword: '', confirmPassword: '' });
    setErrors({ oldPassword: "", newPassword: "", confirmPassword: "" });
  };

  const handlePasswordChangeClick = () => {
    setChangePassword(true);
  };
  const [originalData, setOriginalData] = useState({});
  // const [errors, setErrors] = useState({});

  const handlePasswordSaveClick = async () => {
    if (validateFields()) {
      try {
        const cognitoIdentityServiceProvider =
          new AWS.CognitoIdentityServiceProvider();
        await cognitoIdentityServiceProvider
          .changePassword({
            PreviousPassword: passwordFields.oldPassword,
            ProposedPassword: passwordFields.newPassword,
            AccessToken: accessToken,
          })
          .promise();

        setPasswordFields({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        setChangePassword(false);
        toast.success("Password changed successfully!");
      } catch (error) {
        console.error("Error changing password:", error);
        alert("Failed to change password. Please try again.");
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim())
      newErrors.firstName = "First name is required";
    if (!formData.lastName.trim()) newErrors.lastName = "Last name is required";
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\+91[1-9]\d{9}$/.test(formData.phone)) {
      newErrors.phone = "Invalid phone number format";
    }
    setErrorDetails(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleTogglePasswordNew = () => {
    setShowPasswordNew((prevShowPasswordNew) => !prevShowPasswordNew);
  };
  const handleTogglePasswordConfirm = () => {
    setShowPasswordConfirm(
      (prevShowPasswordConfirm) => !prevShowPasswordConfirm
    );
  };
  const handlePasswordInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
    // Clear the error when user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handlePhotoUpload = async () => {
    console.log("..handleUpload");
    setImageLoading(true);
    const input = document.createElement("input");
    input.type = "file";
    const client_id = localStorage.getItem("client_id");
    const idToken = localStorage.getItem("idToken");

    input.onchange = async (e) => {
      const file = e.target.files[0];
      console.log("Selected file:", file.name);

      try {
        const token = localStorage.getItem("idToken");

        // Generate a unique key for the image (you can use any method to generate a unique key)
        const key = `${Date.now()}-${file.name}`;

        // Upload the image to S3 with the generated key
        const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
        const UploadUrl = process.env.REACT_APP_COUCAL_IMAGE_URL;
        const uploadResponse = await fetch(
          `${baseUrl}/imageupload/${UploadUrl}/${key}`,
          {
            method: "PUT",
            body: file,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const imageKey = process.env.REACT_APP_COUCAL_IMAGE_KEY;
        if (uploadResponse.ok) {
          // Construct the URL for the uploaded image
          const imageUrl = `${imageKey}/${key}`;

          // Save imageUrl to state
          setImageDataUrl(imageUrl);

          console.log("Image uploaded successfully. Image URL:", imageUrl);
          const cognitoIdentityServiceProvider =
            new AWS.CognitoIdentityServiceProvider();
          await cognitoIdentityServiceProvider
            .updateUserAttributes({
              AccessToken: accessToken, // Make sure to define accessToken
              UserAttributes: [{ Name: "picture", Value: imageUrl }],
            })
            .promise();
        } else {
          throw new Error("Failed to upload image");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        // Handle error
      }
    };

    input.click();
  };

  const handleCancelClick = () => {
    // setEditMode(false);
    setErrorDetails({});
    setFormData({ ...originalData });
  };

  const handleSaveClick = async () => {
    if (validateForm()) {
      // Update user attributes in AWS Cognito
      const cognitoIdentityServiceProvider =
        new AWS.CognitoIdentityServiceProvider();
      const accessToken = localStorage.getItem("accessToken");

      try {
        const response = await cognitoIdentityServiceProvider
          .updateUserAttributes({
            AccessToken: accessToken,
            UserAttributes: [
              { Name: "given_name", Value: formData.firstName },
              { Name: "family_name", Value: formData.lastName },
              { Name: "phone_number", Value: formData.phone },
            ],
          })
          .promise();
        setEditMode(false);
        setErrorDetails({});
        setOriginalData({ ...formData });
        // console.log("Attributes updated successfully:", response);
      } catch (error) {
        console.error("Error updating attributes:", error);
        // Handle error (e.g., show error message to the user)
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  console.log("...imageDataUrl...".imageDataUrl);
  useEffect(() => {
    const getUserAttributes = async () => {
      const cognitoIdentityServiceProvider =
        new AWS.CognitoIdentityServiceProvider();

      try {
        // Get the authenticated user
        const user = await cognitoIdentityServiceProvider
          .getUser({ AccessToken: accessToken })
          .promise();
        console.log("..user..", user);
        // Extract user attributes
        const attributes = user.UserAttributes;

        // Log user attributes, you can filter and extract the ones you need
        // console.log('User attributes:', attributes);

        // Example: Get email and custom profile picture attribute
        const emailAttribute = attributes.find((attr) => attr.Name === "email");
        const profilePictureAttribute = attributes.find(
          (attr) => attr.Name === "picture"
        );
        const nameAttribute = attributes.find(
          (attr) => attr.Name === "given_name"
        );
        const LastNameAttribute = attributes.find(
          (attr) => attr.Name == "family_name"
        );
        const PhoneNumberAttribute = attributes.find(
          (attr) => attr.Name == "phone_number"
        );
        const profilePicture = attributes.find(
          (attr) => attr.Name == "picture"
        );
        const newData = {
          firstName: nameAttribute?.Value || "",
          lastName: LastNameAttribute?.Value || "",
          phone: PhoneNumberAttribute?.Value || "",
          email: emailAttribute?.Value || "",
          password: "********",
        };

        setFormData(newData);
        setOriginalData(newData);
        // console.log("..Name..",nameAttribute?.Value)
        // console.log('Email:', emailAttribute?.Value);
        // console.log('Profile Picture:', profilePictureAttribute?.Value);
        setProfilePictureAttribute(profilePictureAttribute?.Value);

        setEmailAttribute(emailAttribute?.Value);
        setNameAttribute(nameAttribute?.Value);
        setLastNameAttribute(LastNameAttribute?.Value);
        setphoneAttribute(PhoneNumberAttribute?.Value);
        setPicture(profilePicture?.Value);
        setFormData({
          firstName: nameAttribute?.Value || "",
          lastName: LastNameAttribute?.Value || "",
          phone: PhoneNumberAttribute?.Value || "",
          email: emailAttribute?.Value || "",
          password: "********",
        });
        if (profilePictureAttribute?.Value) {
          setImageLoading(false);
        }
      } catch (error) {
        if (
          error.code === "NotAuthorizedException" &&
          error.message === "Invalid Access Token"
        ) {
          // If the token is expired, attempt to refresh it
          // Implement your token refresh logic here
          console.error("Access token expired. Implement token refresh logic.");
        } else {
          console.error("Error getting user attributes:", error);
        }
      }
    };
    getUserAttributes();
  }, [profilePictureAttribute]);

  console.log("..profile...", profilePictureAttribute);
  console.log("...email...", emailAttribute);
  console.log("....name...", nameAttribute);
  console.log("...lastname...", LastnameAttribute);
  console.log("formData...", formData);
  console.log("...pictureData..", PictureData);

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        maxHeight: "calc(100vh - 40px)",
        overflowY: "auto",
        width: "auto",
        height: "100vh",
        paddingLeft: 6,
        backgroundColor: isDarkMode ? "black" : "#F9F9F9",
      }}
    >
      <CssBaseline />
      <NavBar
        pageTitle="My Profile"
        showNameWithTitle={false}
        nameAttribute={nameAttribute}
        LastnameAttribute={LastnameAttribute}
        profilePictureAttribute={profilePictureAttribute}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        open={open} // Pass the open state
        setOpen={setOpen} // Pass the setOpen function
      />
      <Box
        component="main"
        sx={{
          height: "100%",
          flexGrow: 1,
          pt: 3,
          pl: open ? 20 : 5,
          pr: 3,
          pb: 5,
          backgroundColor: isDarkMode ? "black" : "#F9F9F9",
        }}
      >
        <DrawerHeader />
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            color={isDarkMode ? "#FFF" : "#142764"}
            sx={{ fontWeight: "500", marginLeft: 1 }}
          >
            Profile Details
          </Typography>
          {editMode ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <Button
                onClick={handleCancelClick}
                variant="text"
                sx={{ mr: 1, width: 100 }}
              >
                Cancel
              </Button>
              <Button onClick={handleSaveClick} variant="contained">
                Save
              </Button>
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handleEditClick}
                variant="contained"
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Box>
          )}
        </Box>
        {/* <Paper style={containerStyle}> */}
        <Container maxWidth="sm" marginTop="10">
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <SmallAvatar sx={{ backgroundColor: "#bcd1f7" }}>
                  <IconButton sx={{ marginTop: 1 }} onClick={handlePhotoUpload}>
                    <EditRoundedIcon
                      sx={{ width: 15, height: 15, color: "#3b2aad", mb: 1 }}
                    />
                  </IconButton>
                </SmallAvatar>
              }
            >
              {/* {imageDataUrl && <img src={imageDataUrl} alt="Uploaded Image" />} */}
              {imageDataUrl ? (
                <Avatar
                  alt="Profile Picture"
                  src={imageDataUrl || "default_profile_picture_url"}
                  sx={{
                    width: 100,
                    height: 100,
                    borderRadius: "50%",
                    margin: "auto",
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    width: 100,
                    height: 100,
                    backgroundColor: "#1F098C",
                    cursor: "pointer",
                  }}
                  alt="Initials"
                  src={profilePictureAttribute}
                  onClick={handlePhotoUpload}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: 30,
                      // marginTop: "3px",
                    }}
                  >
                    {nameAttribute?.charAt(0).toUpperCase()}
                    {LastnameAttribute?.charAt(0).toUpperCase()}
                  </Typography>
                </Avatar>
              )}
            </Badge>
          </Box>
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={!editMode}
                value={formData.firstName}
                onChange={handleInputChange}
                error={!!errorDetails.firstName}
                helperText={errorDetails.firstName}
                autoComplete="given-name"
                name="firstName"
                required
                sx={{ width: 268 }}
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={!editMode}
                value={formData.lastName}
                onChange={handleInputChange}
                error={!!errorDetails.lastName}
                helperText={errorDetails.lastName}
                required
                sx={{ width: 268 }}
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography style={{ marginLeft: "0px" }}>+91</Typography>
                    </InputAdornment>
                  ),
                }}
                disabled={!editMode}
                value={
                  formData.phone.startsWith("+91")
                    ? formData.phone.slice(3)
                    : formData.phone
                }
                onChange={(e) => {
                  const phoneValue = e.target.value.replace(/\D/g, ""); // Remove non-digits
                  handleInputChange({
                    target: {
                      name: "phone",
                      value: `+91${phoneValue}`,
                    },
                  });
                }}
                required
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                autoComplete="tel"
                error={!!errorDetails.phone}
                helperText={errorDetails.phone}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={"true"}
                required
                fullWidth
                value={formData.email}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              marginTop: 2,
              marginBottom: 2,
              backgroundColor: isDarkMode ? "black" : "#F9F9F9",
            }}
          >
            {changePassword ? (
              <Grid
                container
                spacing={2}
                marginTop={2}
                alignItems="center"
                sx={{ display: "flex", flexDirection: "row", marginBottom: 2 }}
              >
                <Grid item xs={12}>
                  <TextField
                    onChange={handlePasswordInputChange}
                    name="oldPassword"
                    required
                    autoFocus
                    fullWidth
                    id="oldPassword"
                    label="Old Password"
                    error={!!errors.oldPassword}
                    helperText={errors.oldPassword}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <Button
                          onClick={handleTogglePasswordVisibility}
                          tabIndex={-1}
                          sx={{ padding: 0 }}
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </Button>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoFocus={changePassword}
                    value={passwordFields.newPassword}
                    onChange={handlePasswordInputChange}
                    name="newPassword"
                    required
                    fullWidth
                    id="newPassword"
                    label="New Password"
                    error={!!errors.newPassword}
                    helperText={errors.newPassword}
                    type={showPasswordNew ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <Button
                          onClick={handleTogglePasswordNew}
                          tabIndex={-1}
                          sx={{ padding: 0 }}
                        >
                          {showPasswordNew ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </Button>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    // autoFocus={changePassword}
                    value={passwordFields.confirmPassword}
                    onChange={handlePasswordInputChange}
                    name="confirmPassword"
                    required
                    fullWidth
                    id="confirmPassword"
                    label="Confirm New Password"
                    type={showPasswordConfirm ? "text" : "password"}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    InputProps={{
                      endAdornment: (
                        <Button
                          onClick={handleTogglePasswordConfirm}
                          tabIndex={-1}
                          sx={{ padding: 0 }}
                        >
                          {showPasswordConfirm ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </Button>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      onClick={handlePasswordSaveClick}
                      variant="contained"
                      sx={{ mr: 1 }}
                    >
                      Save
                    </Button>
                    <Button onClick={handlePasswordCancelClick} sx={{ ml: 1 }}>
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Button variant="contained" onClick={handlePasswordChangeClick}>
                Change Password
              </Button>
            )}
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: isDarkMode ? "#080808" : "#EEF3F7",
          position: "fixed",
          bottom: 0,
          width: "100%",
          padding: 2,
          textAlign: "center",
          height: 50,
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: isDarkMode ? "#FFF" : "#142764" }}
        >
          &copy; copyright {new Date().getFullYear()}: Udyat Technologies
        </Typography>
      </Box>
    </Box>
  );
}
