import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import axios from "axios";
import {
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { AutoAwesomeOutlined, Restore } from "@mui/icons-material";
import { keyframes } from "@emotion/react";

const steps = [
  "Job Details",
  "Other Details",
  "Job Description",
  "Features",
  "Initial Screening Questions",
  // "Technical Screening Questions",
  "Complete!",
];
const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(26, 117, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(26, 117, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(26, 117, 255, 0);
  }
`;
export default function Step3({
  handleBack,
  handleNext,
  formData,
  setFormData,
}) {
  const [jobDescriptionText, setJobDescriptionText] = useState(
    formData.jobDescription || ""
  );
  const [keyResponsibilityText, setKeyResponsibilityText] = useState(
    formData.KeyResponsibility || ""
  );
  const [animationIndexJD, setAnimationIndexJD] = useState(0);
  const [animationIndexKR, setAnimationIndexKR] = useState(0);
  const [generatedJD, setGeneratedJD] = useState("");
  const [generatedKR, setGeneratedKR] = useState("");
  const [isGeneratingJD, setIsGeneratingJD] = useState(false);
  const [isGeneratingKR, setIsGeneratingKR] = useState(false);
  const [previousJD, setPreviousJD] = useState("");
  const [previousKR, setPreviousKR] = useState("");
  const [showRestoreJD, setShowRestoreJD] = useState(false);
  const [showRestoreKR, setShowRestoreKR] = useState(false);
  const [isAnimatingJD, setIsAnimatingJD] = useState(false);
  const [isAnimatingKR, setIsAnimatingKR] = useState(false);
  const [jobDescriptionCharCount, setJobDescriptionCharCount] = useState(0);
  const [keyResponsibilityCharCount, setKeyResponsibilityCharCount] =
    useState(0);

  const [activeStep, setActiveStep] = useState(2);
  const [ResponseData, setResponseData] = useState("");
  const [KeyResponseData, setKeyResponseData] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [errors, setErrors] = useState({
    jobDescription: false,
    keyResponsibilities: false,
  });

  const [jobDescriptionEffectRun, setJobDescriptionEffectRun] = useState(false);
  const [keyResponsibilityEffectRun, setKeyResponsibilityEffectRun] =
    useState(false);
  const [index, setIndex] = useState(0);
  const [jobindex, setjobIndex] = useState(0);

  const token = localStorage.getItem("idToken");
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const generateText = (type) => {
    const isJD = type === "jobDescription";
    const setIsGenerating = isJD ? setIsGeneratingJD : setIsGeneratingKR;
    const setPrevious = isJD ? setPreviousJD : setPreviousKR;
    const setShowRestore = isJD ? setShowRestoreJD : setShowRestoreKR;
    const setGenerated = isJD ? setGeneratedJD : setGeneratedKR;
    const setText = isJD ? setJobDescriptionText : setKeyResponsibilityText;
    const currentText = isJD ? jobDescriptionText : keyResponsibilityText;

    setIsGenerating(true);
    setPrevious(currentText);
    setShowRestore(true);

    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    const apiUrl = `${baseUrl}/job/${
      isJD ? "create_job_description" : "create_key_responsiblities"
    }`;
    const postData = {
      job_title: formData.jobTitle,
      required_skills: formData.skills,
      required_exp: `${formData.minExp}-${formData.maxExp}`,
      required_qual: formData.qualification,
    };

    axios
      .post(apiUrl, postData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = isJD
          ? response.data.Response
          : response.data.Key_Responsiblities;
        setGenerated(data);
        setText("");
        isJD ? setAnimationIndexJD(0) : setAnimationIndexKR(0);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  const restorePreviousText = (type) => {
    if (type === "jobDescription") {
      setJobDescriptionText(previousJD);
      setShowRestoreJD(false);
    } else {
      setKeyResponsibilityText(previousKR);
      setShowRestoreKR(false);
    }
  };

  useEffect(() => {
    if (generatedJD && animationIndexJD < generatedJD.length) {
      setIsAnimatingJD(true);
      const timer = setTimeout(() => {
        setJobDescriptionText(
          (prevText) => prevText + generatedJD[animationIndexJD]
        );
        setAnimationIndexJD((prevIndex) => prevIndex + 1);
      }, 20);
      return () => clearTimeout(timer);
    } else if (animationIndexJD === generatedJD.length) {
      setGeneratedJD("");
      setIsAnimatingJD(false);
    }
  }, [generatedJD, animationIndexJD]);

  useEffect(() => {
    if (generatedKR && animationIndexKR < generatedKR.length) {
      setIsAnimatingKR(true);
      const timer = setTimeout(() => {
        setKeyResponsibilityText(
          (prevText) => prevText + generatedKR[animationIndexKR]
        );
        setAnimationIndexKR((prevIndex) => prevIndex + 1);
      }, 20);
      return () => clearTimeout(timer);
    } else if (animationIndexKR === generatedKR.length) {
      setGeneratedKR("");
      setIsAnimatingKR(false);
    }
  }, [generatedKR, animationIndexKR]);

  const handleTextChange = (event, type) => {
    const text = event.target.value;
    const count = text.length;
    if (count <= 1000) {
      if (type === "jobDescription") {
        setJobDescriptionText(text);
        setJobDescriptionCharCount(count);
        setErrors({ ...errors, jobDescription: false });
      } else {
        setKeyResponsibilityText(text);
        setKeyResponsibilityCharCount(count);
        setErrors({ ...errors, keyResponsibilities: false });
      }
    }
  };
  const validateFields = () => {
    const newErrors = {
      jobDescription: "",
      keyResponsibilities: "",
    };

    // Check if job description is empty
    if (jobDescriptionText.trim() === "") {
      newErrors.jobDescription = "Job Description cannot be empty.";
    }

    // Check if key responsibilities are empty
    if (keyResponsibilityText.trim() === "") {
      newErrors.keyResponsibilities = "Key Responsibilities cannot be empty.";
    }

    setErrors(newErrors);

    // Return true if no errors are found, false otherwise
    return !newErrors.jobDescription && !newErrors.keyResponsibilities;
  };

  // useEffect(() => {
  //   setJobDescriptionCharCount(jobDescriptionText.length);
  //   setKeyResponsibilityCharCount(keyResponsibilityText.length);
  // }, [jobDescriptionText, keyResponsibilityText]);

  const handleNextStep = () => {
    if (validateFields()) {
      setFormData({
        ...formData,
        jobDescription: jobDescriptionText,
        KeyResponsibility: keyResponsibilityText,
      });
      handleNext();
    }
  };

  const handleConfirmCancel = () => {
    // Add your cancel logic here
    window.location.href = "/dashboard";
  };
  const handleCancel = () => {
    setOpenDialog(true);
    // Navigate to the dashboard page
  };
  return (
    <Box sx={{ width: "100%", marginTop: 2 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Typography
        sx={{
          fontSize: "32px",
          color: "#142764",
          marginTop: "15px",
          textAlign: "center",
        }}
      >
        Job Description
      </Typography>
      <Box
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 20,
            marginTop: 20,
          }}
        >
          <FormControl
            variant="outlined"
            sx={{ marginBottom: 2, width: "700px" }}
          >
            <TextField
              id="job-description"
              label="Job Description"
              InputLabelProps={{ shrink: true }}
              multiline
              onChange={(e) => handleTextChange(e, "jobDescription")}
              rowsMax={30}
              minRows={5}
              variant="outlined"
              inputProps={{ maxLength: 1000 }}
              value={jobDescriptionText}
              error={!!errors.jobDescription} // Check if there's an error
              helperText={errors.jobDescription} // Display error message
              sx={{
                "& .MuiInputBase-input": {
                  textAlign: "justify",
                },
              }}
              placeholder={
                isGeneratingJD
                  ? "Generating..."
                  : "Enter job description manually or use AI to generate"
              }
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
              <Tooltip
                title={
                  <Typography variant="body2">
                    {showRestoreJD
                      ? "Click to restore your previous manually entered text"
                      : "Click to generate AI-suggested job description"}
                  </Typography>
                }
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#F8F8FF",
                      color: "black",
                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      padding: "8px 12px",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "darkblue", // Match arrow color with tooltip background
                      boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                    },
                  },
                }}
                arrow
                placement="top"
              >
                <span>
                  <Button
                    variant="contained"
                    onClick={
                      showRestoreJD
                        ? () => restorePreviousText("jobDescription")
                        : () => generateText("jobDescription")
                    }
                    disabled={
                      isGeneratingJD || (showRestoreJD && isAnimatingJD)
                    }
                    startIcon={
                      isGeneratingJD ? (
                        <CircularProgress size={16} color="inherit" />
                      ) : showRestoreJD ? (
                        <Restore />
                      ) : (
                        <AutoAwesomeOutlined />
                      )
                    }
                    sx={{
                      minWidth: "auto",
                      padding: "6px 12px",
                      bgcolor: showRestoreJD ? "#ff9800" : "#142764",
                      "&:hover": {
                        bgcolor: showRestoreJD ? "#f57c00" : "#1a75ff",
                      },
                      fontSize: "0.75rem",
                      fontWeight: "bold",
                      textTransform: "none",
                      borderRadius: "20px",
                      animation: `${pulseAnimation} 2s infinite`,
                      transition: "all 0.3s ease",
                    }}
                  >
                    {isGeneratingJD
                      ? "Generating"
                      : showRestoreJD
                      ? "Revert"
                      : "AI Generate"}
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </FormControl>
          <Tooltip
            title={
              <Typography variant="body2">
                Enter job description manually or use the AI button to generate
                description for this job. You can edit the generated description as needed
                or restore your previous input.
              </Typography>
            }
            arrow
            placement="right"
            sx={{ cursor: "pointer", ml: 1 }}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#F8F8FF",
                  color: "black",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  padding: "8px 12px",
                },
              },
              arrow: {
                sx: {
                  color: "darkblue", // Match arrow color with tooltip background
                  boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                },
              },
            }}
          >
            <HelpOutlineIcon
              fontSize="small"
              sx={{
                color: "#142764",
                cursor: "pointer",
                transition: "all 0.3s ease",
                mt: -5,
                "&:hover": {
                  transform: "scale(1.1)",
                  color: "#1a75ff",
                },
              }}
            />
          </Tooltip>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 20,
            marginTop: 20,
          }}
        >
          <FormControl
            variant="outlined"
            sx={{ marginBottom: 2, width: "700px" }}
          >
            <TextField
              id="key-responsibilities"
              label="Key Responsibilities"
              InputLabelProps={{ shrink: true }}
              multiline
              onChange={(e) => handleTextChange(e, "keyResponsibilities")}
              minRows={5}
              rowsMax={30}
              variant="outlined"
              inputProps={{ maxLength: 1000 }}
              value={keyResponsibilityText}
              sx={{
                "& .MuiInputBase-input": {
                  textAlign: "justify",
                },
              }}
              error={!!errors.keyResponsibilities} // Check if there's an error
              helperText={errors.keyResponsibilities}
              placeholder={
                isGeneratingKR
                  ? "Generating..."
                  : "Enter key responsibilities manually or use AI to generate"
              }
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
              <Tooltip
                title={
                  <Typography variant="body2">
                    {showRestoreKR
                      ? "Click to restore your previous manually entered text"
                      : "Click to generate AI-suggested key responsibilities"}
                  </Typography>
                }
                arrow
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#F8F8FF",
                      color: "black",
                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      padding: "8px 12px",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "darkblue", // Match arrow color with tooltip background
                      boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                    },
                  },
                }}
              >
                <span>
                  <Button
                    variant="contained"
                    onClick={
                      showRestoreKR
                        ? () => restorePreviousText("keyResponsibilities")
                        : () => generateText("keyResponsibilities")
                    }
                    disabled={
                      isGeneratingKR || (showRestoreKR && isAnimatingKR)
                    }
                    startIcon={
                      isGeneratingKR ? (
                        <CircularProgress size={16} color="inherit" />
                      ) : showRestoreKR ? (
                        <Restore />
                      ) : (
                        <AutoAwesomeOutlined />
                      )
                    }
                    sx={{
                      minWidth: "auto",
                      padding: "6px 12px",
                      bgcolor: showRestoreKR ? "#ff9800" : "#142764",
                      "&:hover": {
                        bgcolor: showRestoreKR ? "#f57c00" : "#1a75ff",
                      },
                      fontSize: "0.75rem",
                      fontWeight: "bold",
                      textTransform: "none",
                      borderRadius: "20px",
                      animation: `${pulseAnimation} 2s infinite`,
                      transition: "all 0.3s ease",
                    }}
                  >
                    {isGeneratingKR
                      ? "Generating"
                      : showRestoreKR
                      ? "Revert"
                      : "AI Generate"}
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </FormControl>
          <Tooltip
            title={
              <Typography variant="body2">
                <Typography variant="body2">
                Enter key responsibility manually or use the AI button to generate
                responsibilities for this job. You can edit the generated key responsibilities as needed
                or restore your previous input.
              </Typography>
              </Typography>
            }
            arrow
            placement="right"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#F8F8FF",
                  color: "black",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  padding: "8px 12px",
                },
              },
              arrow: {
                sx: {
                  color: "darkblue", // Match arrow color with tooltip background
                  boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                },
              },
            }}
            sx={{ cursor: "pointer", ml: 1 }}
          >
            <HelpOutlineIcon
              fontSize="small"
              sx={{
                color: "#142764",
                cursor: "pointer",
                transition: "all 0.3s ease",
                mt: -5,
                "&:hover": {
                  transform: "scale(1.1)",
                  color: "#1a75ff",
                },
              }}
            />
          </Tooltip>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginRight: 5,
          gap: {
            xs: "50px",
            sm: "100px",
            md: "450px",
          },
          marginBottom: 2,
        }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={handleCancel}
          sx={{ mr: 1 }}
        >
          Cancel
        </Button>
        <div>
          <Button variant="outlined" onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
          <Button variant="contained" onClick={handleNextStep}>
            Next
          </Button>
        </div>
      </Box>
      {/* </Box> */}
      {/* </Box> */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 0,
            pl: 3,
            mb: -2,
          }}
        >
          <WarningIcon color="warning" sx={{ marginRight: -2 }} />
          <DialogTitle>Are you sure?</DialogTitle>
        </Box>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel the job creation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseDialog}
            color="primary"
          >
            cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmCancel}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
