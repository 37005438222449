// awsConfig.js

import { CognitoIdentityProviderClient } from "@aws-sdk/client-cognito-identity-provider";
import { S3Client } from "@aws-sdk/client-s3";

const config = {
  region: process.env.REACT_APP_AWS_REGION,
  // region:'ap-south-1',
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey:process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
};

// const client = new CognitoIdentityProviderClient(config);

const cognitoIdentityServiceProvider = new CognitoIdentityProviderClient(config);
const s3 = new S3Client(config);

const client = {
  cognitoIdentityServiceProvider,
  s3,
};

export default client;