import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import axios from "axios";
import WarningIcon from "@mui/icons-material/Warning";
import FormHelperText from "@mui/material/FormHelperText";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { keyframes } from "@emotion/react";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import { Restore } from "@mui/icons-material";
const steps = [
  "Job Details",
  "Other Details",
  "Job Description",
  "Features",
  "Initial Screening Questions",
  // "Technical Screening Questions",
  "Complete!",
];
export default function Step2({
  handleBack,
  handleNext,
  formData,
  setFormData,
}) {
  const [response, setResponse] = useState("");
  const [activeStep, setActiveStep] = useState(1);
  const [qualification, setQualification] = useState(
    formData.qualification || ""
  );
  const [error, setError] = useState(null);

  // const [displayedText, setDisplayedText] = useState('');
  const [vacancy, setVacancy] = useState(formData.vacancy || "");
  const [minExp, setMinExp] = useState(formData.minExp || 0);
  const [maxExp, setMaxExp] = useState(formData.maxExp || 0);
  const [minExpError, setMinExpError] = useState("");
  const [maxExpError, setMaxExpError] = useState("");
  const [jobCategory, setJobCategory] = useState(
    formData.jobCategory || "IT Technical"
  );
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [index, setIndex] = useState(0);
  const [displayText, setDisplayText] = useState(formData.skills || "");
  const [vacancyError, setVacancyError] = useState("");
  const [qualificationError, setQualificationError] = useState("");
  const [jobCategoryError, setJobCategoryError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [previousText, setPreviousText] = useState("");
  const [showRestore, setShowRestore] = useState(false);
  const [animatedText, setAnimatedText] = useState("");
  const [isAnimating, setIsAnimating] = useState(false);
  const [generatedText, setGeneratedText] = useState("");
  const [animationIndex, setAnimationIndex] = useState(0);
  const handleConfirmCancel = () => {
    // Add your cancel logic here
    window.location.href = "/dashboard";
  };
  const min = [0, 1, 2, 3, 4, 5, 6, 7, 8];

  const max = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const handleOnChange = (event, value) => {
    setMaxExp(value); // Update state with selected value
  };
  const handleCancel = () => {
    setOpenDialog(true);
    // Navigate to the dashboard page
  };
  const handleQualificationChange = (event) => {
    const value = event.target.value;

    // Validate if a qualification is selected
    if (!value) {
      setQualificationError("Please select a qualification");
    } else {
      setQualificationError("");
      setQualification(value);
    }
  };
  console.log("data...", qualification, vacancy, minExp, maxExp, jobCategory);

  const token = localStorage.getItem("idToken");

  const handleVacancyChange = (event) => {
    const value = event.target.value;

    // Validate if value is a positive integer
    if (!Number.isInteger(Number(value)) || Number(value) < 0) {
      setVacancyError("Please enter a valid positive integer");
    } else {
      setVacancyError("");
      setVacancy(value);
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleNextStep = () => {
    if (!jobCategory) {
      setJobCategoryError("Job Category is required");
      return;
    }
    if (!vacancy) {
      setVacancyError("Number of Vacancies is required");
      return;
    }
    if (!qualification) {
      setQualificationError("Qualification is required");
      return;
    }
    // if (!minExp) {
    //   setMinExpError("Minimum Exp is required");
    //   return;
    // }

    if (!maxExp && !maxExp === 0) {
      setMaxExpError("Maximum Exp is required");
      return;
    }

    if (parseInt(minExp) > parseInt(maxExp)) {
      setMinExpError("Min Exp should be less than Max Exp");
      return;
    }
    setFormData({
      ...formData,
      qualification: qualification,
      vacancy: vacancy,
      minExp: minExp,
      maxExp: maxExp,
      jobCategory: jobCategory,
      skills: displayText,
    });
    handleNext(); // Proceed to the next step
  };
  // useEffect(() => {
  //   if (formData.skills) {
  //     // If already populated, return early to skip further execution
  //     console.log("..skills render");
  //     return;
  //   }
  //   console.log("...yes no return");
  //   const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
  //   const apiUrl = `${baseUrl}/job/create_skills`;
  //   const postbody = {
  //     job_title: formData.jobTitle,
  //   };

  //   let isMounted = true; // Flag to track component mount status

  //   axios
  //     .post(apiUrl, postbody, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((response) => {
  //       if (isMounted) {
  //         // Check if the component is still mounted
  //         console.log("response skills...", response.data.Skills);
  //         setResponse(response.data.Skills);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });

  //   // Cleanup function to update component mount status
  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);
  const generateSkills = () => {
    setIsGenerating(true);
    setPreviousText(displayText);
    setShowRestore(true);
    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    const apiUrl = `${baseUrl}/job/create_skills`;
    const postbody = {
      job_title: formData.jobTitle,
    };

    axios
      .post(apiUrl, postbody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setGeneratedText(response.data.Skills);
        setDisplayText("");
        setAnimationIndex(0);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  const restorePreviousText = () => {
    setDisplayText(previousText);
    setShowRestore(false);
  };

  useEffect(() => {
    if (generatedText && animationIndex < generatedText.length) {
      const timer = setTimeout(() => {
        setDisplayText((prevText) => prevText + generatedText[animationIndex]);
        setAnimationIndex((prevIndex) => prevIndex + 1);
      }, 20);

      return () => clearTimeout(timer);
    } else if (animationIndex === generatedText.length) {
      setGeneratedText("");
    }
  }, [generatedText, animationIndex]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      skills: displayText,
    }));
  }, [displayText, setFormData]);

  const handleManualInput = (manualInput) => {
    setDisplayText((prevText) => prevText + manualInput);
  };
  const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(26, 117, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(26, 117, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(26, 117, 255, 0);
  }
`;
  useEffect(() => {
    console.log("..skill response..", response);
    console.log("..skill response length..", response.length);
    if (!response || response.length === 0) return;
    const intervalId = setInterval(() => {
      if (index < response.length) {
        setDisplayText((prevText) => prevText + response[index]);
        setIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(intervalId);
      }
    }, 20);

    return () => {
      clearInterval(intervalId);
    };
  }, [index, response]);

  console.log("...displayedText...", displayText);
  useEffect(() => {
    if (isAnimating && animatedText) {
      let i = 0;
      const typingInterval = setInterval(() => {
        if (i < animatedText.length) {
          setDisplayText((prev) => prev + animatedText.charAt(i));
          i++;
        } else {
          clearInterval(typingInterval);
          setIsAnimating(false);
          setShowRestore(true);
        }
      }, 20);

      return () => clearInterval(typingInterval);
    }
  }, [isAnimating, animatedText]);

  return (
    <Box sx={{ width: "100%", marginTop: 2 }}>
      <Stepper activeStep={1} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Typography
        sx={{
          fontSize: "25px",
          color: "#142764",
          marginTop: "15px",
          textAlign: "center",
        }}
      >
        Add more details
      </Typography>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: 20,
          marginTop: 20,
          alignItems: "center",
        }}
      >
        <TextField
          id="outlined-number"
          label="Number of Vacancies"
          type="number"
          value={vacancy}
          onChange={handleVacancyChange}
          sx={{ width: 400 }}
          error={Boolean(vacancyError)}
          helperText={vacancyError}
        />
        <Tooltip
          title={
            <Typography variant="body2">
              Enter the total number of vacancies available for this job.
            </Typography>
          }
          arrow
          placement="right"
          sx={{ cursor: "pointer", ml: 1 }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#F8F8FF",
                color: "black",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                padding: "8px 12px",
              },
            },
            arrow: {
              sx: {
                color: "darkblue", // Match arrow color with tooltip background
                boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
              },
            },
          }}
        >
          <HelpOutlineIcon
            fontSize="small"
            sx={{
              color: "#142764",
              cursor: "pointer",
              transition: "all 0.3s ease",
              "&:hover": {
                transform: "scale(1.1)",
                color: "#1a75ff",
              },
            }}
          />
        </Tooltip>
      </Box>
      <Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 20,
            marginTop: 20,
          }}
        >
          <FormControl sx={{ minWidth: 400 }}>
            <InputLabel id="demo-simple-select-label">Qualification</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={qualification}
              label="Qualification"
              onChange={handleQualificationChange}
              error={Boolean(qualificationError)}
              helperText={qualificationError}
            >
              <MenuItem value="Bachelor's Degree">Bachelor's Degree</MenuItem>
              <MenuItem value="Masters Degree">Masters Degree</MenuItem>
              <MenuItem value=" Post Masters Degree">
                Post Masters Degree
              </MenuItem>
              <MenuItem value="PHD">PHD</MenuItem>
              <MenuItem value="Non-Bachelor Degree">
                Non-Bachelor Degree
              </MenuItem>
            </Select>
            {qualificationError && (
              <Typography variant="caption" color="error">
                {qualificationError}
              </Typography>
            )}
          </FormControl>
          <Tooltip
            title={
              <Typography variant="body2">
                Select the qualifications required for this position
              </Typography>
            }
            arrow
            placement="right"
            sx={{ cursor: "pointer", ml: 1 }}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#F8F8FF",
                  color: "black",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  padding: "8px 12px",
                },
              },
              arrow: {
                sx: {
                  color: "darkblue", // Match arrow color with tooltip background
                  boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                },
              },
            }}
          >
            <HelpOutlineIcon
              fontSize="small"
              sx={{
                color: "#142764",
                cursor: "pointer",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "scale(1.1)",
                  color: "#1a75ff",
                },
              }}
            />
          </Tooltip>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 20,
            marginTop: 20,
          }}
        >
          <FormControl sx={{ m: 0, minWidth: 190 }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={min}
              onChange={(event, value) => setMinExp(value)} // Update state with selected value
              onInputChange={(event, value) => setMinExp(value)} // Update state with user input
              value={minExp}
              renderInput={(params) => (
                <>
                  <TextField
                    sx={{ height: 50 }}
                    {...params}
                    label="Minimum Exp"
                    placeholder="enter your exp"
                    error={Boolean(error)}
                  />
                  {error && <FormHelperText error>{error}</FormHelperText>}
                </>
              )}
            />
            {minExpError && (
              <Typography variant="caption" color="error">
                {minExpError}
              </Typography>
            )}
          </FormControl>
          <FormControl sx={{ m: 0, minWidth: 190 }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={max}
              onChange={handleOnChange} // Update state with selected value
              onInputChange={(event, value) => setMaxExp(value)} // Update state with user input
              value={maxExp}
              renderInput={(params) => (
                <>
                  <TextField
                    sx={{ height: 50 }}
                    {...params}
                    label="Maximum Exp"
                    placeholder="enter your exp"
                    error={Boolean(error)}
                  />
                  {error && <FormHelperText error>{error}</FormHelperText>}
                </>
              )}
            />
            {maxExpError && (
              <Typography variant="caption" color="error">
                {maxExpError}
              </Typography>
            )}
          </FormControl>
          <Tooltip
            title={
              <Typography variant="body2">
                Select the minimum and maximum experience required for this job
              </Typography>
            }
            arrow
            placement="right"
            sx={{ cursor: "pointer", ml: 1 }}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#F8F8FF",
                  color: "black",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  padding: "8px 12px",
                },
              },
              arrow: {
                sx: {
                  color: "darkblue", // Match arrow color with tooltip background
                  boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                },
              },
            }}
          >
            <HelpOutlineIcon
              fontSize="small"
              sx={{
                color: "#142764",
                cursor: "pointer",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "scale(1.1)",
                  color: "#1a75ff",
                },
              }}
            />
          </Tooltip>
        </Box>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 20,
          marginTop: 20,
        }}
      >
        <FormControl sx={{ m: 0, minWidth: 400 }}>
          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={jobCategory}
            label="Remote"
            onChange={(event) => setJobCategory(event.target.value)}
            InputLabelProps={{
              style: {
                width: "100px", // Adjust as needed
              },
            }}
          >
            <MenuItem value="IT Technical">IT Technical</MenuItem>
          </Select>
          {jobCategoryError && (
            <Typography variant="caption" color="error">
              {jobCategoryError}
            </Typography>
          )}
        </FormControl>
        <Tooltip
          title={
            <Typography variant="body2">
              Choose the category for this job
            </Typography>
          }
          arrow
          placement="right"
          sx={{ cursor: "pointer", ml: 1 }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#F8F8FF",
                color: "black",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                padding: "8px 12px",
              },
            },
            arrow: {
              sx: {
                color: "darkblue", // Match arrow color with tooltip background
                boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
              },
            },
          }}
        >
          <HelpOutlineIcon
            fontSize="small"
            sx={{
              color: "#142764",
              cursor: "pointer",
              transition: "all 0.3s ease",
              "&:hover": {
                transform: "scale(1.1)",
                color: "#1a75ff",
              },
            }}
          />
        </Tooltip>
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 20,
          marginTop: 20,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            m: 0,
            width: 400,
          }}
        >
          <Box sx={{ width: 400, position: "relative" }}>
            <TextField
              label="Skills"
              variant="outlined"
              multiline
              fullWidth
              value={displayText}
              onChange={(e) => {
                setDisplayText(e.target.value);
                setShowRestore(false); // Hide restore when manually editing
              }}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 1000 }}
              minRows={5}
              maxRows={10}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#142764",
                  },
                  "&:hover fieldset": {
                    borderColor: "#1a75ff",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#1a75ff",
                  },
                },
                "& .MuiInputBase-input": {
                  textAlign: "justify",
                },
              }}
              placeholder={
                isGenerating
                  ? "Generating skills..."
                  : "Enter skills manually or use AI to generate"
              }
            />
            <Tooltip
              title={
                <Typography variant="body2">
                  {showRestore
                    ? "Click to restore your previous manually entered skills"
                    : "Click to generate AI-suggested skills for this job"}
                </Typography>
              }
              arrow
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#F8F8FF",
                    color: "black",
                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    padding: "8px 12px",
                  },
                },
                arrow: {
                  sx: {
                    color: "darkblue", // Match arrow color with tooltip background
                    boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow to the arrow for depth
                  },
                },
              }}
            >
              <Button
                variant="contained"
                onClick={showRestore ? restorePreviousText : generateSkills}
                disabled={isGenerating || animationIndex < generatedText.length}
                startIcon={
                  isGenerating ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : showRestore ? (
                    <Restore />
                  ) : (
                    <AutoAwesomeOutlinedIcon />
                  )
                }
                sx={{
                  position: "absolute",
                  right: 8,
                  bottom: 8, // Adjusted to prevent overlapping with text
                  minWidth: "auto",
                  padding: "6px 12px", // Smaller padding for a smaller button size
                  bgcolor: showRestore ? "#ff9800" : "#142764",
                  "&:hover": {
                    bgcolor: showRestore ? "#f57c00" : "#1a75ff",
                    transform: "translateY(-2px)",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  },
                  fontSize: "0.75rem", // Smaller font size for smaller button
                  fontWeight: "bold",
                  textTransform: "none",
                  borderRadius: "20px",
                  transition: "all 0.3s ease",
                  animation: `${pulseAnimation} 2s infinite`,
                  "&:active": {
                    transform: "translateY(0)",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                {isGenerating
                  ? "Generating"
                  : showRestore
                  ? "Revert"
                  : "AI Generate"}
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <Tooltip
          title={
            <Typography variant="body2">
              Enter skills manually or use the AI button to generate skills for
              this job. You can edit the generated skills as needed or restore
              your previous input.
            </Typography>
          }
          arrow
          placement="right"
          sx={{ alignSelf: "flex-start", cursor: "pointer", ml: 1 }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#F8F8FF",
                color: "black",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                padding: "8px 12px",
              },
            },
            arrow: {
              sx: {
                color: "#F8F8FF",
                boxShadow: "-1px -1px 4px rgba(0, 0, 0, 0.1)",
              },
            },
          }}
        >
          <HelpOutlineIcon
            fontSize="small"
            sx={{
              color: "#142764",
              cursor: "pointer",
              transition: "all 0.3s ease",
              "&:hover": {
                transform: "scale(1.1)",
                color: "#1a75ff",
              },
            }}
          />
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "360px", // Match the width of the fields above
          margin: "20px auto", // Center the button group
          // gap:10,
        }}
      >
        <Button
          sx={{ marginLeft: "-40px" }}
          variant="outlined"
          color="error"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Box
          sx={{ display: "flex", justifyContent: "space-around", gap: "15px" }}
        >
          <Button variant="outlined" onClick={handleBack} sx={{ mr: 0 }}>
            Back
          </Button>
          <Button variant="contained" onClick={handleNextStep}>
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </Box>
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 0,
            pl: 3,
            mb: -2,
          }}
        >
          <WarningIcon color="warning" sx={{ marginRight: -2 }} />
          <DialogTitle>Are you sure?</DialogTitle>
        </Box>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel the job creation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseDialog}
            color="primary"
          >
            cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmCancel}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
