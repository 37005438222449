import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";

function Thankupage() {
  const location = useLocation();
  const { email } = location.state || {};
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 10000); // 10 seconds

    return () => clearTimeout(timer); // Cleanup the timer
  }, [navigate]);

  const iconStyle = {
    fontSize: 48,
    marginBottom: 8,
  };

  const textStyle = {
    marginBottom: 8,
    color: "#363640",
    fontWeight: "bold",
  };

  const rootStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    gap: 20,
  };

  return (
    <div style={rootStyle}>
      <VerifiedOutlinedIcon color="success" sx={{ fontSize: "60px" }} />
      <Typography variant="h4" gutterBottom style={textStyle}>
        Thanks for registering!
      </Typography>
      <Typography variant="h6" style={textStyle}>
        Verify your email address
      </Typography>
      <Typography variant="h6" style={{ color: "#363640" }}>
        We have sent a confirmation email to:{" "}
        <strong style={{ color: "#363640", fontSize: "20px" }}>
          {email}
        </strong>
      </Typography>
      <Typography variant="h6" style={{ color: "#363640" }}>
        Check your email and click on the confirmation link to continue
      </Typography>
      <Typography variant="body2" style={{ marginTop: 20 }}>
        You will be redirected to the login page in 10 seconds.
      </Typography>
    </div>
  );
}

export default Thankupage;