import React, { useContext, useEffect, useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import FinalStep from "./FinalStep";

import { CognitoAccessToken } from "amazon-cognito-identity-js"; // Import CognitoAccessToken from appropriate package

import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import Step3 from "./Step3";
// Import other steps as needed
import dayjs from "dayjs";
export default function StepperMain() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [callScheduleDate, setCallScheduleDate] = useState("");
  const [targetDate, setTargetDate] = useState("");
  const [codingStartDate, setCodingStartDate] = useState("");
  const [codingEndDate, setCodingEndDate] = useState("");
  const [formData, setFormData] = useState({});
  console.log("... main formData...", formData);
  const client_data = localStorage.getItem("client_id");
  const [technicalCheckedParent, setTechnicalCheckedParent] = useState(false);

  const navigate = useNavigate();

  const refreshToken = localStorage.getItem("refreshToken");
  const handleAccessToken = () => {
    const accessToken = localStorage.getItem("accessToken");

    const navigateAndReturn = (path) => {
      navigate(path);
      return false;
    };

    if (!accessToken || !checkAccessTokenValidity(accessToken)) {
      // console.log("...to redirect on login")
      localStorage.setItem("logedIn", false);
      return navigateAndReturn("/");
    } else {
      // console.log("...to redirect on dashboard")
      // refreshtokenentry()
      checkScreenActivity();
      return navigateAndReturn("/create-job");
    }
    // console.log("...dashboard return ...");
  };

  const checkAccessTokenValidity = (accessToken) => {
    try {
      const decodedToken = new CognitoAccessToken({ AccessToken: accessToken });

      const expirationTime = decodedToken.getExpiration();

      const currentTime = Math.floor(Date.now() / 1000);

      if (expirationTime > currentTime) {
        // console.log("Expiration time is greater than current time. Triggering useEffect logic.");
        return expirationTime > currentTime;
      }
    } catch (error) {
      console.error("Error decoding token or missing expiration time:", error);
      return false;
    }
  };

  const refreshtokenentry = () => {
    // console.log("'...token checking...")
    const region = process.env.REACT_APP_AWS_REGION;
    const apiUrl = `https://cognito-idp.${region}.amazonaws.com/`;
    const requestBody = {
      AuthFlow: "REFRESH_TOKEN",
      ClientId: process.env.REACT_APP_CLIENT_ID_USER,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    };
    const headers = {
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      "Content-Type": "application/x-amz-json-1.1",
    };
    axios
      .post(apiUrl, requestBody, { headers })
      .then((response) => {
        // Handle the response as needed
        // console.log(".....refresh response...", response.data);
      })
      .catch((error) => {
        // Handle errors
        // console.error("Error in Axios refresh POST request:", error);
      });
  };

  const checkScreenActivity = () => {
    let isActive = false;

    const handleMove = () => {
      isActive = true;
    };

    window.addEventListener("mousemove", handleMove);

    // You can add more event listeners like 'keydown' or 'touchmove' if needed
    // window.addEventListener('keydown', handleMove);
    // window.addEventListener('touchmove', handleMove);

    setTimeout(() => {
      window.removeEventListener("mousemove", handleMove);
      // Remove other event listeners if added
      // window.removeEventListener('keydown', handleMove);
      // window.removeEventListener('touchmove', handleMove);

      // console.log(isActive ? 'Yes' : 'No');
      if (isActive) {
        // console.log("....yess...")
        refreshtokenentry();
      } else {
        //  console.log("...no...")
      }
    }, 3000); // Adjust the timeout duration as needed (3000 milliseconds = 3 seconds)
  };
  // const handleTechnicalChecked = (event) => {
  //   setTechnicalChecked(event.target.checked);
  // };

  useEffect(() => {
    handleAccessToken();
    const timeoutId = setTimeout(() => {
      console.log("...trigger after 2 min");
      handleAccessToken();
    }, 55 * 60 * 1000);

    const foraccessToken = setTimeout(() => {
      console.log("...trigger after 5 min");
      // toast.error("Session expired");
      handleAccessToken();
    }, 60 * 60 * 1000);
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(foraccessToken);
    };
  }, []);

  // const screening_questionsdata = formData.screening_questions
  // const addition_screeningQuestion = formData.additionScreeing_Question
  // console.log("additional_Question...",addition_screeningQuestion)

  const token = localStorage.getItem("idToken");
  // address: `${formData.buildingaddress} ${formData.pincode}`,.
  // console.log("...main screening questions....",formData.screening_questions)
  // console.log("....main additional questions...",formData.additionScreeing_Question)
  // const addingQuestion = formData.screening_questions.concat(formData.additionScreeing_Question)
  //    const data = JSON.stringify(addingQuestion)
  //    console.log("...data..",data)

  const handleAPICall = async () => {
    console.log("...call Schedule ...", formData.call_schedule);
    const callSchedule = formData.call_schedule; // Assuming formData.call_schedule contains the Date object
    if (dayjs(callSchedule).isValid()) {
      // Use Day.js to format the date
      const extractedDate = dayjs(callSchedule).format("YYYY-MM-DD");
      setCallScheduleDate(extractedDate);
      console.log("Extracted Date:", extractedDate); // Output: Apr 09 2024
    } else {
      console.log("formData.call_schedule is not a valid date.");
    }
    const targetHiring = formData.target_hiring_date; // Assuming formData.call_schedule contains the Date object
    if (dayjs(targetHiring).isValid()) {
      // Use Day.js to format the date
      const extractedTargetDate = dayjs(callSchedule).format("YYYY-MM-DD");
      setTargetDate(extractedTargetDate);
      console.log("Extracted Date:", extractedTargetDate); // Output: Apr 09 2024
    } else {
      console.log("formData.call_schedule is not a valid date.");
    }
    const codingAssessment = formData.coding_assessment;

if (codingAssessment!==null) {
  const codingStart = codingAssessment.coding_start_date;
  if (codingStart && dayjs(codingStart).isValid()) {
    // Use Day.js to format the date
    const extractedCodingStartDate = dayjs(codingStart).format("YYYY-MM-DD");
    setCodingStartDate(extractedCodingStartDate);
    console.log("Extracted Start Date:", extractedCodingStartDate);
  } else {
    console.log("formData.coding_start_date is not a valid date or is missing.");
  }

  const codingEnd = codingAssessment.coding_end_date;
  if (codingEnd && dayjs(codingEnd).isValid()) {
    // Use Day.js to format the date
    const extractedCodingEndDate = dayjs(codingEnd).format("YYYY-MM-DD");
    setCodingEndDate(extractedCodingEndDate);
    console.log("Extracted End Date:", extractedCodingEndDate);
  } else {
    console.log("formData.coding_end_date is not a valid date or is missing.");
  }
} else {
  console.log("formData.coding_assessment is null or undefined.");
}



    const weekDays = JSON.stringify(formData.week_range);
    // const dealbreaker = JSON.stringify(formData.deal_breaker_question);

    const normalQuestions = formData.normal_questions
      ? formData.normal_questions.map((question) => question.question)
      : [];
    const normalQuestions1 = JSON.stringify(normalQuestions);

    const normal_questionsbreaker = JSON.stringify(formData.normal_questions);
    console.log("...normal_questionsBreake...", normal_questionsbreaker);

    const screeningQuestions = formData.screening_questions || [];
    const additionScreeingQuestions = formData.additionScreeing_Question || [];
    const addingQuestion = screeningQuestions.concat(additionScreeingQuestions);
    const data = JSON.stringify(addingQuestion);
    const technicalData = JSON.stringify(normal_questionsbreaker);
    console.log("...data..", data);
    const formattedDate = callSchedule && dayjs(callSchedule).isValid() ? dayjs(callSchedule).format("YYYY-MM-DD") : "None";
    const formattedHiringDate = targetHiring && dayjs(targetHiring).isValid() ? dayjs(targetHiring).format("YYYY-MM-DD") : "None";

    const formattedCodingStartDate = codingAssessment?.coding_start_date && dayjs(codingAssessment.coding_start_date).isValid() 
    ? dayjs(codingAssessment.coding_start_date).format("YYYY-MM-DD") 
    : "None";

  const formattedCodingEndDate = codingAssessment?.coding_end_date && dayjs(codingAssessment.coding_end_date).isValid() 
    ? dayjs(codingAssessment.coding_end_date).format("YYYY-MM-DD") 
    : "None";
    console.log("formattedStartDate:", formattedCodingStartDate);
    const perEndTime = dayjs(formData.perday_end_time).format("HH:mm");
    const perStartTime = dayjs(formData.perday_start_time).format("HH:mm");
    console.log("...normal_questionsBreake...", normal_questionsbreaker);

    try {
      const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
      const response = await axios.post(
        `${baseUrl}/job/create_job`,
        {
          client_id: client_data,
          job_title: formData.jobTitle || "Not Available",
          address: formData.streetAddress || "Not Available",
          country: formData.country || "Not Available",
          schedule: "Day Shift",
          number_of_vacancies: formData.vacancy || "Not Available",
          skills: formData.skills || "Not Available",
          qualification: formData.qualification || "Not Available",
          experience: `${formData.minExp} - ${formData.maxExp}` || "Not Available",
          start_date: formattedDate,
          target_hiring_date: formattedHiringDate,
          week_range: JSON.stringify(formData.week_range) || "Not Available",
          perday_start_time: formData.perday_start_time ? dayjs(formData.perday_start_time).format("HH:mm") : "None",
          perday_end_time: formData.perday_end_time ? dayjs(formData.perday_end_time).format("HH:mm") : "None",
          rankwithlocation: formData.checked || "Not Available",
          job_category: formData.jobCategory || "Not Available",
          job_description: formData.jobDescription || "Not Available",
          key_responsiblities: formData.KeyResponsibility || "Not Available",
          normal_questions: formData.normal_questions ? JSON.stringify(formData.normal_questions.map((question) => question.question)) : "Not Available",
          job_type: formData.jobType || "Not Available",
          job_site: formData.location,
          job_state: formData.state,
          job_city: formData.city || "Not Available",
          screening_questions: data || "Not Available",
          technical_call: formData.technical_call,
          screening_call: formData.screening_call,
          salary_range: `${formData.minSalary} - ${formData.maxSalary}` || "Not Available",
          hourly_price: `${formData.minHourlyPrice} - ${formData.maxHourlyPrice}` || "Not Available",
          remote_options: formData.remoteOption || "Not Available",
          skip_holidays: formData.skipHoliday || 0,
          aptitude_assessment_check: formData.aptitude_checked,
          coding_assessment_check: formData.coding_assessment?.coding_check,
          coding_assessment_language: formData.coding_assessment?.coding_language,
          coding_assessment_start_date: formattedCodingStartDate,
          coding_assessment_end_date: formattedCodingEndDate,
          programming_language: formData.programming_language,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      //  toast.success("Job Created  successfully")
      console.log("API Response:", response.data);
      handleNext();
    } catch (error) {
      console.error("Error while making API call:", error);
      // let messages =  JSON.stringify(err.message);

      //         // return toast.error(messages)
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  console.log("...main active step..", activeStep);
  console.log("");

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            handleNext={handleNext}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 1:
        return (
          <Step2
            handleNext={handleNext}
            handleBack={handleBack}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 2:
        return (
          <Step3
            handleNext={handleNext}
            handleBack={handleBack}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 3:
        return (
          <Step4
            handleNext={handleNext}
            handleBack={handleBack}
            formData={formData}
            setFormData={setFormData}
            setTechnicalCheckedParent={setTechnicalCheckedParent}
          />
        );
      case 4:
        return (
          <Step5
            handleNext={handleNext}
            handleBack={handleBack}
            formData={formData}
            setFormData={setFormData}
            technicalChecked={technicalCheckedParent}
          />
        );
      // case 5:
      //   return (
      //     <Step6
      //       handleNext={handleNext}
      //       handleBack={handleBack}
      //       formData={formData}
      //       setFormData={setFormData}
      //     />
      //   );
      case 5:
        return (
          <Step7
            handleNext={handleNext}
            handleBack={handleBack}
            formData={formData}
            setFormData={setFormData}
            handleAPICall={handleAPICall}
          />
        );
      default:
        return <FinalStep />;
    }
  };

  return <div>{getStepContent(activeStep)}</div>;
}
