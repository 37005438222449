import React from 'react';

function Deletes(){
    return(
        <div>
            delete
        </div>
    )
}

export default Deletes