import axios from 'axios';
import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Auth/AuthContext';
import styles from '../../css/jobDetails.module.css';
import client from "../../Auth/awsConfig";
import AWS from 'aws-sdk';
import { toast } from 'react-toastify';


AWS.config.update({

    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});


function ResumeDetails() {
    const [data, setData] = useState([]);
    
    const token = localStorage.getItem("idToken")
    // const { token } = useContext(AuthContext);

    const location = useLocation();
const jobId = location.state && location.state.job_id;
console.log("...jobId...",jobId)
  const navigate = useNavigate()
    // Function to handle file upload
    // const { s3 } = client;
    // console.log("...s3..",s3)
    // const handleFileUpload = async (file) => {
    //   const s3 = new AWS.S3();
    //   try {
    //     const bucketName = 'dev-coucal-temp-bucket';
    //     // const { s3 } = client;
    //     const params = {
    //       Bucket: bucketName,
    //       Key: file.name,
    //       Body: file,
    //       ContentType: 'application/zip',
    //       ACL: 'public-read',
    //     };
        
    //     const response = await s3.upload(params).promise();
    //     console.log('File uploaded successfully:', response.Location);
  
    //     if (response.Location) {
    //       return response.Key;
    //       // VerifyZipAPI(response.Key); // Call VerifyZipAPI after successful file upload
    //     }
    //   } catch (error) {
    //     console.error('Error uploading file:', error);
    //     // Handle error
    //   }
    // };
  
    const handleFileUpload = async(file) => {
      try {
        const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
        const jobId = location.state && location.state.job_id;
        const filenameWithJobId = jobId ? `${jobId}_${file.name}` : file.name;
        console.log("...filename...",filenameWithJobId)
        console.log("file....", filenameWithJobId);
        const fileUploader = process.env.REACT_APP_COUCAL_FILE_UPLOADER
        const response = await axios.put(
          `${baseUrl}/fileupload/${fileUploader}/${filenameWithJobId}`,
          file,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/zip',
            },
          }
        );    
        
        console.log('API Response:', response);
        
        navigate("/dashboard");
      } catch(error){
        console.error('Error uploading file : ', error);
      }
    }
    
    // Function to handle file input change
    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      console.log("...selectedFile...",selectedFile.name)
      handleFileUpload(selectedFile);
    };
  
    // Function to verify the uploaded zip file
    const VerifyZipAPI = async (fileKey) => {
      console.log("..verifyZip...",fileKey)
      try {
        const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
        const response = await axios.post(

          `${baseUrl}/verifyzip`,
          {
            job_id: location.state.job_id,
            file_name: fileKey,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/zip',
            },
          }
        );    
      
      //   toast.success('Uploaded successfully');
        console.log('API Response:', response.data);
        
        navigate("/dashboard")
      } catch (error) {
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Server responded with a non-2xx status:', error.response.status);
              toast.error("only pdf and doc will be acceptable")
              console.error('Response data:', error.response.data);
            } else if (error.request) {
              // The request was made but no response was received
              console.error('No response received from the server:', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error during request setup:', error.message);
            }
        console.error('Error while making API call:', error);
      }
    };
  
  
    const handleAPICall = async (formData) => {
      //  console.log("...handleAPIformData..",formData)
      //  console.log("...handleAPIformDatadeal..",formData.deal_breaker_question)
      //  console.log("...handleAPIfornormamData..",formData.normal_questions)
      console.log("...jobId...",location.state.job_id)
      try {
        
        const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
          const response = await axios.post(`${baseUrl}/resume-details`, {
          job_id:location.state.job_id
          
  
      },{
          headers: {
              Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
      }
        );
        //  toast.success("Job Created  successfully")
         console.log('API Response:', response.data);
         console.log("...data...",response.data.resume_files)
         const sortedData = response.data.resume_files.sort((a, b) => b.candidate_score - a.candidate_score);
  console.log("...sortedData...",sortedData)
          // Print the highest score
          // if (sortedData.length > 0) {
          //     const highestScore = sortedData[0].overall_score;
          //     console.log('Highest Score:', highestScore);
          // }
  setData(sortedData)
        // await setData(sortedData);
      } catch (error) {
        console.error('Error while making API call:', error);
  
        // let messages =  JSON.stringify(error.response);
         
        //    return toast.error(messages)
        // return toast.error('Resumes Not Found. Plz Verify the Job ID.')
      }
  
    };
  
    const handleResumes = async () => {
      // Find the input element
      const fileInput = document.querySelector('input[type=file]');
      if (!fileInput) {
        console.error('Input element not found');
        return;
      }
    
      // Get the selected file
      const selectedFile = fileInput.files[0];
      if (!selectedFile) {
        console.error('No file selected');
        return;
      }
    
      // Call handleFileUpload to upload the file and get the file key
      const fileKey = await handleFileUpload(selectedFile);
      if (fileKey) {
        console.log("..virifyZip called")
        // If file upload is successful, call VerifyZipAPI
        VerifyZipAPI(fileKey);
      }
      // Other code remains unchanged...
    };
    
  
 
  
  
  console.log("...data response ...",data)
  console.log("...nearby..",data.nearby)
  
  useEffect(() => {
    handleAPICall();
  
    // Set up an interval to refresh the table every 30 seconds (30000 milliseconds)
    const refreshInterval = setInterval(() => {
      handleAPICall();
    }, 30000);
  
    // Clear the interval on component unmount to avoid memory leaks
    return () => clearInterval(refreshInterval);
  }, [token, location.state.job_id]);
  
    return (
      <div>
        <label className={styles.jobDetailsbutton}>
          Upload Resumes
          <input style={{ margin: '20px' }} type="file" accept=".zip" onChange={handleFileChange} />
        </label>
        <button onClick={handleResumes} style={{border:'1px solid black',borderRadius : '8px' , padding : "4px"}}>Submit</button>
        <div>
          <table className={styles.tableJobDetails}>
            <tr className={styles.JobDetailstr}>
              <th className={styles.JobDetailsth}>Rank</th>
              <th className={styles.JobDetailsth}>Name</th>
              <th className={styles.JobDetailsth}>Current Location</th>
              <th className={styles.JobDetailsth}>Work Experience</th>
              <th className={styles.JobDetailsth}>Score</th>
              <th className={styles.JobDetailsth}>Skills</th>
            </tr>
  
            {data.map((item, index) => (
              <tr key={index}>
                <td className={styles.JobDetailstr}>{index + 1}</td>
                <td className={styles.JobDetailstr}>{item.Name || 'Data Not Found' }</td>
                {/* <td className={styles.JobDetailstr}>{item.Current_Location || 'Data Not Found'}</td> */}
                <td className={`${styles.JobDetailstr} ${!item.nearby ? '' : styles.boldLocation}`} style={{ fontWeight: item.nearby ? 'bold' : 'normal' }}>
    {item.Current_Location || 'Data Not Found'}
</td>
                <td className={styles.JobDetailstr}>{item.Work_Experience || 'Data Not Found'}</td>
                <td className={styles.JobDetailstr}>{item.candidate_score || 'Data Not Found'}</td>
                <td className={styles.JobDetailstr}>
        {item.Skills && item.Skills.length > 0 ? item.Skills.slice(0, 4).join(', ') : ''}
      </td>
              </tr>
            ))}
          </table>
        </div>
  
      </div>
    );
  }

export default ResumeDetails