// import React, { useCallback } from 'react';
// import '../cssdeleteone/responsive.css';
// // import 'bootstrap/dist/css/bootstrap. css';

// function Checkjs() {
  
 
  

//   return (
//     <div>
//     <section className="banner-one" id="home">
//     {/*        	<img src="images/banner-icon-1-1.png" alt="Awesome Image" class="bubble-1" />
//       <img src="images/banner-icon-1-3.png" alt="Awesome Image" class="bubble-2" />
//       <img src="images/banner-icon-1-2.png" alt="Awesome Image" class="bubble-3" />
//       <img src="images/banner-icon-1-4.png" alt="Awesome Image" class="bubble-4" />
//       <img src="images/banner-icon-1-5.png" alt="Awesome Image" class="bubble-5" />
//       <img src="images/banner-icon-1-6.png" alt="Awesome Image" class="bubble-6" />*/}
//     {/*<img src="images/banner-moc-1.png" class="banner-one__moc" alt="Awesome Image"/>*/}
//     <div className="container">
//       <div className="row">
//         <div className="col-lg-6">
//           <div className="banner-one__content">
//             <div className="block-title">
//               <div className="block-title__text">
//                 Certified
//                 <br />
//                 Managed<br /> 
//                 Services Provider
//               </div>{/* /.block-title__text */}
//             </div>{/* /.block-title */}
//             <p className="banner-one__text">Certified Managed Services Provider that will Manage Any Cloud, Any time!</p>{/* /.banner-one__text */}
//             {/*<a href="#" class="banner-one__btn">Get Started</a>*/}
//           </div>{/* /.banner-one__content */}
//         </div>{/* /.col-lg-5 */}
//         <div className="col-lg-6">
//           <div className="cta-one__image">
//             <img src="images/Cloud-Computing-Service-Providers.png" />
//           </div>{/* /.banner-one__content */}
//         </div>{/* /.col-lg-5 */}
//       </div>{/* /.row */}
//     </div>{/* /.container */}
//   </section>{/* /.banner-one */}
//   <section className="brand-style-one">
//     {/*            <img src="images/banner-icon-1-5.png" alt="Awesome Image" class="bubble-1" />
//       <img src="images/banner-icon-1-3.png" alt="Awesome Image" class="bubble-2" />*/}
//     <div className="container">
//       <div className="brand-carousel-one owl-theme owl-carousel">
//         <div className="item">
//           <img src="<?=SITE_MAIN?>images/brands/aws-logo.svg" alt="Aws" />
//         </div>{/* /.item */}
//         <div className="item">
//           <img src="<?=SITE_MAIN?>images/brands/google-logo.png" alt="Google Cloud" />
//         </div>{/* /.item */}
//         <div className="item">
//           <img src="<?=SITE_MAIN?>images/brands/azure-logo.svg" alt="Microsoft Azure" />
//         </div>{/* /.item */}
//         <div className="item">
//           <img src="<?=SITE_MAIN?>images/brands/cloudflare-logo.svg" alt="Cloudflare" />
//         </div>{/* /.item */}
//         <div className="item">
//           <img src="<?=SITE_MAIN?>images/brands/tencent-cloud-logo.png" alt="Tencent Cloud" />
//         </div>{/* /.item */}
//         <div className="item">
//           <img src="<?=SITE_MAIN?>images/brands/kubeless-logo.svg" alt="Kuberless" />
//         </div>{/* /.item */}
//       </div>{/* /.brand-carousel-one */}
//       <hr className="style-one" />
//     </div>{/* /.container */}
//   </section>{/* /.brand-style-one */}
//   <section className="pricing-one" id="pricing">
//     <div className="container">
//       <div className="block-title text-center">
//         <div className="block-title__text"><span>Services</span></div>{/* /.block-title__text */}
//       </div>{/* /.block-title */}
//       <div className="row">
//         <div className="col-lg-4">
//           <div className="pricing-one__single">
//             <div className="pricing-one__top">
//               <h3 className="pricing-one__title">Infrastructure <br /> Management</h3>
//               <p className="pricing-one__pack">&nbsp;</p> {/*/.pricing-one__pack */}
//             </div>{/* /.pricing-one__top */}
//             <ul className="pricing-one__feature">
//               <li className="pricing-one__feature-item"><a href="<?=SITE_MAIN?>data-center-designing-management.php">Data Center Designing and Management</a></li>
//               <li className="pricing-one__feature-item"><a href="<?=SITE_MAIN?>voip-setup-management.php">VoIP Setup and Management</a></li>
//               <li className="pricing-one__feature-item"><a href="<?=SITE_MAIN?>virtualization.php">Virtualization</a></li>
//               <li className="pricing-one__feature-item"><a href="<?=SITE_MAIN?>open-source-services-deployment.php">Open Source Services Deployment</a> </li>
//               <li className="pricing-one__feature-item"><a href="<?=SITE_MAIN?>managed-monitoring.php">Managed Monitoring</a></li>
//             </ul>{/* /.pricing-one__feature */}
//             <a href="<?=SITE_MAIN?>contact-us" className="pricing-one__btn">Contact Us</a>
//             <p className="pricing-one__note">24/7 Support</p>{/* /.pricing-one__note */}
//           </div>{/* /.pricing-one__single */}
//         </div>{/* /.col-lg-4 */}
//         <div className="col-lg-4">
//           <div className="pricing-one__single">
//             <div className="pricing-one__top">
//               <h3 className="pricing-one__title">Automation <br /> Services</h3>
//               <p className="pricing-one__pack">&nbsp;</p> {/*/.pricing-one__pack */}
//             </div>{/* /.pricing-one__top */}
//             <ul className="pricing-one__feature">
//               <li className="pricing-one__feature-item"><a href="<?=SITE_MAIN?>devops-services.php">DevOps Services</a></li>
//               <li className="pricing-one__feature-item"><a href="<?=SITE_MAIN?>cloud-management.php">Cloud Management</a></li>
//               <li className="pricing-one__feature-item"><a href="<?=SITE_MAIN?>container-management.php">Container Management</a></li>
//               <li className="pricing-one__feature-item"><a href="<?=SITE_MAIN?>cicd-implementation.php">CICD Implementation</a></li>
//               <li className="pricing-one__feature-item"><a href="<?=SITE_MAIN?>custom-automation.php">Custom Automation</a></li>
//             </ul>{/* /.pricing-one__feature */}
//             <a href="<?=SITE_MAIN?>contact-us" className="pricing-one__btn">Contact Us</a>
//             <p className="pricing-one__note">24/7 Support</p>{/* /.pricing-one__note */}
//           </div>{/* /.pricing-one__single */}
//         </div>{/* /.col-lg-4 */}
//         <div className="col-lg-4">
//           <div className="pricing-one__single">
//             <div className="pricing-one__top">
//               <h3 className="pricing-one__title">Security <br /> Services</h3>
//               <p className="pricing-one__pack">&nbsp;</p> {/*/.pricing-one__pack */}
//             </div>{/* /.pricing-one__top */}
//             <ul className="pricing-one__feature">
//               <li className="pricing-one__feature-item"><a href="<?=SITE_MAIN?>penetration-testing.php">Penetration Testing</a></li>
//               <li className="pricing-one__feature-item"><a href="<?=SITE_MAIN?>vulnerability-assessment.php">Vulnerability Assessment</a></li>
//               <li className="pricing-one__feature-item"><a href="<?=SITE_MAIN?>security-compliance.php">Security Compliance</a></li>
//               <li className="pricing-one__feature-item">&nbsp;</li>
//               <li className="pricing-one__feature-item">&nbsp;</li>
//             </ul>{/* /.pricing-one__feature */}
//             <a href="<?=SITE_MAIN?>contact-us.php" className="pricing-one__btn">Contact Us</a>
//             <p className="pricing-one__note">24/7 Support</p>{/* /.pricing-one__note */}
//           </div>{/* /.pricing-one__single */}
//         </div>{/* /.col-lg-4 */}
//       </div>{/* /.row */}
//     </div>{/* /.container */}
//   </section>{/* /.pricing-one */}
//   <section className="service-one" id="services">
//     <div className="container">
//       <div className="block-title text-center">
//         <div className="block-title__text"><span>About Us</span></div>{/* /.block-title__text */}
//       </div>{/* /.block-title */}
//       <div className="row">
//         <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
//           <div className="cta-three__content my-auto">
//             <p className="cta-three__text">Having vast experience in Architecting of Scalable, Reliable and High Available Infrastructure. Multi year experience in managing different cloud platforms like Amazon Web Services, Google Cloud Platform and Microsoft Azure. DevOps Architects who can designed and develop Automation of environment with Continues Delivery and Deployment (CICD) by using industry standard tools. Experienced team in leading IT projects on various topics like IT Security, IT Management. Security Risk Management, IT Audits, PCI DSS Security Compliance, HIPAA, GLBA, SSAE16. Accomplished IT Professionals with demonstrated success in implementation of strategic IT initiatives that improve business functionality with positive impacts on the bottom line.</p>{/* /.cta-three__text */}
//             {/*                            <ul class="cta-three__list">
//                           <li class="cta-three__list-item"><i class="fa fa-check"></i>Lorem Imsum is a dummy text </li>
//                           <li class="cta-three__list-item"><i class="fa fa-check"></i>Lorem Imsum is a dummy text </li>
//                           <li class="cta-three__list-item"><i class="fa fa-check"></i>Lorem Imsum is a dummy text </li>
                          
//                       </ul> /.cta-three__list */}
//             <a href="<?=SITE_MAIN?>contact-us" className="cta-three__btn">Contact Us</a>
//           </div>
//         </div>{/* /.col-lg-3 */}
//       </div>{/* /.row */}
//     </div>{/* /.container */}
//   </section>{/* /.service-one */}
//   <section className="service-one" id="services">
//     <div className="container">
//       <div className="block-title text-center">
//         <div className="block-title__text"><span>What we do</span></div>{/* /.block-title__text */}
//       </div>{/* /.block-title */}
//       <div className="row">
//         <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
//           <div className="service-one__single">
//             <i className="service-one__icon nonid-icon-laptop" />
//             <h3 className="service-one__title"><a href="#">Managed  <br /> Cloud Support</a></h3>{/* /.service-one__title */}
//             <p className="service-one__text">Let us manage your Cloud Infrastructure so you can concentrate on your business.</p>{/* /.service-one__text */}
//             <a href="<?=SITE_MAIN?>cloud-management" className="service-one__link"><i className="nonid-icon-left-arrow" /></a>
//           </div>{/* /.service-one__single */}
//         </div>{/* /.col-lg-3 */}
//         <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
//           <div className="service-one__single">
//             <i className="service-one__icon nonid-icon-presentation" />
//             <h3 className="service-one__title"><a href="#">Cloud  <br /> Migration/Enablement</a></h3>{/* /.service-one__title */}
//             <p className="service-one__text">Transform your legacy infrastructure into something that is new age and offer all the benefits that come from Cloud.</p>{/* /.service-one__text */}
//             <a href="#" className="service-one__link"><i className="nonid-icon-left-arrow" /></a>
//           </div>{/* /.service-one__single */}
//         </div>{/* /.col-lg-3 */}
//         <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
//           <div className="service-one__single">
//             <i className="service-one__icon nonid-icon-target" />
//             <h3 className="service-one__title"><a href="#">Data <br /> Center Management</a></h3>{/* /.service-one__title */}
//             <p className="service-one__text">Give us the headace to manage and maintain your data centers. We will manage everything from servers to electricity and air conditioning.</p>{/* /.service-one__text */}
//             <a href="#" className="service-one__link"><i className="nonid-icon-left-arrow" /></a>
//           </div>{/* /.service-one__single */}
//         </div>{/* /.col-lg-3 */}
//       </div>{/* /.row */}
//       <div className="row">
//         <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
//           <div className="service-one__single">
//             <i className="service-one__icon nonid-icon-visualization" />
//             <h3 className="service-one__title"><a href="#">Managed <br /> Monitoring</a></h3>{/* /.service-one__title */}
//             <p className="service-one__text">Monitoring as a service. We will monitor everything in your cloud or lagacy data center and provide you reports bounded with SLA.</p>{/* /.service-one__text */}
//             <a href="#" className="service-one__link"><i className="nonid-icon-left-arrow" /></a>
//           </div>{/* /.service-one__single */}
//         </div>{/* /.col-lg-3 */}
//         <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
//           <div className="service-one__single">
//             <i className="service-one__icon nonid-icon-laptop" />
//             <h3 className="service-one__title"><a href="#">DevOps  <br /> s/Support</a></h3>{/* /.service-one__title */}
//             <p className="service-one__text">DevOps uses automation techniques to increase collaboration across development and operations, enabling faster, more predictable and more frequent deployments to market.</p>{/* /.service-one__text */}
//             <a href="<?=SITE_MAIN?>devops-services" className="service-one__link"><i className="nonid-icon-left-arrow" /></a>
//           </div>{/* /.service-one__single */}
//         </div>{/* /.col-lg-3 */}
//         <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
//           <div className="service-one__single">
//             <i className="service-one__icon nonid-icon-presentation" />
//             <h3 className="service-one__title"><a href="#">Security  <br /> Assessment</a></h3>{/* /.service-one__title */}
//             <p className="service-one__text">Security is not last but first to think.</p>{/* /.service-one__text */}
//             <a href="<?=SITE_MAIN?>security-compliance" className="service-one__link"><i className="nonid-icon-left-arrow" /></a>
//           </div>{/* /.service-one__single */}
//         </div>{/* /.col-lg-3 */}
//       </div>{/* /.row */}
//     </div>{/* /.container */}
//   </section>{/* /.service-one */}
//   <section className="fun-fact-one">
//     <img src="images/banner-icon-1-4.png" alt="Awesome Image" className="bubble-1" />
//     <img src="images/banner-icon-1-2.png" alt="Awesome Image" className="bubble-2" />
//     <img src="images/banner-icon-1-1.png" alt="Awesome Image" className="bubble-3" />
//     <img src="images/banner-icon-1-6.png" alt="Awesome Image" className="bubble-4" />
//     <div className="container">
//       <div className="block-title text-center">
//         <div className="block-title__text"><span>Over 185 projects</span> <br /> <span>completed</span></div>{/* /.block-title__text */}
//       </div>{/* /.block-title */}
//       <div className="fun-fact-one__box-wrapper">
//         <div className="fun-fact-one__single">
//           <div className="inner-block">
//             <h3 className="fun-fact-one__title counter">
//               220
//             </h3>{/* /.fun-fact-one__title */}
//             <p className="fun-fact-one__text">
//               projects
//             </p>{/* /.fun-fact-one__text */}
//           </div>{/* /.inner-block */}
//         </div>{/* /.fun-fact-one__single */}
//         <div className="fun-fact-one__single">
//           <div className="inner-block">
//             <h3 className="fun-fact-one__title counter">
//               123
//             </h3>{/* /.fun-fact-one__title */}
//             <p className="fun-fact-one__text">
//               customers
//             </p>{/* /.fun-fact-one__text */}
//           </div>{/* /.inner-block */}
//         </div>{/* /.fun-fact-one__single */}
//         <div className="fun-fact-one__single">
//           <div className="inner-block">
//             <h3 className="fun-fact-one__title counter">
//               185
//             </h3>{/* /.fun-fact-one__title */}
//             <p className="fun-fact-one__text">
//               success
//             </p>{/* /.fun-fact-one__text */}
//           </div>{/* /.inner-block */}
//         </div>{/* /.fun-fact-one__single */}
//         <div className="fun-fact-one__single">
//           <div className="inner-block">
//             <h3 className="fun-fact-one__title counter">
//               22
//             </h3>{/* /.fun-fact-one__title */}
//             <p className="fun-fact-one__text">
//               awards
//             </p>{/* /.fun-fact-one__text */}
//           </div>{/* /.inner-block */}
//         </div>{/* /.fun-fact-one__single */}
//       </div>{/* /.fun-fact-one_box-wrapper */}
//     </div>{/* /.container */}
//   </section>
//   </div>
//   );
// }

// export default Checkjs;
import React, { useState, useEffect } from 'react';
// import './YourComponent.css'; // Import your CSS file

const Checkjs = () => {
  const [lines, setLines] = useState([
    'First line',
    'Second line',
    'Third line',
    'Fourth line',
    'Fifth line',
  ]);
  const [visibleLines, setVisibleLines] = useState([]);

  useEffect(() => {
    // Use a timeout to add lines one by one with a delay
    const timeoutIds = lines.map((line, index) =>
      setTimeout(() => {
        setVisibleLines((prevLines) => [...prevLines, line]);
      }, index * 1000) // Adjust the delay as needed (here it's 1000ms or 1 second)
    );

    return () => {
      // Clear timeouts on component unmount to avoid memory leaks
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, [lines]);

  return (
    <div className="your-container">
      {visibleLines.map((line, index) => (
        <div key={index} className="animated-line">
          {line}
        </div>
      ))}
    </div>
  );
};

export default Checkjs;
