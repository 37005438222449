import React, { useContext, useState, useEffect } from "react";
import client from "./awsConfig";
import { AuthContext } from "./AuthContext";
import {
  InitiateAuthCommand,
  GetUserCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import logo from "../assets/Icon.png";
import loader from "../assets/animated_logo.gif";
import { jwtDecode } from "jwt-decode";
import { CircularProgress } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

function Login() {
  const { cognitoIdentityServiceProvider } = client;
  const { setToken, setRefreshToken, setAccessToken, setEmail } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [loadingStep, setLoadingStep] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [fields, setFields] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleSignUp = () => {
    navigate("/register");
  };

  const handleForgot = () => {
    navigate("/forgotPassword");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let formValid = true;
    const updatedErrors = { ...errors };

    Object.entries(fields).forEach(([fieldName, value]) => {
      if (value.trim() === "") {
        updatedErrors[fieldName] = true;
        formValid = false;
      } else {
        updatedErrors[fieldName] = false;
      }
    });

    setErrors(updatedErrors);

    if (formValid) {
      await handleLogin(fields);
    } else {
      console.log("Form has errors. Please fill in all fields.");
    }
  };

  const handleLogin = async (values) => {
    setLoadingStep("login");
    try {
      const authResponse = await cognitoIdentityServiceProvider.send(
        new InitiateAuthCommand({
          AuthFlow: "USER_PASSWORD_AUTH",
          AuthParameters: {
            USERNAME: values.email,
            PASSWORD: values.password,
          },
          ClientId: process.env.REACT_APP_CLIENT_ID_USER,
        })
      );

      if (
        authResponse.AuthenticationResult &&
        authResponse.AuthenticationResult.AccessToken
      ) {
        const { IdToken, RefreshToken, AccessToken } =
          authResponse.AuthenticationResult;

        const clientId = localStorage.getItem("client_id");

        localStorage.setItem("accessToken", AccessToken);
        localStorage.setItem("idToken", IdToken);
        localStorage.setItem("refreshToken", RefreshToken);
        localStorage.setItem("logedIn", "true");

        setToken(IdToken);
        setRefreshToken(RefreshToken);
        setAccessToken(AccessToken);
        setEmail(values.email);

        setLoadingStep("getUserAttributes");
        const userResponse = await cognitoIdentityServiceProvider.send(
          new GetUserCommand({
            AccessToken: AccessToken,
          })
        );

        const invitedUserAttribute = userResponse.UserAttributes.find(
          (attr) => attr.Name === "custom:invitedUser"
        );

        if (invitedUserAttribute && invitedUserAttribute.Value === "true") {
          setLoadingStep(null);
          navigate("/invite-user-form", {
            state: {
              email: values.email,
              accessToken: AccessToken,
              clientId: clientId,
            },
          });
        } else if (
          invitedUserAttribute &&
          invitedUserAttribute.Value === "false"
        ) {
          try {
            const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
            const apiResponse = await axios.post(
              `${baseUrl}/user/verify_invite_user`,
              { user_email_id: values.email },
              {
                headers: {
                  Authorization: `Bearer ${IdToken}`,
                  "Content-Type": "application/json",
                },
              }
            );

            if (apiResponse.data && apiResponse.data.client_id) {
              localStorage.setItem("client_id", apiResponse.data.client_id);
              navigate("/dashboard", {
                state: { client_id: apiResponse.data.client_id },
              });
            } else {
              console.error("Unexpected API response");
              toast.error("An unexpected error occurred. Please try again.");
            }
          } catch (apiError) {
            console.error("API error", apiError);
            toast.error(
              "An error occurred while verifying your account. Please try again."
            );
          }
        } else {
          setLoadingStep("verifyUser");

          try {
            const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
            const apiResponse = await axios.post(
              `${baseUrl}/verify_user`,
              { user_email_id: values.email },
              {
                headers: {
                  Authorization: `Bearer ${IdToken}`,
                  "Content-Type": "application/json",
                },
              }
            );

            if (apiResponse.data && apiResponse.data.client_id) {
              localStorage.setItem("client_id", apiResponse.data.client_id);
              navigate("/dashboard", {
                state: { client_id: apiResponse.data.client_id },
              });
            } else {
              console.error("Unexpected API response");
              toast.error("An unexpected error occurred. Please try again.");
            }
          } catch (apiError) {
            if (
              apiError.response &&
              apiError.response.data.error === "User not found"
            ) {
              setLoadingStep(null);
              navigate("/check-user", {
                state: {
                  email: values.email,
                  AccessToken: AccessToken,
                  IdToken: IdToken,
                  RefreshToken: RefreshToken,
                },
              });
            } else {
              console.error("API error", apiError);
              toast.error(
                "An error occurred while verifying your account. Please try again."
              );
            }
          }
        }
      } else {
        toast.error("Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Login error", error);
      toast.error(error.message);
    } finally {
      setLoadingStep(null);
    }
  };

  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("idToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("Edit-jobId");
    sessionStorage.clear();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {loadingStep ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                margin: "0",
              }}
            >
              <img
                style={{ width: "50px", height: "50px" }}
                src={loader}
                alt="Loading"
              />
            </div>
          ) : (
            <>
              <img
                style={{ width: 80, height: 80, padding: 10 }}
                src={logo}
                alt="icon"
              />
              <Typography
                component="h1"
                variant="h4"
                marginTop={2}
                style={{ color: "#363640", fontWeight: "600" }}
              >
                Welcome back!
              </Typography>
              <Typography
                component="h1"
                marginTop={2}
                variant="h6"
                style={{ color: "#363640" }}
              >
                Sign in to your account
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3, pb: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      error={errors.email}
                      helperText={
                        errors.email ? "please enter your mail id" : ""
                      }
                      onChange={handleChange}
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={errors.password}
                      helperText={
                        errors.password ? "please fill you password" : ""
                      }
                      onChange={handleChange}
                      fullWidth
                      name="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      autoComplete="new-password"
                      InputProps={{
                        endAdornment: (
                          <Button
                            onClick={handleTogglePasswordVisibility}
                            tabIndex={-1}
                            sx={{ padding: 0 }}
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </Button>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Typography
                  variant="body2"
                  onClick={handleForgot}
                  sx={{
                    mt: 2,
                    color: "#363640",
                    textAlign: "end",
                    cursor: "pointer",
                  }}
                >
                  Forgot your password?
                </Typography>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 4,
                    mb: 2,
                    height: 50,
                    textTransform: "inherit",
                    fontSize: "18px",
                  }}
                >
                  Login
                </Button>
                <Grid
                  container
                  justifyContent="center"
                  sx={{ marginBottom: 5 }}
                >
                  <Grid item>
                    <Link href="#" variant="body2" underline="none">
                      <Typography
                        variant="caption2"
                        sx={{
                          color: "#797B7C",
                          display: "flex",
                          flexDirection: "row",
                          mt: 2,
                        }}
                      >
                        Don't have an account?&nbsp;
                        <Typography
                          onClick={handleSignUp}
                          variant="caption2"
                          sx={{
                            color: "#115293",
                            fontWeight: "600",
                          }}
                        >
                          Sign Up
                        </Typography>
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Box>
        {/* </Box> */}
      </Container>
      <Box
        sx={{
          backgroundColor: "#EEF3F7",
          position: "fixed",
          bottom: 0,
          width: "100%",
          padding: 2,
          textAlign: "center",
          height: 50,
        }}
      >
        <Typography variant="body2" sx={{ color: "#142764" }}>
          &copy; copyright {new Date().getFullYear()}: Udyat Technologies
        </Typography>
      </Box>
    </ThemeProvider>
  );
}

export default Login;
