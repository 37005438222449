import React from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../Auth/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import styles from "../../css/updateJobDetails.module.css"
import { CiEdit } from "react-icons/ci";
import axios from 'axios';

function JobDetails() {
  const [data, setData] = useState('')
  const [arraydata,setarraydata] = useState([])
  const [normalArray,setnormalquestionarray] = useState([])
  const location = useLocation()
  console.log("...jobdetails...", location.state.job_id)
  // const { token } = useContext(AuthContext);
  // console.log("...jobdetals ...", token)
  const token = localStorage.getItem("idToken")
  
  const job_id = location.state.job_id
  console.log("...true...", job_id)
  const navigate = useNavigate()
  const updateApi = () => {
    console.log("..jobdetajhj..")
    navigate('/update-job', {
      state: {
        job_id: job_id,
        address:  data.address,
        country:  data.country,
        deal_breaker_questions:data.deal_breaker_questions,
        experience:  data.experience,
        job_category: data.job_category,
        job_description: data.job_description,
        job_title: data.job_title,
        job_type: data.job_type,
        key_responsiblities: data.key_responsiblities,
        normal_questions: data.normal_questions,
        number_of_resumes: data.number_of_resumes,
        number_of_vacancies: data.number_of_vacancies,
        qualification: data.qualification,
        schedule: data.schedule,
        skills: data.skills,
        start_date: data.start_date,
        target_hiring_date: data.target_hiring_date
      }
    })
  }
  const jobId = localStorage.getItem('job_id');
console.log("...username...",jobId); // Output: john_doe

  useEffect(() => {
    // Define the API endpoint
    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    const apiUrl = `${baseUrl}/job/show_job_detail`;

    // Make the POST request using Axios
    axios.post(apiUrl, {
      job_id: location.state.job_id ? location.state.job_id : jobId, 
    },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => {
        // Handle the API response data here
        setData(response.data);
        console.log("..response..", response.data)
        // const dealBreakerQuestionsArray = response.data.deal_breaker_questions ? response.data.deal_breaker_questions.split('\n') : [];
        // console.log("...dealBreakerQuestion...",dealBreakerQuestionsArray)
        // console.log("Number of strings:", dealBreakerQuestionsArray.length);
        const separatedValuesArray = response.data.deal_breaker_questions
        ? response.data.deal_breaker_questions
            .replace(/\[|\]|'/g, '') // Remove [ ] and single quotes
            .split(',')
            .map(value => value.trim()) // Trim leading and trailing spaces
            : [];
      console.log("Separated Values Array:",separatedValuesArray)
        // setarraydata(response.data.deal_breaker_questions)
         setarraydata(separatedValuesArray.map((value, index) => `${value}`));


         const separatednormalValuesArray = response.data.normal_questions
         ? response.data.normal_questions
             .replace(/\[|\]|'/g, '') // Remove [ ] and single quotes
             .split(',')
             .map(value => value.trim()) // Trim leading and trailing spaces
             : [];
       console.log("separatednormalValuesArray Values Array:",separatednormalValuesArray)
         // setarraydata(response.data.deal_breaker_questions)
         setnormalquestionarray(separatednormalValuesArray.map((value, index) => `${value}`));

         

        // navigate("/dashboard",{state : {client_id:response.data.client_id}})
        //    return  toast.success("User Found Successfully");
      })
      .catch((error) => {
        // Handle errors here
        console.error('Error:', error);
        // navigate("/init-page")
        // return toast.success("User Not Found");
      });
  }, []);

  
  console.log("...ajghgaj array...",arraydata)
console.log("....normalArray...",normalArray)

  return (
    <div className={styles.updatejobpage}>
      {/* <h1>hi job details</h1> */}
      <  CiEdit onClick={() => updateApi()} className={styles.editIcon} />
      <div >
        <label>Job Title:</label>
        <input className={styles.updateinput} type="text" value={data.job_title} readOnly />
      </div>

      <div>
        <label>address</label>
        <input className={styles.updateinput} type="text" value={data.address} readOnly />
      </div>

      <div>
        <label>country</label>
        <input className={styles.updateinput} type="text" value={data.country} readOnly />
      </div>

      <div>
        <label>job_type</label>
        <input className={styles.updateinput} type="text" value={data.job_type} readOnly />
      </div>

      <div>
        <label>schedule</label>
        <input className={styles.updateinput} type="text" value={data.schedule} readOnly />
      </div>

      <div>
        <label>number_of_vacancies</label>
        <input className={styles.updateinput} type="text" value={data.number_of_vacancies} readOnly />
      </div>

      <div>
        <label>target_hiring_date</label>
        <input className={styles.updateinput} type="text" value={data.target_hiring_date} readOnly />
      </div>

      <div>
        <label>skills</label>
        <input className={styles.updateinput} type="text" value={data.skills} readOnly />
      </div>

      <div>
        <label>experience</label>
        <input className={styles.updateinput} type="text" value={data.experience} readOnly />
      </div>

      <div>
        <label>qualification</label>
        <input className={styles.updateinput} type="text" value={data.qualification} readOnly />
      </div>

      <div>
        <label>key_responsiblities</label>
        <input className={styles.updateinput} type="text" value={data.key_responsiblities} readOnly />
      </div>

      {/* <div>
        <label>deal_breaker_questions</label>
        
        <input className={styles.updateinput} type="text" value={data.deal_breaker_questions} readOnly />
      </div> */}
       <div>
        <label>Deal Breaker Questions:</label>
        {arraydata.map((formattedString, index) => (
          <input
            key={index}
            className={styles.updateinput}
            type="text"
            value={formattedString}
            readOnly
          />
        ))}
      </div> 
     

      {/* <div>
        <label>normal_questions</label>
        <input className={styles.updateinput} type="text" value={data.normal_questions} readOnly />
      </div> */}
      <div>
        <label>Normal Questions:</label>
        {normalArray.map((formattedString, index) => (
          <input
            key={index}
            className={styles.updateinput}
            type="text"
            value={formattedString}
            readOnly
          />
        ))}
      </div> 

      <div>
        <label>creation_date</label>
        <input className={styles.updateinput} type="text" value={data.creation_date} readOnly />
      </div>

      <div>
        <label>start_date</label>
        <input className={styles.updateinput} type="text" value={data.start_date} readOnly />
      </div>

      <div>
        <label>number_of_resumes</label>
        <input className={styles.updateinput} type="text" value={data.number_of_resumes} readOnly />
      </div>

      <div>
        <label>job_description</label>
        <input className={styles.updateinput} type="text" value={data.job_description} readOnly />
      </div>

      <div>
        <label>job_category</label>
        <input className={styles.updateinput} type="text" value={data.job_category} readOnly />
      </div>
<button type='submit' onClick={()=>navigate('/dashboard')} className={styles.jobdetailsbutton}>back</button>
      {/* <p>Job Title:   {data.job_title}</p>,
    <p>Address:  {data.address}</p>,
    <p>Job Type:  {data.job_type}</p>,
    <p>Schedule:  {data.schedule}</p>,
    <p>Number Of Vacancies:  {data.number_of_vacancies}</p>,
    <p>Target Hiring Date:  {data.target_hiring_date}</p>,
    <p>Skills:  {data.skills}</p>,
    <p>Deal Breaker Questions:  {data.deal_breaker_questions}</p>,
    <p>Normal Questions:  {data.normal_questions}</p>, */}
    </div>
  )
}

export default JobDetails
