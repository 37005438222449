import * as React from "react";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import MenuIcon from "@mui/icons-material/Menu";
import { Alert, Skeleton } from "@mui/material";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { Country, State, City } from "country-state-city";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { useState } from "react";

export default function CreateCompany() {
  const [isDarkMode, setIsDarkMode] = React.useState(false);

  const drawerWidth = 200;
  const backgroundDrawer = isDarkMode ? "black" : "#FFF";
  const colorDrawer = isDarkMode ? "#FFF" : "black";

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100%)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const openedMixin = (theme, isDarkMode) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    backgroundColor: backgroundDrawer,
    color: colorDrawer,
  });

  const closedMixin = (theme, isDarkMode) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    backgroundColor: backgroundDrawer,
    color: colorDrawer,
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open, isDarkMode }) => ({
    width: drawerWidth,
    backgroundColor: isDarkMode ? "black" : "#FFF",
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme, isDarkMode),
      "& .MuiDrawer-paper": {
        ...openedMixin(theme, isDarkMode),
      },
    }),
    ...(!open && {
      ...closedMixin(theme, isDarkMode),
      "& .MuiDrawer-paper": {
        ...closedMixin(theme, isDarkMode),
      },
    }),
  }));

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleResize = () => {
    if (window.innerWidth < 600) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = React.useState({
    company: "",
    country: "IN",
    state: "",
    city: "",
    website: "",
    email: "",
  });
  const [clientId, setClientId] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { email, IdToken, RefreshToken, AccessToken } = location.state || {};
  const token = IdToken;

  const tokens = IdToken;
  const decodedToken = jwtDecode(tokens);

  React.useEffect(() => {
    const statesInIndia = State.getStatesOfCountry("IN");
    setStates(statesInIndia);
  }, []);

  React.useEffect(() => {
    if (formData.state) {
      const citiesInState = City.getCitiesOfState("IN", formData.state);
      setCities(citiesInState);
    }
  }, [formData.state]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

    if (name === "state") {
      setFormData((prevData) => ({ ...prevData, city: "" }));
      setCities([]);
    }
  };

  const steps = [
    {
      label: "Create Company",
      description: (
        <Box>
          <TextField
            required
            fullWidth
            label="Company Name"
            name="company"
            onChange={handleInputChange}
            value={formData.company}
            error={Boolean(errors.company)}
            helperText={errors.company || ""}
          />
          <TextField
            required
            fullWidth
            label="Country"
            name="country"
            value="India"
            InputProps={{
              readOnly: true,
            }}
            sx={{ mt: 2 }}
          />
          <TextField
            required
            fullWidth
            label="State"
            name="state"
            select
            SelectProps={{
              native: true,
            }}
            onChange={handleInputChange}
            value={formData.state}
            error={Boolean(errors.state)}
            helperText={errors.state || ""}
            sx={{ mt: 2 }}
          >
            <option value=""></option>
            {states.map((state) => (
              <option key={state.isoCode} value={state.isoCode}>
                {state.name}
              </option>
            ))}
          </TextField>
          <TextField
            required
            fullWidth
            label="City"
            name="city"
            select
            SelectProps={{
              native: true,
            }}
            onChange={handleInputChange}
            value={formData.city}
            error={Boolean(errors.city)}
            helperText={errors.city || ""}
            sx={{ mt: 2 }}
          >
            <option value=""></option>
            {cities.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </TextField>
          <TextField
            required
            fullWidth
            label="Website"
            name="website"
            onChange={handleInputChange}
            value={formData.website}
            error={Boolean(errors.website)}
            helperText={errors.website || ""}
            sx={{ mt: 2 }}
          />
        </Box>
      ),
    },
    {
      label: "User Information",
      description: (
        <Box>
          <TextField
            disabled={true}
            fullWidth
            label="Email ID"
            name="email"
            value={email}
            sx={{ mt: 2 }}
          />
        </Box>
      ),
    },
  ];

  const handleNext = async () => {
    if (activeStep === 0) {
      if (validateCompanyForm()) {
        try {
          await registerApi();
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } catch (error) {
          console.error("Error creating company:", error);
        }
      }
    } else if (activeStep === 1) {
      try {
        await createUserApi();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (error) {
        console.error("Error creating user:", error);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData({
      company: "",
      country: "IN",
      state: "",
      city: "",
      website: "",
      email: "",
    });
    setClientId("");
    setCities([]);
  };

  const validateCompanyForm = () => {
    let hasError = false;
    let newErrors = {};
    if (!formData.company) {
      newErrors.company = "Company name is required";
      hasError = true;
    }
    if (!formData.state) {
      newErrors.state = "State is required";
      hasError = true;
    }
    if (!formData.city) {
      newErrors.city = "City is required";
      hasError = true;
    }
    if (!formData.website) {
      newErrors.website = "Website is required";
      hasError = true;
    }
    setErrors(newErrors);
    return !hasError;
  };

  const registerApi = async () => {
    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    try {
      const response = await axios.post(
        `${baseUrl}/client/create_client`,
        {
          client_address: `${formData.state} ${formData.city}`,
          creator_email_id: email,
          client_website: formData.website,
          client_name: formData.company,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const clientId =
        response.data.existing_client_id || response.data.client_id;
      setClientId(clientId);
    } catch (error) {
      console.error("Error registering client:", error);
    }
  };

  const createUserApi = async () => {
    const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
    try {
      await axios.post(
        `${baseUrl}/user/create_user`,
        {
          user_email_id: email,
          client_id: clientId,
          user_status: "Confirmed",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      localStorage.setItem("accessToken", AccessToken);
      localStorage.setItem("idToken", IdToken);
      localStorage.setItem("refreshToken", RefreshToken);
      localStorage.setItem("logedIn", "true");
      localStorage.setItem("client_id", clientId);
      navigate("/dashboard", { state: { client_id: clientId } });
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Simulate loading data
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Box
      sx={{
        maxHeight: "calc(100vh - 40px)",
        overflowY: "auto",
        width: "auto",
        height: "100vh",
        backgroundColor: isDarkMode ? "black" : "#F9F9F9",
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{
          paddingLeft: 0,

          backgroundColor: isDarkMode ? "#171717" : "#F9F9F9",
          color: isDarkMode ? "#000" : "#DDE8EE",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon sx={{ color: isDarkMode ? "#FFF" : "grey" }} />
          </IconButton>
          <Typography
            variant="h5"
            component="h2"
            color={isDarkMode ? "#FFF" : "#142764"}
            fontWeight="600"
            sx={{ paddingTop: 1.5, paddingBottom: 1 }}
          >
            Create Company
          </Typography>
        </Toolbar>
        <Box style={{ marginTop: 10 }}>
          <Alert severity="info">
            <Typography
              sx={{ fontSize: "12px", color: "#142764", marginTop: "1px" }}
            >
              There is no Company registered for this user. Please fill in below
              details to create a company.
            </Typography>
          </Alert>
        </Box>
      </AppBar>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: isDarkMode ? "black" : "#F9F9F9",
        }}
      >
         {loading ? (
          <Box sx={{ maxWidth: 600, width: "100%", p: 3 }}>
          <Typography variant="h5" component="h2">
            <Skeleton width="40%" />
          </Typography>
          <Box sx={{ mt: 2 }}>
            {[...Array(3)].map((_, index) => (
              <Box key={index} sx={{ mb: 3 }}>
                <Skeleton variant="text" width="60%" sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" height={56} sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" height={56} sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" height={56} sx={{ mb: 1 }} />
                <Box sx={{ display: "flex", mt: 1 }}>
                  <Skeleton
                    variant="rectangular"
                    width={100}
                    height={36}
                    sx={{ mr: 1 }}
                  />
                  <Skeleton variant="rectangular" width={100} height={36} />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        ) : (
        <Box sx={{ maxWidth: 600, width: "100%", p: 3 }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
                <StepContent>
                  <Typography>{step.description}</Typography>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {index === steps.length - 1 ? "Finish" : "Continue"}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>All steps completed - you're finished</Typography>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
            </Paper>
          )}
        </Box>
        )}
      </Box>

      <Box
        sx={{
          backgroundColor: isDarkMode ? "#080808" : "#EEF3F7",
          position: "fixed",
          bottom: 0,
          width: "100%",
          padding: 2,

          textAlign: "center",
          height: 50,
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: isDarkMode ? "#FFF" : "#142764" }}
        >
          &copy; copyright {new Date().getFullYear()}: Udyat Technologies
        </Typography>
      </Box>
    </Box>
  );
}
